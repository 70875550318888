import "../Handbok.css"
import Footer from "../../../components/Footer";
import Header from "../../../components/Header/Header";
import { Link } from "react-router-dom";

import { useEffect } from "react";

const HandbokFormanerOchFordelar = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 });
      }, []);
    return ( 
        <>
                <Header/>
                <div className="handbook-con-wrapper">
        <main>
        <section className="handbok-menu">
        <div className="handbook-wrapper">
            <h1 className="h1-separat">💎 Flexibel arbetstid</h1>
            <article className="handbok-article">
                    <p>👋 Shaya Solutions grundas på en kultur av förtroende, vilket innebär att du kan lita på att hantera dina egna arbetstider.
                         Vi uppmuntrar dig att skapa en balans mellan arbete och privatliv som fungerar för dig, så att du kan vara både produktiv
                          och lycklig både på och utanför jobbet.
                         Ta dig tid att ta hand om dina personliga åtaganden, såsom att spendera tid med familjen, närvara vid viktiga händelser och
                          ta hand om din hälsa och välbefinnande.</p>
                </article>
                <article className="handbok-article">
                    <p>🌐 Självklart är det viktigt att informera kunderna när du inte är tillgänglig,
                         särskilt om de är beroende av din tillgänglighet. Se till att dina ansvarsområden täcks
                          när du inte är tillgänglig för att säkerställa en smidig övergång.</p>
                </article>
            </div>
        </section>
    </main>
    </div>
   <Footer/>
    </>
     );
}
 
export default HandbokFormanerOchFordelar;