import "./EachCase.css"
import Header from "../../components/Header/Header"
import Footer from "../../components/Footer"
import ITCasesComponent from "../../components/ITCasesComponent/ITCasesComponent"
import TeknikCasesComponent from "../../components/TeknikCasesComponent/TeknikCasesComponent"
import ManagementCasesComponent from "../../components/ManagementCasesComponent/ManagementCasesComponent"
import { useLocation, useNavigate } from "react-router"
import { useEffect } from "react"


const EachCase = () => {
    const location = useLocation()
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }, []);
    
    const obj = location.state;
    return (
        <>
        <Header />
        <div className="container-eachcase">
            
        <main className="ITCases-main">
            
                <h1 className="fade">{obj.title}</h1>
                <div className="first-textarea fade">
                    <p>{obj.body}</p>
                </div>

                { obj.arbetsuppgifter && 
                <div className="second-textarea fade ">
                    <h2>Konsultens arbetsuppgifter har varit att:</h2>
                    
                    <ul className="list fade">
                    {obj.arbetsuppgifter && obj.arbetsuppgifter.map((uppgift, i)=>{
                        return(
                            
                                <li key={i}>{uppgift}</li>
                            
                            
                        )
                    })}
                    </ul>
                    
                </div>}
                
                <div className="cases">
                    {
                        obj.type == "it" && <ITCasesComponent />
                    }
                    {
                        obj.type == "management" && <ManagementCasesComponent />
                    }
                    {
                        obj.type == "teknik" && <TeknikCasesComponent />
                    }
                    
                </div>
                
        </main>
        
        </div>
        <Footer />
        </>
    );
}

export default EachCase;