//COMPONTENTS
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer";
import WhatWeDo from "../../components/WhatWeDOo/WhatWeDo"; 
//STYLING
import "./Consult.css"
//CONSULT ICONS
import Search from "../../img/consult-img/search.png";
import SearchData from "../../img/consult-img/search-data.png";
import Verified from "../../img/consult-img/verified.png";
import Contact from "../../img/consult-img/contact.png";
import { useEffect } from "react";
import FadeInSection from "../../components/FadeInSection";
const Consult = () => {
    useEffect(() => {
        window.scrollTo({top: 0, left: 0});
       
      }, []);
    <FadeInSection/>
    return (

        <>
        <Header />
        <div className="container-consult">
            
            <div className="consult-content-container">
                <div className="hero-wrapper">
            <h1>Utveckla din erfarenhet</h1>
                <p className="hero-text">
                VAERNA är ett nischat konsultbolag inom OT/IT Säkerhet och styrsystem.
                Vi fokuserar på att anställa seniora personer med djup kompetens och
                med lång erfarenhet. Personer som brinner för att lösa de mest komplexa
                problemställningarna och som är kvar till projektavslut.
                </p>
                </div>
                <h2>Process</h2>
                <div className="process-wrapper">
                    <div className="process-box">
                        <span>I</span>
                        <img className="consult-icon" src={Search} alt="search-icon" />
                        <p>Hitta ett jobb och skicka in din ansökan</p>
                    </div>
                    <div className="process-box">
                        <span className="mar-r">II</span>
                        <img className="consult-icon" src={SearchData} alt="searchData-icon" />
                        <p>Vi granskar din ansökan</p>
                    </div>
                    <div className="process-box">
                        <span className="mar-l">III</span>
                        <img className="consult-icon" src={Contact} alt="contact-icon" />
                        <p>Vid rätt kompetens och erfarenhet, kallas du till intervju. </p>
                    </div>
                    <div className="process-box">
                        <span className="mar-r">IV</span>
                        <img className="consult-icon" src={Verified} alt="verified-icon" />
                        <p>Vi ger dig återkoppling oavsett utfall. </p>
                    </div>
                </div>
                <a className="btn-blue" href="https://career.shayasolutions.com/#section-jobs" target="_blank" rel="noopener noreferrer">Lediga uppdrag</a>

                {/* WHAT WE DO COMPONENT */}
                <FadeInSection>
               <WhatWeDo/>
               </FadeInSection>
            </div>
 
        </div>
        <Footer />
        </>

    );
}

export default Consult;