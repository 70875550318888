import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useState, useEffect, useRef } from "react";

import './App.css';
import LandingPage from "./pages/LandingPage/LandingPage";
import AboutUs from "./pages/AbousUs/AboutUs";
import Nadia from "./pages/Nadia/Nadia";
import Contact from "./pages/Contact";
import WordsFromCeo from "./pages/WordsFromCeo";
import Enviornment from "./pages/Enviornment/Enviornment";
import Consult from "./pages/Consult/Consult";
import Customer from "./pages/Customer/Customer"
import Partner from "./pages/Partner/Partner"
import News from "./pages/News/News"
import EachNews from "./pages/News/eachNews"
import ITCases from "./pages/IT-Cases/IT-Cases"
import Faq from "./pages/FAQ/FAQ"

import EachCase from "./pages/EachCase/EachCase"
import ManagementCases from "./pages/Management-Cases/Management-Cases"
import TeknikCases from "./pages/Teknik-Cases/Teknik-Cases"
import WhatWeDoIt from "./pages/WhatWeDo-IT-Page/WhatWeDo-IT-Page"
import WhatWeDoManagement from "./pages/WhatWeDo-Management-Page/WhatWeDo-Management-Page"
import WhatWeDoTeknik from "./pages/WhatWeDo-Teknik-Page/WhatWeDo-Teknik-Page"
import WhatWeDoITNEW from "./pages/WhatWeDo-ITNEW-Page/WhatWeDo-ITNEW-Page"
import EachField from "./pages/EachField/EachField"
import Comp from "./pages/Comp/Comp"
import CompProfile from "./pages/Comp/CompProfile"

import NewNews1 from "./pages/NewNews/NewNews1";
import NewNews2 from "./pages/NewNews/NewNews2";
import NewNews3 from "./pages/NewNews/NewNews3";
import NewNews4 from "./pages/NewNews/NewNews4";
import NewNews5 from "./pages/NewNews/NewNews5";
import NewNews6 from "./pages/NewNews/NewNews6";
import NewNews7 from "./pages/NewNews/NewNews7";
import NewNews8 from "./pages/NewNews/NewNews8";
import Widget from "./components/Widget/Widget";


import Handbok from "./pages/Handbok/Handbok";
import HandbokSvenska from "./pages/Handbok/HandbokSvenska"
import HandbokVarderingar from "./pages/Handbok/Handbok-enskild-sida/HandbokVarderingar";
import HandbokForvantningar from "./pages/Handbok/Handbok-enskild-sida/HandbokForvantningar";
import HandbokInsiderPolicy from "./pages/Handbok/Handbok-enskild-sida/HandbokInsiderPolicy";
import HandbokCvMall from "./pages/Handbok/Handbok-enskild-sida/HandbokCvMall";
import HandbokViktigInfo from "./pages/Handbok/Handbok-enskild-sida/HandbokViktigInfo";
import HandbokKontaktPersoner from "./pages/Handbok/Handbok-enskild-sida/HandbokFriskVardBidrag";
import HandbokFormanerOchFordelar from "./pages/Handbok/Handbok-enskild-sida/HandbokFormanerOchFordelar";
import HandbokFaqNyAnstallda from "./pages/Handbok/Handbok-enskild-sida/HandbokFaqNyAnstallda";
import HandbokFriskVardBidrag from "./pages/Handbok/Handbok-enskild-sida/HandbokFriskVardBidrag";
import HandbokElektronik from "./pages/Handbok/Handbok-enskild-sida/HandbokElektronik";
import HandbokGratisBocker from "./pages/Handbok/Handbok-enskild-sida/HandbokGratisBocker";
import HandbokLedighetUtveckling from "./pages/Handbok/Handbok-enskild-sida/HandbokLedighetUtveckling";
import HandbokKompetensUtveckling from "./pages/Handbok/Handbok-enskild-sida/HandbokKompetensUtveckling";
import HandbokPension from "./pages/Handbok/Handbok-enskild-sida/HandbokPension";
import HandbokArbetstiderLön from "./pages/Handbok/Handbok-enskild-sida/HandbokArbetstiderLön";
import HandbokLedighetGraviditet from "./pages/Handbok/Handbok-enskild-sida/HandbokLedighetGraviditet";
import HandbokMiljo from "./pages/Handbok/Handbok-enskild-sida/HandbokMiljo";
import HandbokShayaHQ from "./pages/Handbok/Handbok-enskild-sida/HandbokShayaHQ";
import HandbokFragor from "./pages/Handbok/Handbok-enskild-sida/HandbokFragor";

function App() {

  return (
    <>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/om-oss" element={<AboutUs />} />
          <Route path="/nadia-initiativet" element={<Nadia />} />
          <Route path="/miljo-hollbarhet" element={<Enviornment />} />
          <Route path="/konsult" element={<Consult />} />
          <Route path="/kund" element={<Customer />} />
          <Route path="/kontakt" element={<Contact />} />
          <Route path="/vd" element={<WordsFromCeo />} />
          <Route path="/partner" element={<Partner />} />
          <Route path="/nyheter" element={<News />} />
          <Route path="/en-nyhet" element={<EachNews />} />
          <Route path="/it-cases" element={<ITCases />} />
          <Route path="/teknik-cases" element={<TeknikCases />} />
          <Route path="/management-cases" element={<ManagementCases />} />
          <Route path="/ettcase" element={<EachCase />} />
          <Route path="/AUTOMATION" element={<WhatWeDoIt />} />
          <Route path="/ett-omrade" element={<EachField />} />
          <Route path="/NATVERKSTEKNIK" element={<WhatWeDoManagement />} />
          <Route path="/ot" element={<WhatWeDoTeknik />} />
          <Route path="/it" element={<WhatWeDoITNEW />} />
          {/* <Route path="/konsulter-och-kompetenser" element={<Comp />} /> */}
          <Route path="/kompetenser-profil" element={<CompProfile />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/nyhet-2" element={<NewNews6 />} />
          <Route path="/nyhet-3" element={<NewNews2 />} />
          <Route path="/nyhet-1" element={<NewNews5 />} />
          <Route path="/nyhet-4" element={<NewNews1 />} />
          <Route path="/nyhet-5" element={<NewNews3 />} />
          <Route path="/nyhet-6" element={<NewNews4 />} />
          <Route path="/nyhet-7" element={<NewNews7 />} />
          <Route path="/nyhet-8" element={<NewNews8 />} />
          <Route path="/guidelines" element={<HandbokSvenska />}/>
          <Route path="/varderingar" element={<HandbokVarderingar />}/>
          <Route path="/forvantningar" element={<HandbokForvantningar />}/>
          <Route path="/insider-policy" element={<HandbokInsiderPolicy />}/>
          <Route path="/ledighet-graviditet" element={<HandbokLedighetGraviditet />}/>
          <Route path="/cv-mall" element={<HandbokCvMall />}/>
          <Route path="/ledighet-foraldrar" element={<HandbokViktigInfo/>} />
          <Route path="/friskvardbidrag" element={<HandbokFriskVardBidrag/>} />
          <Route path="/kontaktpersoner" element={<HandbokKontaktPersoner/>} />
          <Route path="/flexibel-arbetstid" element={<HandbokFormanerOchFordelar/>} />
          <Route path="/foraldrar-ledighet" element={<HandbokFaqNyAnstallda/>} /> 
          <Route path="/elektronik" element={<HandbokElektronik/>} />
          <Route path="/litteratur" element={<HandbokGratisBocker/>} />
          <Route path="/ledighet-graviditet" element={<Handbok/>} />
          <Route path="/ledighet-utveckling" element={<HandbokLedighetUtveckling/>} />
          <Route path="/kompetens-utveckling" element={<HandbokKompetensUtveckling/>} />
          <Route path="/pension" element={<HandbokPension/>} />
          <Route path="/arbetstid-lon" element={<HandbokArbetstiderLön/>} />
          <Route path="/miljo-hallbarhet" element={<HandbokMiljo/>} />
          <Route path="/shaya-huvudkontor" element={<HandbokShayaHQ/>} />
          <Route path="/shaya-fragor" element={<HandbokFragor/>} />
        </Routes>
    </>
  );
}

export default App;
