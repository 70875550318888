
//COMPONTENTS
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer";
import WhatWeDo from "../../components/WhatWeDOo/WhatWeDo";
import WhatWee from "../../components/WhatWee/WhatWee"
import Pressmeddelande from "../../components/Pressmeddelande/Pressmeddelande";
import MySliderIt from "../../components/Slider/SliderIt";
import MySliderManagement from "../../components/Slider/SliderManagement";
import MySliderTeknik from "../../components/Slider/SliderTeknik";
import Widget from "../../components/Widget/Widget";
import JobPostings from "../../components/TeamTailor-api/JobPosting";
import Users from "../../components/Users/Users"

import check from "../../img/check-mark.png"
import arrow from "../../img/arrow.png"

import bgVideo from "../../img/bg-video.mp4"

import bgVideo2 from "../../img/bg-vid-2.mp4"

import OurNews from "../../components/News-component/our-news";
import "./LandingPage.css"

import Handbok from "../Handbok/Handbok";

//IMAGE CASES
import { useState, useEffect, useRef } from 'react'
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet"
import FadeInSection from "../../components/FadeInSection";

const LandingPage = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 });

    }, []);
    <FadeInSection />
    const [words2, setWords2] = useState(["nischade specialistbolag.", "anställda konsulter.", "kunder.", "egenföretagande konsulter."])
    const [words, setWords] = useState(["Automation.", "Styrsystem.", "Maskinstyrning.", "OT/IT Säkerhet."])
    const wrapperRef = useRef()
    const wrapperRef2 = useRef()

    const handleAnim = () => {

        setTimeout(() => {
            const copyWords = [...words];
            const firstElem = copyWords.splice(1)

            const copyWords2 = [...words2];
            const firstElem2 = copyWords2.splice(1)

            wrapperRef.current.style.transition = "0.4s";
            wrapperRef.current.style.top = "-3px";
            wrapperRef.current.style.animation = "ease-in";
            wrapperRef.current.style.opacity = "1";


            wrapperRef2.current.style.transition = "0.4s";
            wrapperRef2.current.style.top = "-3px";
            wrapperRef2.current.style.animation = "ease-in";
            wrapperRef2.current.style.opacity = "1";


            setWords([...firstElem.concat(copyWords)])

            setWords2([...firstElem2.concat(copyWords2)])
        }, 300);


        wrapperRef.current.style.opacity = "0.8"
        wrapperRef.current.style.top = "0px";

        wrapperRef2.current.style.opacity = "0.8"
        wrapperRef2.current.style.top = "0px";
    }

    useEffect(() => {
        setTimeout(() => {
            handleAnim()
        }, 1700);
    })
    useEffect(() => {
        const videoElement = document.getElementById("video");
        if (videoElement) {
            videoElement.play();
        }
    }, []);
    //TOGGLE WORD IN HERO

    return (

        <>

            <link href="/your-path-to-uicons/css/uicons-[your-style].css" rel="stylesheet"></link>
            <Header />
            {/* <Pressmeddelande/>  */}
            <div className="container-landingpage">

                <div className="hero">
                    <div className="video-wrapper">
                        <video autoPlay loop muted id="video" playsInline>
                            <source src={bgVideo} type="video/mp4" />
                        </video>
                    </div>
                    <div className="hero-text-landingPage">
                        {/* <div className="hero-box">
                            <p>Värdeskapande för hållbart liv i samhället.
                                <br />
                                Omsätt din organisationskompetens till affärsnytta.
                            </p>

                        </div> */}
                        <div className="hero-text-h3">
                            {/* <h3 className="regular-text">Vi hedrar</h3> */}
                            <h2>OT. IT. SÄKERHET.</h2>
                            <div ref={wrapperRef} className='text-container-word-wrapper'>
                                <h2 className='text-word'>{words[0]}</h2>
                            </div>
                            {/* <h2>framgångsrika</h2> */}
                        </div>
             <div className="hero-box hero-box-alone">
                            <p>
                                SÄKRARE SAMHÄLLE. FÖR OSS ALLA. 
                            </p>

                        </div>
                    </div>
                </div>

                {/* <WhatWeDo /> */}
                <div className="provide-content-wrapper">
                <FadeInSection>
                        <h2>Erfarna experter. <br /> Med unik leveransförmåga.</h2>
                    </FadeInSection>
                <div className="provide-content-2">
                   




                    <div className="content-section-2-wrapper content-2-extra-mt">
                        <div className="content-section">
                            <FadeInSection>
                            <h3 className="provide-wwd-heading">Tjänsteområden</h3>
                                <div className="content-new-wrapper">
                                {/* <h3>IT</h3> */}
                                    <h3>AUTOMATION</h3>
                                </div>
                                <div className="section-2-text-wrapper">
                                    {/* <h4>Mjukvaruutveckling, Nätverk, Säkerhet, Applikation, Industriell IT.</h4> */}
                                    <h4>PLC, SCADA/HMI, Instrumentering, Kommunikationsteknologi, Drivsystem osv..</h4>
                                    {/* <p>Förstärk Er verksamhet med specialiserad kompetens som är verifierad och validerad med förmåga att leverera
                                        omgående i era projekt</p> */}
                                </div>
                                 <button className="btn-placeholder-new"><Link className="link-section-2"  to={"/AUTOMATION"}>Läs mer</Link></button>
                            </FadeInSection>
                        </div>
                        <div className="content-section">
                            <FadeInSection>
                                <div className="content-new-wrapper">
                                    {/* <h3>Management</h3> */}
                                    <h3>NÄTVERKSTEKNIK</h3>
                                </div>
                                <div className="section-2-text-wrapper">
                                    {/* <h4>Projektledning, Verksamhetsutveckling, Interimslösningar, Ekonomi.</h4> */}
                                    <h4>Protokollförståelse, Nätverksutrustning, Kabelinfrastruktur, IPv6,
Katastrofåterställning osv..</h4>
                                    {/* <p>Verksamhetskritiska frågor kan vara varierande i omfattning där olika kompetenser
                                        behövs för att komma i mål med multidisciplinära lösningar</p> */}
                                </div>
                                <button className="btn-placeholder-new"><Link className="link-section-2"  to={"/NATVERKSTEKNIK"}>Läs mer</Link></button>
                            </FadeInSection>
                        </div>
                        <div className="content-section">
                            <FadeInSection>
                                <div className="content-new-wrapper">
                                    {/* <h3>Ingenjörstjänster</h3> */}
                                    <h3>OT ARKITEKTUR OCH INTEGRATION</h3>
                                </div>
                                <div className="section-2-text-wrapper">
                                    {/* <h4>Maskinstyrning, Styr och regler, I&C, Elkonstruktion, Idrifttagning.</h4> */}
                                    <h4>Industriella Kontrollsystem (ICS), Nätverksarkitektur, Säkerhet, Hårdvara osv...</h4>
                                    {/* <p>Vi är nischade på att få flera aktörer, som ibland kan vara konkurrenter på
                                        samma marknad, att sammarbeta för en multidisciplinär leverans med fokus på det
                                        bästa för Er organisation</p> */}
                                </div>
                                <button className="btn-placeholder-new"><Link className="link-section-2"  to={"/ot"}>Läs mer</Link></button>
                            </FadeInSection>
                        </div>
                        <div className="content-section">
                            <FadeInSection>
                                <div className="content-new-wrapper">
                                    {/* <h3>Ingenjörstjänster</h3> */}
                                    <h3>IT ARKITEKTUR OCH INTEGRATION</h3>
                                </div>
                                <div className="section-2-text-wrapper">
                                    {/* <h4>Maskinstyrning, Styr och regler, I&C, Elkonstruktion, Idrifttagning.</h4> */}
                                    <h4>Systemintegration, API-design och -hantering, Meddelandeprotokoll, Middleware-
verktyg osv..</h4>
                                    {/* <p>Vi är nischade på att få flera aktörer, som ibland kan vara konkurrenter på
                                        samma marknad, att sammarbeta för en multidisciplinär leverans med fokus på det
                                        bästa för Er organisation</p> */}
                                </div>
                                <button className="btn-placeholder-new"><Link className="link-section-2"  to={"/it"}>Läs mer</Link></button>
                            </FadeInSection>
                        </div>
                    </div>




                    <div className="content-section-2-wrapper vit-bak">
                        <div className="content-section sp">
                            <FadeInSection>
                            <h3 className="provide-wwd-heading">Erbjudande</h3>
                                <div className="content-new-wrapper">
                                   
                                    <span>|</span>
                                    <h3>Konsultavrop deltid eller heltid</h3>
                                </div>
                                <div className="section-2-text-wrapper">
                                    <h4>Utvidga ditt team med en konsult på löpande räkning per timme</h4>
                                    {/* <p>Förstärk Er verksamhet med specialiserad kompetens som är verifierad och validerad med förmåga att leverera
                                        omgående i era projekt</p> */}
                                </div>
                                {/* <button className="btn-section-2"><Link className="link-section-2">Läs mer</Link></button> */}
                            </FadeInSection>
                        </div>
                        <div className="content-section sp">
                            <FadeInSection>
                                <div className="content-new-wrapper">
                                    <span>||</span>

                                    <h3>Avropa en funktion</h3>
                                </div>
                                <div className="section-2-text-wrapper">
                                    <h4>Utvidga Er verksamhet genom att avropa stöd inom en funktion</h4>
                                    {/* <p>Verksamhetskritiska frågor kan vara varierande i omfattning där olika kompetenser
                                        behövs för att komma i mål med multidisciplinära lösningar</p> */}
                                </div>
                                {/*                                 
                                    <button className="btn-section-2"><Link className="link-section-2">Läs mer</Link></button> */}
                            </FadeInSection>
                        </div>
                        <div className="content-section sp">
                            <FadeInSection>
                                <div className="content-new-wrapper">
                                    <span>|||</span>
                                    <h3>Helhetsåtagande</h3>
                                </div>
                                <div className="section-2-text-wrapper">
                                    <h4>Multidisciplinära projektleveranser till rätt pris, tidplan och kvalité</h4>
                                    {/* <p>Vi är nischade på att få flera aktörer, som ibland kan vara konkurrenter på
                                        samma marknad, att sammarbeta för en multidisciplinär leverans med fokus på det
                                        bästa för Er organisation</p> */}
                                </div>
                                {/* <button className="btn-section-2"><Link className="link-section-2">Läs mer</Link></button> */}
                            </FadeInSection>
                        </div>
                    </div>




                </div>
                </div>
                <JobPostings />
                {/* <Users/>   */}
                {/* <Widget /> */}
                <div className="provide-content-3">
                    <FadeInSection>
                        <div className="word-wrapper-new">
                            <h2>Vi erbjuder</h2>
                            {/*<div ref={wrapperRef2} className='text-container-word-wrapper' >
                                <h2 id="changing-word-new" className='text-word'>{words2[0]}</h2>
                            </div>*/}
                        </div>
                    </FadeInSection>
                    <div className="content-section-3-wrapper">
                        <div className="content-section-3">
                            <FadeInSection>
                                <h3>Kunder</h3>
                                <div className="provide-list-6">

                                    <div className="each-item">
                                        <img className="check-svg" src={check} alt="checkmark" />
                                        <p>Validerad expertkompetens på levererade konsulter</p>
                                    </div>
                                    <div className="each-item">
                                        <img className="check-svg" src={check} alt="checkmark" />
                                        <p>Återkoppling inom en vecka gällande konsultbehov</p>
                                    </div>
                                    <div className="each-item">
                                        <img className="check-svg" src={check} alt="checkmark" />
                                        <p>Helhetsåtagande inom multidisciplinära projekt</p>
                                    </div>
                                    <div className="each-item">
                                        <img className="check-svg" src={check} alt="checkmark" />
                                        <p>Självgående konsulter på plats eller remote</p>
                                    </div>
                                </div>
                                {/* <button className="btn-section-2"><Link className="link-section-2">Läs mer</Link></button> */}
                            </FadeInSection>
                        </div>
                        <div className="content-section-3">
                            <FadeInSection>
                                <h3>Anställda</h3>
                                <div className="provide-list-6">

                                    <div className="each-item">
                                        <img className="check-svg" src={check} alt="checkmark" />
                                        <p>Utvecklingsmöjligheter</p>
                                    </div>
                                    <div className="each-item">
                                        <img className="check-svg" src={check} alt="checkmark" />
                                        <p>Trevligt och centralt kontor</p>
                                    </div>
                                    <div className="each-item">
                                        <img className="check-svg" src={check} alt="checkmark" />
                                        <p>Hälsa och friskvård</p>
                                    </div>
                                    <div className="each-item">
                                        <img className="check-svg" src={check} alt="checkmark" />
                                        <p>Tjänstepension</p>
                                    </div>
                                    <div className="each-item">
                                        <img className="check-svg" src={check} alt="checkmark" />
                                        <p>Försäkringar</p>
                                    </div>
                                    <div className="each-item">
                                        <img className="check-svg" src={check} alt="checkmark" />
                                        <p>Access till Onlinepsykolog tjänsten Mindler</p>
                                    </div>

                                </div>
                            </FadeInSection>
                        </div>
                        {/*<div className="content-section-3-3">
                            <FadeInSection>
                                <h3>Underkonsulter och partners</h3>
                                <div className="provide-list-6">

                                    <div className="each-item">
                                        <img className="check-svg" src={check} alt="checkmark" />
                                        <p>Attraktiva, långa konsultuppdrag och projekt</p>
                                    </div>
                                    <div className="each-item">
                                        <img className="check-svg" src={check} alt="checkmark" />
                                        <p>Kompetensfokuserad säljorganisation</p>
                                    </div>
                                    <div className="each-item">
                                        <img className="check-svg" src={check} alt="checkmark" />
                                        <p>Factoringlösning</p>
                                    </div>

                                    <div className="each-item">
                                        <img className="check-svg" src={check} alt="checkmark" />
                                        <p>Access till centralt kontor</p>

                                    </div>
                                    <div className="each-item">
                                        <img className="check-svg" src={check} alt="checkmark" />
                                        <p>Access till Onlinepsykolog tjänsten Mindler</p>
                                    </div>
                                    <div className="each-item">
                                        <img className="check-svg" src={check} alt="checkmark" />
                                        <p>Stöttande organisation löpande i uppdraget</p>
                                    </div>
                                </div>
                            </FadeInSection>
                        </div>*/}

                    </div>
                    {/* <Link to={"/handbok"} className="moreJobsBtn">Läs vår handbok</Link> */}
                </div>
                {  /*   <WhatWee />*/}
                <OurNews />
                <MySliderIt />
                <MySliderManagement />
                <MySliderTeknik />
              
            </div>
            <Footer />
        </>
    );
}

export default LandingPage;