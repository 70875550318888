import "./our-news.css"
import video from "./video.mp4";
import logoo from "../../img/vlogga.png"
import image2 from "../../img/2.jpeg"
import placeholderImg from "../../img/middagMedTeamet.jpg"
import shayaxmindler from "../../img/Bild1.png"
import redovisning from "../../img/Bild2.png"
import placeholderImg2 from "../../img/arsredovisning-news.png"
import cecilia from "../../img/UppsalaUniversitet.jpeg";
import KonsultTraff2023 from "../../img/konsult_sommar.jpeg"
import shayadi from "../../img/shaya_di.png"
import Eventpakontor from "../../img/Eventpakontor.jpg"
import vr from "../../img/VrAktivitet2.1a1c56d7.jpg"
import TecharenanSummit2 from "../../img/TecharenanSummit2.jpeg"
import midsommar from "../../img/midsommar.jpg"
import { Link } from "react-router-dom";
import YouTube from 'react-youtube';
    
import { useState, useEffect, useRef } from 'react'

const OurNews = () => {
    return (
        <div className="our-news-wrapper">
          
            <div className="our-news-content-wrapper">
            <h2>Senaste</h2>
                <div className="our-news-grid">
                    <div className="one-news-posistion">
                        <img src={logoo} alt="" />
                        <p className="centered">Shaya Solutions AB byter namn till VAERNA OT IT AB (samma organisationsnummer) </p>
                        <Link to="/nyhet-7">
                            <button className="btn-placeholder">Läs mer</button>
                            </Link>
                            <span className="hashtag-news">#Nyhet</span>
                    </div>
           
                    <div className="one-news-posistion">
                        <img src={image2} alt="" />
                        <p className="centered">Det har varit en ära. Hatten av och tack för mig. -Joseph Öberg Shaya</p>
                        <Link to="/nyhet-8">
                            <button className="btn-placeholder">Läs mer</button>
                            </Link>
                            <span className="hashtag-news">#Nyhet</span>
                    </div>
                <div className="one-news-posistion">
                        <img src={shayadi} alt="" />
                        <p className="centered">SHAYA SOLUTIONS (numera VAERNA) UTSETT TILL GASELL BOLAG AV DAGENS INDUSTRI</p>
                        <Link to="/nyhet-6">
                            <button className="btn-placeholder">Läs mer</button>
                            </Link>
                            <span className="hashtag-news">#Nyhet</span>
                    </div>
                <div className="one-news-posistion">
                        <img src={KonsultTraff2023} alt="" />
                        <p className="centered">Glad sommar och tack för gångna halvåret av 2023</p>
                        <Link to="/nyhet-1">
                            <button className="btn-placeholder">Läs mer</button>
                            </Link>
                            <span className="hashtag-news">#Nyhet</span>
                  </div>
                    <div className="one-news-posistion">
                        <img src={midsommar} alt="" />
                        <p className="centered">Glad midsommar önskar teamet på Shaya Solutions</p>
                        <Link to="/nyhet-2">
                            <button className="btn-placeholder">Läs mer</button>
                            </Link>
                            <span className="hashtag-news">#Nyhet</span>
                    </div>
                    <div className="one-news-posistion">
                        <img src={shayaxmindler} alt="" />
                        <p className="centered">Shaya Solutions har skrivit avtal med Mindler</p>
                        <Link to="/nyhet-3">
                            <button className="btn-placeholder">Läs mer</button>
                            </Link>
                        <span className="hashtag-news">#Nyhet</span>
                    </div>
                    <div className="one-news-posistion">
                        <img src={redovisning} alt="" />
                        <p className="centered">Sammanfattning av årsredovisning för 2021</p>
                        <Link to="/nyhet-4">
                            <button className="btn-placeholder">Läs mer</button>
                            </Link>
                            <span className="hashtag-news">#Nyhet</span>
                    </div>
                    <div className="one-news-posistion">    
                        <img src={cecilia} alt="" />
                        <p className="centered">Ett kärt återseende av Ångströmlaboratoriet och Uppsala Universitet</p>
                        <Link to="/nyhet-5">
                            <button className="btn-placeholder">Läs mer</button>
                            </Link>
                            <span className="hashtag-news">#Nyhet</span>
                    </div>

                    {/* <div className="one-news-posistion">
                        <img src={vr} alt="" />
                        <p className="centered">VR aktivitet för teamet på Shaya Solutions</p>
                        <Link to="/nyhet-6">
                            <button className="btn-placeholder">Läs mer</button>
                            </Link>
                            <span className="hashtag-news">#Nyhet</span>
                    </div> */}
                </div>
                {/* <h2 className="heading-center">Se en snabb video med en av <br />våra konsulter</h2>
                <div className="video-wrapper">
                    <a target="_blank" href="https://www.youtube.com/watch?v=0GcnO81iTdw">
                <video src={video} autoPlay loop muted />
                </a>
                <a target="_blank" href="https://www.youtube.com/watch?v=0GcnO81iTdw">
                <   p className="centered-video">Titta på videon</p>
                </a>
                </div>
            */}
            </div>
        </div>
    );
}

export default OurNews;