import konsult_sommar from "../../img/konsult_sommar.jpeg"

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer";
import { useEffect } from "react";
import "./NewNews1.css";
const NewNews2 = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 });

    }, []);
    return (
        <><Header />
            <div className="container-newNews">
                <div className="newNews-wrapper">
                    <span>#Nyhet</span>
                    <h1>Glad sommar och tack för gångna halvåret av 2023</h1>
                    <img src={konsult_sommar} alt="" />
                    <p>Konsulterna och interna teamet är bra på många saker - men att ta bilder och hålla hemsidan uppdaterad på nyheter avseende allt vi gör hela tiden - det är vi mindre bra på.

<br />
Men det passar oss rätt bra också - lite snack och mycket verkstad.

<br />

Tack för årets första halvår och vi önskar alla våra kunder och partners för en trevlig och glad sommar så länge. 
<br />
<br /> Datum: Juni 2023. </p>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default NewNews2;