import "./Slider.css"
import { motion } from "framer-motion"
import { Link } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import arrow from "../../img/arrow.png"
const MySliderIt = () => {
  const itCase = [

    {
      title: "VAERNA stöttar kund med apputveckling inom iOS",
      body: "Kunden behövde stärka sitt team med en iOS-utvecklare för att vidareutveckla deras olika appar.Kundens verksamhet kräver mer och fler komplexa IT-lösningar som är en förutsättning för deras verksamhet med system och IT-utrustning för exempelvis radio, försäljning, information och trygghet. Kunden bygger nu ut sektionen Utveckling och Digitalisering som ansvarar för design av IT-lösningar baserade på behov från verksamheten där målet är att bli bäst inom digitalisering. Konsulten blir en del av ett etablerat agilt utvecklarteam. De mobila applikationerna är bara en del i de distribuerade system som tillsammans bygger upp kundens lösningar. Konsulten har behövt ha tidigare kunskap och erfarenhet av liknande distribuerade miljöer, vilket gjorde att honom till väldigt lämplig. Då teamet består av både iOS- och Android-utvecklare har det varit starkt meriterande att hen kunna agera utvecklare för båda plattformarna, vilket bidrog till ett framgångsrikt samarbete. Systemutveckling sker i Swift.",
      start: "Uppdraget startades Q1 2020.",
      alt: "bild på it",
      id: "1",
      type: "it"
    },
    {
      title: "VAERNA stöttar kund med en IT-säkerhetskonsult/arkitekt",
      body: "Kunden behövde stärka sin IT-avdelning som designar, utvecklar, beställer och förvaltar kundens digitala tjänster med en IT-säkerhetskonsult/arkitekt. Uppdraget består både i att arbeta som gemensam/övergripande Informations- och IT-säkerhetsresurs men även som resurs i projekten. Typiska arbetsuppgifter har varit och är att säkerställa Informations- och IT-säkerhetsområdet följer målarkitekturframtagna lösningar. Arbetet kommer även bestå av att skriva arkitekturdokumentation för IT- och Informationssäkerhet för programmets leveranser, granska underlag producerade av andra samt identifiera risker och ta fram specifika kravställningar i projekten. Rollen kräver således att konsulten har förmågan att jobba både på en övergripande nivå, men även på mer detaljerade systemnivåer. Då området är komplext med många intressenter är det av största vikt att konsulten har en analytisk förmåga att tänka i olika nivåer i arkitektoniska modeller och bidra till att knyta ihop leverabler m.a.p. informations- och IT-säkerhet på alla systemnivåer.",
      start: "Uppdraget startades Q1 2020.",
      alt: "bild på teknik",
      arbetsuppgifter: ["Ansvara för kravställning",
        "Ansvara för arkitektur av IT-säkerheten i programmets leveranser",
        "Ansvara för Informationssäkerheten i programmets leveranser",
        "Utgöra kontaktperson mot informationssäkerhetsfunktion (Ledningsstaben) samt IT-säkerhet (IT-avdelningen)",
        "Ansvara för stöd och råd",
        "Följa upp att utdelade och identifierade åtgärder korrigeras"],
      id: "2",
      type: "it"
    },

    {
      title: "VAERNA stöttar myndighet med en Javautvecklare",
      body: "Kunden behövde stärka sitt team med en Javautvecklare för arbete med deras digitala tjänster. Inom kundens verksamhet pågår ett arbete med att utveckla och förnya deras digitala tjänster. Javautvecklaren arbetar med systemutveckling och implementerar funktionalitet baserad på fastställda krav. Utveckling av system sker med hänsyn till den planerade/levererade lösningens hela livscykel. I uppdraget ingår även 3:e linjens support. Javautvecklaren har erfarenhet och intresse för viss webbutveckling. Webblösningen bygger på Angular/Typescript och kunden strävar efter att varje utvecklingsteam ska kunna utveckla funktioner från ax till limpa. Beroende på person, erfarenhet och intresse så finns det möjligheter att bredda arbetsuppgifterna så att de inkluderar viss webbutveckling.",
      start: "Uppdraget startades Q1 2021.",
      alt: "bild på teknik",
      arbetsuppgifter: ["Utvecklar och implementerar mjukvarulösningar inom specificerad tid och budget samt enligt ställda krav, design och arkitektur.",
        "Genomför felsökning, isolerar och korrigerar mjukvarufel.",
        "Utgör stöd och support till drift och förvaltning av mjukvarulösningar.",
        "Utför enhetstester och integrationstester.",
        "Dokumenterar implementerad funktionalitet.",
        "Justerar/optimerar prestanda.",
        "Genomför intern och extern integration av system.",
        "Levererar enhetsstöd och dokumenterad kod som integrerar med angränsande system.",
        "Levererar systemdokumentation.",
        "Stöd för övriga utvecklare, svara på frågor och hjälpa till med kompetensutveckling i teamet.",
        "Ta fram implementationsförslag för nya funktioner och lösningar.",
        "Kontinuerligt analysera och förbättra kvalitén i de systems om tas fram och hålla en backlog med teknisk skuld och förbättringsarbete som inte är kravdrivet.",
        "Skriva och förfina tasks utifrån krav i JIRA.",
      ],
      id: "4",
      type: "it"
    },
    {
      title: "VAERNA stöttar IT-avdelning med en Kravledare",
      body: "VAERNA stöttar kundens IT-avdelning som behövde förstärka sin organisation med en kravledare för att leda kravanalysarbetet i deras strategiska satsningar. Kunden är en upphandlande och beställande organisation där IT-stöd är grunden för en långsiktigt, stabil och säker drift- och förvaltning av verksamheten. I samband med upphandlingar tar kunden fram underlag i form av kravställningar, specifika förutsättningar och definierade villkor vilka beskriver vad som förväntas av leverantören. Det är IT-avdelningens ansvar att bidra med underlag i upphandlingarna för det IT-stöd leverantören skall använda i samverkan med kunden. Uppdraget kommer innebära att leda kravanalysarbetet i kundens strategiska satsningar där en grupp av kravanalytiker i samverkan med arkitekter från IT deltar proaktivt i syfte att fånga upp behov och krav utifrån verksamheten. Detta för att förstå nuvarande kravbild men också för att kunna verka som rådgivare och styra (arkitekturstyrning) så att behov och krav harmoniserar mot den satta målarkitekturen inom IT. Uppdraget innefattar också att leda och samordna IT-beredningsarbetet baserat på nya upphandlingar samt även ansvara för formering, beredning och beslutsförankring av de investeringssatsningar som krävs inför avtalsstarter. Uppdraget är ett brett och målstyrt med mycket höga krav på leveransförmåga och framdrift. Konsultens ansvar är att leda kravanalytiker i samverkan med en bred konstellation av arkitekter och områdesansvariga som omfattar både verksamhet, IT-förvaltning och arkitektur.",
      start: "Uppdraget startades Q2 2020.",
      alt: "bild på teknik",
      id: "5",
      type: "it"
    },
    {
      title: "VAERNA stöttar region med en Lösningsarkitekt",
      body: "Kunden behövde stärka sitt team som arbetar med ett komplext system för olika typer av information där informationen rör sig i olika takt, från semi-statiska tabeller till störningsinformation där vissa delar är realtidsdrivet med en Lösningsarkitekt. Uppdraget är att arbeta som lösningsarkitekt i programmet där den initiala uppgiften är att mappa nuläget tillsammans med arkitekter i andra roller samt intressenter för att sedan skapa en ny modern skalbar och effektiv arkitektur. Rollen kräver att personen kan ta in mycket information på kort tid, förstå samband och komplexa system för att kunna kravställa funktionella och icke-funktionella krav som sedan mynnar ut i att skapa ny arkitektur som kan användas som plattform för kundens nuvarande och kommande tjänster. Typiska arbetsuppgifter inom teamet är behovsanalys, begreppsmodellering, informationsmodellering, målarkitektur och lösningsdefinition. Leverablerna skall följa målarkitektur och levereras till projekt under programmet.",
      start: "Uppdraget startades Q3 2019.",
      alt: "bild på teknik",
      id: "6",
      type: "it"
    },
    {
      title: "VAERNA stöttar myndighet med en Nätverkstekniker",
      body: "Kunden behövde stärka sitt team som arbetar med kundens datacenternätverk med en resurs inom nätverk som är en expert inom routing, DMZ, brandväggar och accessnät för flera utvecklingsprojekt inom nätverk. Konsulten kommer att arbeta med arkitektur och utveckling en av kundens datacenternätverk och accessnät. Shaya Solutions är stolta över att kunna bistå med denna kompetens.",
      start: "Uppdraget startades Q1 2021.",
      alt: "bild på teknik",
      id: "7",
      type: "it"
    },
    {
      title: "VAERNA stöttar region med en IT Projektledare",
      body: "Kunden behövde förstärkning till sitt projekt som drivs för att skapa förutsättningar för ökad säkerhet i tillträdet till deras anläggningar med en erfaren projektledare med kunskap inom IT utveckling. Projektet har tidigare upphandlat och infört ett gemensamt behörighetssystem med tillhörande passagesystem samt installatörer för installation av elektroniska lås. Det överordnade behörighetssystemet är idag integrerat mot flera passersystem för onlinelås och nu ska även ett system för offlinelås integreras mot samma behörighetssystem. Projekt syftar till att ge kunden en ökad kontroll över tillträdet till verksamhetens utrymmen. Detta ska uppnås med stöd av en ny låsarkitektur och ett i huvudsak elektroniskt låssystem, för att skydda anläggningar från skada och intrång. I uppdraget ingår att planera, koordinera och driva aktiviteter för att införa de nya funktionerna och integrationerna.",
      start: "Uppdraget startades Q3 2020.",
      arbetsuppgifter: ["Samverka med projekt, förvaltning och leverantör av behörighetssystem för att säkerställa korrekt införande av nya krav.",
        "Ansvara för att testaktiviteter och integration bedrivs enligt krav och riktlinjer.",
        "Planera, följa upp och driftsätta funktionalitet.",
        "Med stöd av IT-systemförvaltare och extern IT-driftleverantör säkerställa utvecklings- och produktionsmiljöer.",
      ],
      alt: "bild på teknik",
      id: "8",
      type: "it"
    },
    {
      title: "VAERNA stöttar kund med en sakkunnig systemförvaltare",
      body: "Kunden behövde stärka sitt team som arbetar med deras fastighetssystem med en sakkunnig systemförvaltare. Kunden har implementerat Landlord III / Faciliate som stödsystem för fastighetsinformation. Landlord är ett system för fastighetsinformation som innehåller kundens fastighetsdatabas och moduler för att styra, dokumentera och följa upp fastighetsverksamheten. Fastighetssystemet ska tillgodose kundens behov av kontroll på fastigheternas skick och entreprenörernas arbete bland annat genom att vara tillgängligt för många olika användare, interna såväl som entreprenörer. I nästa skede av utveckling ska Landlord-teamet införa fler moduler inom fastighetssystemet men även utveckla de redan inköpta modulerna till fler användningsområden. Konsulten ska vara behjälplig i arbetet med att införa fler moduler inom deras fastighetssystemet.Konsultens arbetsuppgifter har varit att arbeta med:",
      start: "Uppdraget startades Q1 2020 och avslutades Q1 2021.",
      arbetsuppgifter: ["Funktionalitet och vidareutveckling av systemet",
        "Användarsupport",
        "Utbildningar med fokus på teknik",
        "Framtagande av lathundar för både externa och interna användare",
        "Bidra vid omvandling av verksamhetens behov till teknisk specifikation",
        "Release management",
        "Manuell testning",
        "Hantera systemets integrationer till andra system samt utveckla befintliga/nya integrationer",
        "Sammanställa rapporter och stödja verksamheten i dess uppföljning av externa entreprenörer",
      ],
      alt: "bild på teknik",
      id: "9",
      type: "it"
    },
    {
      title: "VAERNA stöttar region med förstärkning av en Testledare",
      body: "Kunden behövde stärka sitt projekt som arbetar med implementering av nya integrationer, modifiering av befintliga system för deras informationssystem. Kunden har specificerat krav gällande integrationer och informationsflöden. Informationen är främst information som ska överföras i realtid. Projekt ska implementera nya integrationer, modifiera befintliga system för att hantera ny information samt genomföra tester för att säkerhetsställa full funktionalitet. Uppdraget är planera samt genomföra projektets tester samt leda projektets testare. Scopet för tester är alltifrån system och integrationstester till end-to-end och acceptanstester. Testerna kommer variera mycket i omfattning, alltifrån testa API:er till genomföras med fysiska tester. Arbetsuppgifterna är blandade med både ledningsarbetet i form av arbetsnedbrytning, tidplaner, riskanalyser, beställningar och leda testare till att faktiskt utföra tester och skriva rapporter, granska rapporter från leverantörer, ta fram testdata och analysera utfall av tester.",
      start: "Uppdraget startades Q2 2020.",
      alt: "bild på teknik",
      id: "10",
      type: "it"
    },
    {
      title: "VAERNA stöttar kund med förstärkning av en Scrum Master",
      body: "Kunden behövde stärka två av sina projekt med en Scrum master för att stötta och coacha teamen. Det första projektet har i uppdrag från IT-avdelningen att introducera en molnbaserad informations- och dataplattform, som ska vara en gemensam plattform för informationsförsörjning och analys. Kundens andra projekt har i uppdrag att bygga upp en ny generation molntjänster ovanpå den gemensamma dataplattformen som nämns ovan. De nya tjänsterna byggs upp på modern mikroservice-arkitektur. Båda plattformarna ska kunna hantera realtidsflöden. Till varje projekt finns två separata agila team som består av utvecklare, data scientist, testare, säkerhetsexperter, och produktägare. Konsulten är van vid att coacha och stötta team till att bygga upp struktur och stöd från agila processer. Förväntningarna på teamet är att iterativt förbättra den process som är uppsatt. Som Scrum Master ansvarar personen vidare för att möjliggöra kontinuerlig förbättring av teamets agila processer och möjliggöra för möten så som standups, sprintplaneringar, retrospektiv och demos. I samarbete med produktägaren kommer Scrum Mastern hjälpa till att lägga budget och tidsplan för plattform och drift för kommande år. Shaya Solutions är stolta över att kunna bidra med denna kompetens.",
      start: "Uppdraget startades Q2 2020.",
      alt: "bild på teknik",
      id: "11",
      type: "it"
    },
    {
      title: "VAERNA stöttar elbolag med en Supporttekniker för samhällsviktiga IT-system",
      body: "Kunden behöver stärka sitt team med en supporttekniker som ska vara med i deras arbete i att bygga upp en ny, spännande supportfunktion som supporterar och övervakar samhällsviktiga system. Samtidigt som funktionen etableras kommer kunden även bygga upp en ny kunskapsdatabas.Arbetet innebär att etablera en ny supportfunktion inklusive övervakning samt bygga en kunskapsdatabas tillsammans med förvaltningarna.",
      start: "Uppdraget startades Q3 2020.",
      arbetsuppgifter: ["Support av IT-system och dess applikationer",
        "Övervaka system och flöden",
        "Bygga en kunskapsdatabas genom samarbete med förvaltningarna",
        "Ärendehantering",
        "Tätt samarbete med incident/problem manager för både proaktivt och reaktivt arbete.",
        "Koordinera entreprenörer och leverantörer vid störningar",
      ],
      alt: "bild på teknik",
      id: "12",
      type: "it"
    },
    {
      title: "VAERNA stöttar kund med förstärkning av en Utvecklare",
      body: "Kunden behövde stärka sitt team som arbetar med utveckling av kommunikationsprotokoll med en erfaren utvecklare. Kunden har på sina fordon legacy sensorer som mäter antal passagerare. Denna information är strategisk viktig för kunden från ekonomisk uppföljning, planering, nya avtal till forskning och extern kommunikation. Efter att kunden tagit beslut om utfasning av befintliga systemet har ett projekt initierats i uppdrag att designa och utveckla en ny systemlösning som kommunicerar med dessa legacy sensorerna i fordon och kunna inhämta data samt utföra olika serviceåtgärder remote eller direkt på fordonen. Systemlösningen kan komma att köras i en bärbar dator eller centralt on-prem eller deployas i en molnmiljö. I projektet ingår också modernisering av kommunikationsutrustning på fordon från GSM till 4G som ska integreras med den nya systemlösningen.",
      start: "Uppdraget startades Q2 2020 och avslutades Q2 2021.",
      arbetsuppgifter: ["implementera seriellt protokoll, RS232 som hämtar in passagerarmätning från fordonen.",
        "Skapa funktionsbibliotek/tjänster som laptopapplikationen och cloudapplikationen kan använda sig av.",
      ],
      alt: "bild på teknik",
      id: "13",
      type: "it"
    },

    {
      title: "VAERNA stöttar myndighet med en angularspecialist",
      body: "Kunden behövde stärka sitt team som arbetar med ett av deras kritiska verksamhetssystem med en erfaren Angularspecialist. Efter flera års utveckling driftsattes systemet 2019 och ett intensivt arbete tog vid för att säkra drift och stabilisera applikationen. Applikationen bygger på Platina som ärendehanteringsmotor med omfattande egenutvecklad kod i .NET och AngularJS. Systemet integrerar med ett stort antal av de gemensamma tjänster som kundens IT-avdelning utvecklat bl.a. för distribution och hantering av personuppgifter. Arbetet sker efter ett agilt förhållningssätt inom ramen för kundens styrande regler. Kunden behöver genomföra en (teknisk 1:1) migrering av frontend från AngularJS till Angular och behövde därför en person som har erfarenhet av både frontend/Angular-arkitektur, ledarskap och Angular-utveckling.",
      start: "Uppdraget startades Q1 2021.",
      arbetsuppgifter: ["Säkerställa byggmiljöer, arkitektur, beroenden till externa komponenter m.m.",
        "Säkerställa ett iterativt genomförande, dvs. att allt från byggmiljö till webbapplikation fungerar att köra parallellt med både AngularJS och Angular på ett bra sätt.",
        "Framtagande/granskning av tidplan och estimat för genomförande.",
        "Genomförande av migrering.",
        "Agera lead-developer under genomförandet och därmed både utveckla samt leda andra utvecklare.",
        "Sätter grundläggande frontendarkitekturen.",
        "Utvecklar och implementerar mjukvarulösningar enligt ställda krav och design.",
        "Utveckling sker i HTML, CSS, TypeScript/Javascript, Angular mot API:er i en .NET-baserad applikation.",
        "Genomför felsökning, isolerar och korrigerar mjukvarufel.",
        "Utför enhetstester och integrationstester.",
        "Dokumenterar implementerad funktionalitet.",
        "Justerar/optimerar prestanda.",
        "Levererar systemdokumentation.",
      ],
      alt: "bild på teknik",
      id: "15",
      type: "it"
    },
  ]

  const [width, setWidth] = useState(0);
  const sliderWrapper = useRef();

  useEffect(() => {
    setWidth(sliderWrapper.current.scrollWidth - sliderWrapper.current.offsetWidth);
  }, [])
  return (
    <>
    <div className="slider-container">
      <div className="slider-heading">
        <h1>Våra referensuppdrag</h1>
      </div>
      <motion.div className="slider-wrapper" ref={sliderWrapper} whileTap={{ cursor: "grabbing" }}>
        <motion.div className="inner-crousel1"
          drag="x"
          dragConstraints={{ right: 0, left: -width }}
        >
          {
            itCase && itCase.map((c, i) => {
              return (
                <div className="case-text-wrapper case case-slider">
                <h3 className="scrolling-h3">IT</h3> 
              <p>{c.title}</p>
              <Link className="slider-a" key={i} to={"/ettcase"} state={c} onClick={() => { window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }); }}>
              <img className="arrow-ld" src={arrow} alt="pil-höger" />
              </Link>
            </div>
              )
            })
          }


        </motion.div>
      </motion.div>

    {/* <p className="drag-p">(dra för att visa fler)</p> */}
      </div >

     </>
  );
}

export default MySliderIt;