import "./Comp.css"
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

const CompProfile = () => {
    useEffect(() => {
        window.scrollTo({top: 0, left: 0});
       
      }, []);

    const navigate = useNavigate();
    const location = useLocation()
    const profile = location.state
    console.log(profile)
    
    return (
        <>
        <Header />
        <div className="container-comp-profile">
                <main className="one-profile-section">
                    {
                        <article key={profile.id} className="one-profile-preview">
                            <h2 className="comp-name">{profile.name}</h2>
                        <img src={profile.img} alt={`bild på ${profile.name}`} className="profile-comp-img"/>
                        <div className="profile-competense-div">
                            <ul className="comp-profile-ul">
                                    {<li><b>Tillgänglighet:</b> {profile.competenses.availability}</li>}
                                    {<li><b>År av erfarenhet:</b> {profile.competenses.experience}</li>}
                                    {<li><b>Område:</b> {profile.competenses.area}</li>}
                                    {<li><b>Expertis:</b> {profile.competenses.expertise}</li>}
                            </ul>
                        </div>

                        <div className="comp-intro">
                            <h3 className="comp-rubrik">{profile.titel}</h3>
                            <p className="comp-intro-p">{profile.background}</p>
                        </div>
                        <div className="comp-questions">
                            {
                               
                            profile.questions && profile.questions.map((q, i) => {
                                return(
                            <div className="comp-each-question-and-awnser" key={i}>
                                
                                <h4 className="question">{q.question}</h4>
                                <p className="comp-intro-p">{q.awnser}</p>
                                
                            </div>
                                )

                            })
                            
                            }
                            <button onClick={() =>{navigate("/konsulter-och-kompetenser")}} className="btn-blue comp-btn">Tillbaka till kompetenser</button>
                        </div>
                        
                    </article>
                    }      
                </main>
        </div>
        <Footer />
        </>
    );
}

export default CompProfile;