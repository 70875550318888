
import Header from "../../components/Header/Header"
import Footer from "../../components/Footer"
import WhatWeDoTeknikComponent from "../../components/WhatWeDoTeknikComponent/WhatWeDoTeknikComponent"
import { useEffect } from "react"
import WhatWeDo from "../../components/WhatWeDOo/WhatWeDo"
const WhatWeDoTeknik = () => {
    useEffect(() => {
        window.scrollTo({top: 0, left: 0});
       
      }, []);


    return (
        <>
        <Header />
        <div className="container-ITCases">
            
            <div className="ITCases-wrapper">
                {/* <h1>Teknik</h1> */}
                <div className="ITCases-hero">
                    <h2>Expertområde OT ARKITEKTUR OCH INTEGRATION</h2>
                    <p className="slow-fade">Shaya Solutions OT-arkitekter fokuserar vanligtvis på design, konstruktion och
implementering av hela OT-systemet. De ser till att systemet fungerar som det
ska, är säkert, robust och uppfyller alla tekniska och affärsmässiga krav. De
ansvarar för att välja rätt teknik, nätverksdesign, hårdvara och mjukvara, samt
för att säkerställa systemets övergripande hälsa och prestanda.
OT-integrationsarkitekten specialiserar sig normalt på att koppla ihop, eller integrera,
OT-system med andra system, oavsett om det är andra OT-system, IT-system
eller externa partnersystem. Deras huvudfokus är att säkerställa en smidig,
säker och effektiv dataöverföring mellan olika system.</p>
                </div>
            </div>

         {   <WhatWeDoTeknikComponent />}
         {<WhatWeDo/>}
        </div>
        <Footer />
        </>
    );
}

export default WhatWeDoTeknik;