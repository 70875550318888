
import Header from "../../components/Header/Header"
import Footer from "../../components/Footer"
import WhatWeDoManagementComponent from "../../components/WhatWeDoManagementComponent/WhatWeDoManagementComponent"
import { useEffect } from "react"
import WhatWeDo from "../../components/WhatWeDOo/WhatWeDo"
const WhatWeDoManagement = () => {
    useEffect(() => {
        window.scrollTo({top: 0, left: 0});
       
      }, []);


    return (
        <>
        <Header />
        <div className="container-ITCases">
            
            <div className="ITCases-wrapper">
                {/* <h1>Management</h1> */}
                <div className="ITCases-hero">
                    <h2>Expertområde NÄTVERKSTEKNIK</h2>
                    <p className="slow-fade">Shaya Solutions nätverkstekniker ansvarar för att designa, implementera, övervaka och
underhålla nätverksinfrastrukturer. Beroende på arbetsplatsens storlek och
komplexitet kan rollens krav variera, men här är några allmänna
kompetensområden som ofta anses vara relevanta för våra nätverkstekniker i
projekt och uppdrag.</p>
                </div>
            </div>

         {   <WhatWeDoManagementComponent />}
         {<WhatWeDo/>}
        </div>
        <Footer />
        </>
    );
}

export default WhatWeDoManagement;