import { Link } from "react-router-dom";
import logo from "../img/shaya-logo.png"
import "./Footer.css";
import FadeInSection from "./FadeInSection";

import di_vit from "../img/di-vit.png"

import iso9001 from "../img/ISO9001.png"
import iso14001 from "../img/ISO14001.png"
const Footer = () => {

 <FadeInSection/>
    return (
        <>
        
        <footer className="footer-container">
            <FadeInSection>
                <div className="iso-adress-wrapper">
          
            <div className="iso-div">
            <img className="iso" src={di_vit} alt="" />
            <img className="iso" src={iso9001} alt="iso-img-orange" />
            <img className="iso" src={iso14001} alt="iso-img-green" />

            </div>
      
            </div>
            </FadeInSection>
            <div className="footer-content-wrapper">
            <div className="wrapper footer-adress">
            <FadeInSection>
            <div>
            <p>Adress och kontaktuppgifter</p>
            <div className="paragraph-flex">
            <p className="white-paragraph">Olof Palmes gata 11, c/o iOffice <br></br>111 37 Stockholm</p>
                <p className="white-paragraph">Support@shayasolutions.se</p>
            </div>
            </div>
            </FadeInSection>
            </div>
            <FadeInSection>
     
            <div className="wrapper">
                <p>VAERNA OT IT AB</p>
                <Link to={"/om-oss"} className="link">Om oss</Link>
                {/* <Link to={'/konsulter-och-kompetenser'}  className="link">Konsulter och kompetenser</Link> */}
                <Link to={"/nyheter"} className="link">Nyheter</Link>
                {/* <Link to={"/guidelines"} className="link">Guidelines</Link> */}
             {/* <Link to={'/nadia-initiativet'} className="link">Nadia initiativet</Link> */}
                {/*<Link to={'/faq'} className="link">FAQ</Link>*/}
                
            </div>
            </FadeInSection>
            <FadeInSection>
            <div className="wrapper">
            <p>Vår verksamhet</p>
                <Link to={'/konsult'} className="link">Konsult</Link>
                <Link to={'/kund'} className="link">Kund</Link>
               {/* <Link to={'/partner'} className="link">Partner</Link>*/}
            </div>
           
            </FadeInSection>
            <FadeInSection>
            <div className="wrapper">
                <p>Referensuppdrag</p>
                <Link to={'/it-cases'} className="link">IT</Link>
                <Link to={'/management-cases'} className="link">Management</Link>
                <Link to={'/teknik-cases'} className="link">Ingenjörstjänster</Link>
            </div>
  </FadeInSection>
  </div>
        </footer>
        </>
    )
}

export default Footer;