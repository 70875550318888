import "../Handbok.css"
import Footer from "../../../components/Footer";
import Header from "../../../components/Header/Header";
import { Link } from "react-router-dom";

import { useEffect } from "react";

const HandbokVarderingar = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 });
    }, []);
    return (
        <>
            <Header />
            <div className="handbook-con-wrapper">
                <main>
                    <section className="handbok-menu">
                        <div className="handbook-wrapper">
                            <h1 className="h1-separat">👋 Kontakt vid frågor eller funderingar</h1>
                            <article className="handbok-article">
                                <p>👋 Kontakta support@shayasolutions.se per mail vid;
                                    <br />
                                    <br />
                                    •	Generella frågor (inga frågor är för små 😄!)
                                    <br />
                                    •	Onboarding
                                    <br />
                                    <br />
                                    Kontakta ekonomi@shayasolutions.se per mail vid;
                                    <br />
                                    <br />
                                    •	Lönefrågor
                                    <br />
                                    •	PE Accounting
                                    <br />
                                    •	Generella ekonomifrågor
                                    <br />
                                    •	Föräldraledighet
                                    <br />
                                    •	Semester
                                    <br />
                                    •	Sjukskrivning
                                    <br />
                                    •	Pension
                                    <br />
                                    •	Inköp av elektronik
                                    <br />
                                    <br />
                                    Kontakta avtal@shayasolutions.se per mail vid frågor gällande;
                                    <br />
                                    <br />
                                    •	ditt avtal
                                    <br />
                                    •	ombudsfrågeställningar som berör dig eller konsultuppdraget
                                    <br />
                                    •	uppsägning
                                    <br />
                                    <br />
                                    <b>Shaya Solutions AB’s Organisationsnummer – 559058-6649</b>
                                </p>
                            </article>
                        </div>
                    </section>
                </main>
            </div>
            <Footer />
        </>
    );
}

export default HandbokVarderingar;