import "../Handbok.css"
import Footer from "../../../components/Footer";
import Header from "../../../components/Header/Header";
import { Link } from "react-router-dom";

import { useEffect } from "react";

const HandbokKontaktPersoner = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 });
      }, []);
    return ( 
        <>
                <Header/>
                <div className="handbook-con-wrapper">
        <main>
        <section className="handbok-menu">
        <div className="handbook-wrapper">
            <h1 className="h1-separat">🌵 Friskvårdsbidrag</h1>
            <article className="handbok-article">
                    <p>👋 På Shaya Solutions erbjuder vi ett årligt friskvårdsbidrag på 2000 kr.
                        <br />
                        <br />
                        Rätten till friskvårdsbidrag på 2000 kr per år gäller från den dag då köpet av friskvård genomförs. Bidraget fylls på ett år efter inköpsdatumet.
                        <br />
                        <br />
                        På följande hemsida kan du se vad som klassas som friskvård (det är mer än du tror!): Vad räknas som friskvård?
                        <br />
                        <br />
                        För att kunna dra av en kostnad som friskvård krävs det alltid ett originalkvitto (fysiskt eller elektroniskt) där ditt namn tydligt framgår (inskrivet på kvittot). Helst bör även personnumret finnas på kvittot.
                        <br />
                        <br />
                        Om det inte är möjligt att få ett nytt kvitto eller att få namnet inskrivet på kvittot, måste ett kontoutdrag ordnas för att kunna koppla kvittot till personen.
                        <br />
                        <br />
                        Du kan ladda upp dina utlägg på din personliga PE Accounting-sida genom att ladda upp en bild eller fil på utlägget. 
                        <br />
                        <br />
                         </p>
                         <a href="https://pe.infocaption.com/_3db881afaf416d36.guide?pageNumber=2 ">Här är en guide om hur utlägg laddas upp på PE</a>
                            <p>
                            På PE kan du även följa statusen på dina utlägg, till exempel om de har godkänts av Shaya Solutions ekonomi team.
                            <br />
                            <br />
                            Viktigt – alla kvitton måste sparas för vår bokföring (digitala eller fysiska). 
                            <br />
                            <br />
                            De fysiska skickas in löpande till vår postadress: Klarabergsgatan 60, 111 21 Stockholm, C/O The Works. 
                            </p>
                            <a href="https://www.skatteverket.se/privat/skatter/arbeteochinkomst/formaner/personalvardmotionochfriskvard.4.7459477810df5bccdd4800014540.html ">Läs mer på Skatteverket här</a>
                </article>
            </div>
        </section>
    </main>
    </div>
   <Footer/>
    </>
     );
}
 
export default HandbokKontaktPersoner;