import cecilia from "../../img/UppsalaUniversitet.jpeg";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer";
import { useEffect } from "react";
import "./NewNews1.css";
const NewNews2 = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 });
    
      }, []);
    return ( 
        <><Header/>
        <div className="container-newNews">               
        <div className="newNews-wrapper">
        <span>#Nyhet</span>
            <h1>Ett kärt återseende av Ångströmlaboratoriet och Uppsala Universitet</h1>
            <img src={cecilia} alt="" />
            <p>”Ett kärt återseende med mitt älskade Uppsala universitet och det nya framväxande
                 Ångströmslaboratoriet.
                 Fantastiskt inspirerande att få möta ingenjörsstudenterna i egenskap av alumn”,
                  säger Shaya Solutions vd, grundare och ägare Joseph Öberg Shaya.</p>
            </div>    
        </div>
        <Footer/>
        </>
     );
}
 
export default NewNews2;