import "../Handbok.css"
import Footer from "../../../components/Footer";
import Header from "../../../components/Header/Header";
import { Link } from "react-router-dom";

import { useEffect } from "react";

const HandbokViktigInfo = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 });
      }, []);
    return ( 
        <>
                <Header/>
                <div className="handbook-con-wrapper">
        <main>
        <section className="handbok-menu">
        <div className="handbook-wrapper">
            <h1 className="h1-separat">💎 Ledighet för föräldrar</h1>
            <article className="handbok-article">
                    <p>👋 På Shaya Solutions har medarbetare med barn under 8 år rätt till
                         ledighet från arbetet, oavsett om de erhåller föräldrapenning från Försäkringskassan eller inte.
                          Vi erbjuder följande alternativ för medarbetaren:</p>
                          <br />
                          <ul>
                            <li>•   Möjligheten att ta en sammanhängande ledighet.</li>
                            <br />
                            <li>•   Flexibiliteten att förkorta sin arbetstid med upp till en fjärdedel av den normala arbetstiden.</li>
                            <br />
                            <li>•   Möjligheten att dela upp ledigheten i högst tre perioder under varje kalenderår.</li>
                          </ul>
                          <br />
                          <p>Vi värdesätter individens behov och strävar efter att hitta överenskommelser som fungerar bäst för både medarbetaren och företaget. Om det finns enighet kan medarbetaren också dela upp
                             ledigheten över fler perioder. Vi arbetar aktivt för att skapa en arbetsmiljö som främjar
                              balansen mellan arbete och familjeliv.</p>
                              <a href="https://www.forsakringskassan.se/privatperson/foralder/foraldrapenning#:~:text=har%20ensam%20v%C3%A5rdnad-,F%C3%B6r%C3%A4ldrapenning%20betalas%20ut%20i%20480%20dagar%20f%C3%B6r%20ett%20barn.,r%C3%A4tt%20till%20alla%20480%20dagarna">Läs mer på Försäkringskassan här</a>
                </article>
            </div>
        </section>
    </main>
    </div>
   <Footer/>
    </>
     );
}
 
export default HandbokViktigInfo;

