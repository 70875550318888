
import Header from "../../components/Header/Header"
import Footer from "../../components/Footer"
import WhatWeDoItComponent from "../../components/WhatWeDoItComponent/WhatWeDoItComponent"
import WhatWeDo from "../../components/WhatWeDOo/WhatWeDo"
import { useEffect } from "react"
const WhatWeDoIt = () => {
    useEffect(() => {
        window.scrollTo({top: 0, left: 0});
       
      }, []);


    return (
        <>
        <Header />
        <div className="container-ITCases">
            
            <div className="ITCases-wrapper">
                {/* <h1>IT</h1> */}
                <div className="ITCases-hero">
                    <h2>Expertområde AUTOMATION</h2>
                    <p className="it-cases-p">En Automationsingenjör arbetar med att utforma, implementera, underhålla
och förbättra automatiserade system. Beroende på den specifika branschen
och applikationen kan kompetenskraven variera, men här är några allmänna
kompetensområden som ofta anses vara relevanta för våra
automationsingenjörer i projekt och uppdrag.</p>
                </div>
            </div>

         {   <WhatWeDoItComponent />}
            {<WhatWeDo/>}
        </div>
        <Footer />
        </>
    );
}

export default WhatWeDoIt;