import "../Handbok.css"
import Footer from "../../../components/Footer";
import Header from "../../../components/Header/Header";
import { Link } from "react-router-dom";

import { useEffect } from "react";

const HandbokVarderingar = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 });
    }, []);
    return (
        <>
                <Header/>
                <div className="handbook-con-wrapper">
            <main>
                <section className="handbok-menu">
                    <div className="handbook-wrapper">
                        <h1 className="h1-separat">👋 Kompetensutveckling</h1>
                        <article className="handbok-article">
                            <p>👋 Du har rätt att ansöka om access till en årlig utbildningspott som finns inom bolaget.
                                 Denna pott kan uppgå till maximalt 20 000 SEK (inklusive moms) för att stödja ditt
                                  lärande och fortsatta karriärutveckling hos Shaya Solutions.
                                  <br />
                                  <br />
                                  Detta baseras på motiveringen hos medarbetaren där nyttan i utbildningen ska gå hand i hand till
                                   fördel för medarbetaren, konsultuppdraget/kunden samt
                                   att det är i den riktning som gagnar Shaya Solutions specialisering. All utbildningstid genomförs
                                    utanför ordinarie arbetstid och faktureras inte till kunder.                                
                                  </p>
                        </article>
                    </div>
                </section>
            </main>
            </div>
            <Footer />
        </>
    );
}

export default HandbokVarderingar;