import Header from "../components/Header/Header";
import FooterFaq from "../components/FooterFaq";
import "./Contact.css"
import svg from "../img/258.svg"
import { useEffect } from "react";

const Contact = () => {
    useEffect(() => {
        window.scrollTo({top: 0, left: 0});
       
      }, []);

    return (
        <>
        <Header />
        <div className="contatct-container">
            
            <main className="contact-main fade">
                
                <div className="info-contact">
                <div className="contact-info">
                <h1 className="contact-header">Kontakt</h1>
                <p>Vänligen maila <a className="email-a" href="mailto:support@shayasolutions.se" target="_blank" rel="noopener noreferrer">support@shayasolutions.se</a> för att få hjälp med ditt behov.</p>
                {/* <img className="svg-contact" src={svg} alt="" /> */}
                </div>
                
                </div>
                {/* <form className="contact-form">
                    <input type="text" className="input" id="first-name" placeholder="Förnamn"/>
                    <input type="text" className="input" id="second-name" placeholder="Efternamn"/>
                    <input type="text" className="input" id="number-name" placeholder="Nummer"/>
                    <textarea name="" id="message" cols="30" rows="10" className="input input-textarea" placeholder="Meddelande"></textarea>
                    <button className="btn">Skicka</button>
                </form> */}
            </main>
            
        </div>
        <FooterFaq />
        
        </>
    );
}

export default Contact;