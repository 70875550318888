import "../Handbok.css"
import Footer from "../../../components/Footer";
import Header from "../../../components/Header/Header";
import { Link } from "react-router-dom";

import { useEffect } from "react";

const HandbokVarderingar = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 });
    }, []);
    return (
        <>
                <Header/>
                
                <div className="handbook-con-wrapper">
            <main>
                <section className="handbok-menu">
                    <div className="handbook-wrapper">
                        <h1 className="h1-separat">💎 Shaya Solutions huvudkontor</h1>
                        <article className="handbok-article">
                            <p>👋 Shaya Solutions har avtal med kontorslösningar över hela Sverige för att ge access till konsulter där våra kunder erbjuder uppdrag.
                                <br />
                                <br />
                                Vårt huvudkontor ligger på Klarabergsgatan 60, 111 21 Stockholm.
                            </p>
                        </article>
                    </div>
                </section>
            </main>
            </div>
            <Footer />
        </>
    );
}

export default HandbokVarderingar;