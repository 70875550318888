import "../Handbok.css"
import Footer from "../../../components/Footer";
import Header from "../../../components/Header/Header";
import { Link } from "react-router-dom";

import { useEffect } from "react";

const HandbokVarderingar = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 });
    }, []);
    return (
        <>
                <Header/>
                <div className="handbook-con-wrapper">
            <main>
                <section className="handbok-menu">
                    <div className="handbook-wrapper">
                        <h1 className="h1-separat">🌵 Ledighet för personlig utveckling / viloperiod</h1>
                        <article className="handbok-article">
                            <p>👋 Efter att ha varit anställd hos Shaya Solutions i fem år har du möjlighet att ta en välförtjänt 5-veckors paus
                                 med full lön. Detta utöver din ordinarie ledighet. Vi erkänner och värdesätter ditt engagemang och lojalitet,
                                  och vi tror att det är viktigt att erbjuda våra anställda tid för återhämtning och avkoppling.
                                 Genom att ge dig denna möjlighet strävar vi efter att främja en hälsosam balans mellan arbete och
                                  privatliv samt visa vår uppskattning för din insats.
                            </p>
                        </article>
                    </div>
                </section>
            </main>
            </div>
            <Footer />
        </>
    );
}

export default HandbokVarderingar;