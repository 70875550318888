import "../Handbok.css"
import Footer from "../../../components/Footer";
import Header from "../../../components/Header/Header";
import { Link } from "react-router-dom";

import { useEffect } from "react";

const HandbokVarderingar = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 });
    }, []);
    return (
        <>
                <Header/>
                <div className="handbook-con-wrapper">
            <main>
                <section className="handbok-menu">
                    <div className="handbook-wrapper">
                        <h1 className="h1-separat">👋 Elektronik</h1>
                        <article className="handbok-article">
                            <p>👋 Behöver du någon speciell elektronik för ditt uppdrag?
                                <br />
                                <br />
                                Vi köper idag våra produkter från Dustin.se resp. Elgiganten,
                                 vilket innebär att all elektronik du behöver för ditt uppdrag
                                  (om sådan behövs) köps in via dessa aktörer. För att kunna påbörja
                                   inköpsprocessen krävs att anställningsavtalet med Shaya Solutions
                                    och kundavtalet är på plats.
                                <br />
                                <br />
                                Om du behöver en dator eller liknande för uppdraget,
                                 kontakta ekonomi@shayasolutions.se innan du gör något
                                  inköp för att få råd och vägledning.
                                 Alla köp som genomförs innan ett godkännande riskerar att avslås.
                            </p>
                        </article>
                    </div>
                </section>
            </main>
            </div>
            <Footer />
        </>
    );
}

export default HandbokVarderingar;