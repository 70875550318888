import "./EachField.css"
import Header from "../../components/Header/Header"
import Footer from "../../components/Footer"
import WhatWeDoItComponent from "../../components/WhatWeDoItComponent/WhatWeDoItComponent"
import { useLocation, useNavigate } from "react-router"


const EachField = () => {
    const location = useLocation()
    
    
    const obj = location.state;
    
    let focus = obj.fokus;
    return (
        <>
        <Header />
        <div className="container-eachfield">
            
        <main className="eachfield-main">
            
                <h1>{obj.title}</h1>
                <div className="first-textarea-field">
                    <p>{obj.body}</p>
                </div>

              <div className="second-container-field">
                
                
                    { obj.fokus &&
                    <div  className="second-textarea-field fade">
                        <h3>De fokusområden som våra konsulter tillhandahåller och innehar som kompetens är:</h3>
                        <ul className="list-field">
                        {
                            obj.fokus && obj.fokus.map((f, i)=>{
                                return(
                                    <li key={i}>{f}</li>
                                )
                            })
                        }
                        </ul>
                        
                    </div>
                    }   
                        {
                obj.body2 && <p className="body2-field">{obj.body2}</p>
                }
                </div> 
                

                <div className="cases">
                    <WhatWeDoItComponent />
                </div>
                
        </main>
        
        </div>
        <Footer />
        </>
    );
}

export default EachField;