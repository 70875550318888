import shayadi from "../../img/shaya_di.png"
import joseph_di from "../../img/joseph_di.jpeg"
import konsult_sommar from "../../img/konsult_sommar.jpeg"
import di_white from "../../img/di_white.png"

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer";
import { useEffect } from "react";
import "./NewNews1.css";
const NewNews2 = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });

  }, []);
  return (
    <><Header />
      <div className="container-newNews">
        <div className="newNews-wrapper">
          <span>#Nyhet</span>
          <h1>SHAYA SOLUTIONS AB (numera VAERNA OT IT AB) UTSETT TILL GASELL BOLAG AV DAGENS INDUSTRI</h1>
          <img src={shayadi} alt="" />
          <p>WOW 🤩🙏🏽 Teamet på Shaya Solutions är så stolta över utmärkelsen som Dagens industri Gasellföretag ❤️
Vi kunde inte skrivit det bättre än Peter Fellman, chefredaktör på Dagens Industri, som skriver i sitt brev;
 ”Vi på Dagens industri tycker att det gör er till en av Sveriges hjältar. Ni har byggt ett av Sveriges snabbast växande
  och mest lönsamma företag. Ni skapar jobb för människor, ni driver tillväxt för Sverige och inspirerar andra att följa
   i dina fotspår.”</p>

   <img src={joseph_di} alt="" />

   <p>”Resultatet är ett kvitto på att vad teamet på SHAYA SOLUTIONS AB gjort de senaste fem åren, vecka in och vecka ut,
     allt vad vi kan för att skapa värde för våra kunder, konsulter, underkonsulter, partnerbolag samr anställda”,
     <br /> säger vår VD, grundare och ägare Joseph Öberg Shaya och fortsätter;
”Vi jobbar i en extremt konkurrensutsatt tjänstesektor och det är människorna i och kring bolaget som utgör själva värdet.
 Tack vare människorna kan bolaget existera och finnas till.</p>

 <img src={konsult_sommar} alt="" />

 <p>Sedan år 2000 har Dagens industri utsett de snabbast växande företagen i vart och ett av Sveriges 21 län,
   vid åtta prisutdelningar från Malmö i söder till Umeå i norr. Gasellföretagen har tagit sig
   igenom de högt ställda krav och det nålsöga som endast ett hundratal företag i Sverige lyckas med, som är färre än 1%.
   <br />
<br /> Datum: December 2023.
   </p>

   <img src={di_white} className="di-width-100" alt="" />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default NewNews2;