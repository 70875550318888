import "../Handbok.css"
import Footer from "../../../components/Footer";
import Header from "../../../components/Header/Header";
import { Link } from "react-router-dom";

import { useEffect } from "react";

const HandbokCvMall = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 });
      }, []);
    return ( 
        <>
                <Header/>
                <div className="handbook-con-wrapper">
        <main>
        <section className="handbok-menu">
        <div className="handbook-wrapper">
            <h1 className="h1-separat">👋 Ledighet i samband med graviditet och förlossning </h1>
            <article className="handbok-article">
                    <p>👋 En medarbetare på Shaya Solutions som är gravid har rätt till heltidsledighet
                         i samband med sin barns födelse. Hen har rätt att vara ledig under en sammanhängande
                          period på minst sju veckor före den förväntade förlossningsdatumet och sju veckor efter förlossningen.
                           Vi ser till att ge adekvat stöd och flexibilitet för att säkerställa att våra medarbetare kan ta den ledighet de 
                           behöver för att ta hand om sin hälsa och sitt välbefinnande under denna viktiga tid
                           i deras liv. Vi strävar efter att skapa en positiv och stödjande arbetsmiljö för alla våra medarbetare. 🙂</p>
                </article>
            </div>
        </section>
    </main>
    </div>
   <Footer/>
    </>
     );
}
 
export default HandbokCvMall;