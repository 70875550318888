import { Link } from "react-router-dom";
//COMPONTENTS
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer";
import WhatWeDo from "../../components/WhatWeDOo/WhatWeDo";
//STYLING
import "./Customer.css"
import { useEffect } from "react";

import FadeInSection from "../../components/FadeInSection";
const Customer = () => {
    useEffect(() => {
        window.scrollTo({top: 0, left: 0});
       
      }, []);
    <FadeInSection/>
    return (
        <>        
        <Header />
        <div className="container-customer">
            
            <div className="customer-content-container">
                <h1>Kompetens för din organisation</h1>
                <p className="hero-text">
                Söker du kompetens med rätt personegenskaper? VAERNA är ett nischat bolag inom automation och styrsystem med stark koppling till OT/IT säkerhet.
                </p>
          
                <div className="process-customer-wrapper">
                <h2>Hur det går till</h2>
                    <div className="process-text-and-number-customer">
                    <span>I</span>
                    <p>Ni kontaktar oss per mail via <a className="email-a" href="mailto:support@shayasolutions.se" target="_blank" rel="noopener noreferrer">support@shayasolutions.se.</a> </p>
                    </div>
                    <div className="underline-large-white"></div>
                    <div className="process-text-and-number-customer">
                    <span>II</span>
                    <p> Vi ser över ert behov och återkopplar kring huruvida vi har kapacitet samt personer att presentera. Ni ges möjlighet att bjuda in relevant kandidat för intervju, fysiskt eller digitalt.</p>
                    </div>
                    <div className="underline-large-white"></div>
                    <div className="process-text-and-number-customer">
                    <span>III</span>
                    <p>Vid korrekt matchning tecknar VAERNA avtal med dig som kund (såvida avtal inte redan finns). <br /> Ovan process förväntas ske inom en vecka.</p>
                    </div>
                </div>
                <Link to={'/kontakt'} className="btn-blue margin3rem">Kontakta oss</Link> 
                {/* WHAT WE DO COMPONENT */}
                <FadeInSection>
                <WhatWeDo />
                </FadeInSection>
            </div>
       
        </div>
        <Footer />
        </>

    );
}

export default Customer;