import "./FAQ.css"
import Header from "../../components/Header/Header";
import FooterFaq from "../../components/FooterFaq";

import { useEffect } from "react";

import { Link } from "react-router-dom";
const ITCases = () => {
    useEffect(() => {
        window.scrollTo({top: 0, left: 0});
       
      }, []);


    return (
        
        <>
        <Header />
        <div className="container-FAQ">
        <h1>FAQ</h1>
                        <article className="FAQ-article">
                            <div className="FAQ-box">
                                <h4 className="question">Hur ser avtalsvillkoren ut?</h4>
                                <p className="comp-intro-p">Det varierar från kund till kund. Vi speglar kundvillkoren. Är det något villkor som du som konsult vill avvika ifrån så kan du föreslå detta för Shaya Solutions. Vi kommer att vikta din fråga gentemot en kostnad. Är det så att vi inte kommer överens pga. avtalsvillkoren så fokuserar vi på nya kunder med andra villkor.</p>
                            </div>

                            <div className="FAQ-box">
                                <h4 className="question">Vad är det för betaltid?</h4>
                                <p className="comp-intro-p">Det varierar från kund till kund. Vi tillämpar ’Pay when Paid’ villkor, dvs. att vi betalar konsulten efter att vi fått betalt från kunden. Vill du som konsult ha snabbare betaltid så är detta möjligt mot en kostnad. Denna kostnad speglar vår upplåningskostnad för den aktuella perioden.</p>
                            </div>

                            <div className="FAQ-box">
                                <h4 className="question">Vad har ni för marginal?</h4>
                                <p className="comp-intro-p">Denna information delar vi inte med oss hur som helst av konkurrensskäl, då den är högst avgörande i samband med anbudsinlämning. Väljer du att skriva på ett exklusivitetsavtal inför upphandling under en överenskommen period så kan vi dela med oss av denna information för specifika uppdrag och kunder. <br /><br /> I övrigt är vår rekommendation att du fokuserar på din ersättning då ”90% av 100 är mer än 95% av 90”. Så det ligger i vårt intresse att timpriset är så högt som möjligt för dig givet att vi har på %-sats påslag på ditt angivna timpris. Sedan är det stark konkurrens på majoriteten av uppdragen, om inte alla uppdragen, där kunderna givetvis är prismedvetna och kommer att utmana. Samtidigt som vi är intresserade av att vinna uppdragen. <br /><br /> Summa summarum, bottna i den ersättning som är viktig för dig. Vi kommer göra vårt bästa i att landa affären.</p>
                            </div>
                            <div className="FAQ-box">
                                <h4 className="question">Vem är kunden?</h4>
                                <p className="comp-intro-p">Vissa av kunderna är extremt noga med att de inte vill att konsultbolag och/eller konsultmäklare ska ”skylta” med deras företagsnamn. <br /> <br /> Normalt har kunderna 3–20 ramavtalsleverantörer. Att bearbeta kunderna och underhålla relationerna tar tid. Att därför delge denna information i en tidig fas är därför att ge bort värdefull information då du som konsult/partner inte möjligtvis är bunden exklusivt till oss och kan vända dig till andra parter. Detta förfarande har båda sina fördelar och sina nackdelar. Det är därför viktigt att vi etablerar en god och ömsesidig relation och förståelse för varandras situation.</p>
                            </div>
                            <div className="FAQ-box">
                                <h4 className="question">Accepterar ni underkonsulter?</h4>
                                <p className="comp-intro-p">Ja, underkonsulter utgör en högst viktig del av vår verksamhet.</p>
                            </div>
                            <div className="FAQ-box">
                                <h4 className="question">Jag vill vara anställd konsult idag, men planerar att starta eget i framtiden, accepterar ni detta?</h4>
                                <p className="comp-intro-p">Det går utmärkt. Vi är ett bolag som har fokus på konsulter som antingen är på väg att starta egna bolag och vill hitta trygghet i en anställning innan de startar ett bolag, alternativt om man redan har ett bolag, men som kan tänka sig att hoppa in i en anställning om det egna företagandet inte är rätt ”tajmat” för tillfället. <br /><br /> Skillnaden är enkel för oss, anställd konsult = högre marginal pga. ett större ansvar, risktagning och administration i relation om du är egenföretagare.</p>
                            </div>
                            <div className="FAQ-box">
                                <h4 className="question">Varför ska jag välja just er?</h4>
                                <p className="comp-intro-p">Vi tror på ödmjukhet så vårt svar är att du ska träffa och ha kontakt med flera bolag (såväl konsultmäklare som konsultbolag) parallellt. Du kommer efter en tid att hitta en aktör som passar just dig. <br /><br /> Vi kan omöjligt vara alla till lags på marknaden. För vissa är vi en bra matchning och för andra är vi inte lika bra matchning. Det gäller även dig. Du kan omöjligt vara alla i marknaden till lags. Utgå därför utifrån dina behov. Oavsett om du söker anställning, vill vara underkonsult, alternativt om du vill vara ett partnerföretag.</p>
                            </div>
                            <div className="FAQ-box">
                                <h4 className="question">Vi är ett nischat specialistbolag som vill leverera helhetsåtaganden i era multidisciplinära projekt, hur gör vi för att etablera en sån kontakt?</h4>
                                <p className="comp-intro-p">Maila oss på <a className="email-a" href="mailto:support@shayasolutions.se" target="_blank" rel="noopener noreferrer">support@shayasolutions.se </a>alternativt ring oss på <span>0738333089</span>, så etablerar vi en kontakt och tar det därifrån.</p>
                            </div>

                            
                        </article>

        </div>
        <FooterFaq />
        </>
        
    );
}

export default ITCases;