import { Link } from "react-router-dom";
import { useEffect } from "react";
import "./wwd.css";
import circle from "../../img/circle.png"

const WhatWeDoItComponent = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  /*
    const cases = [
        {
            title: "Offentlig sektor",
            body: "Våra informationssäkerhetstjänster möjliggör våra kunders digitala affärsmöjligheter genom att tillhandahålla optimerade ledande säkerhetstjänster som skyddar dina informationstillgångar, produkter och tjänster. Genom att förstå dina cyber- och informationssäkerhetsrisker möjliggör och optimerar vi din organisations motståndskraft mot hot. Detta hjälper till att säkerställa att digital transformation kan fortsätta obehindrat och kan till och med hjälpa till att påskynda digitala projekt.",

            body2: "Vi samverkar nära den anlitande organisationen där vi fokuserar på kärnverksamheten där vi med stor lyhördhet tillhandahåller innovativa, praktiska och lämpliga säkerhetstjänster och lösningar. Utifrån er verksamhet och era behov. Vi har erfarna konsulter med en klar förståelse av affärsvärde och risk. Våra tjänster är inte sektor- eller branschberoende.",
            alt: "bild på it",
            fokus: ["Digital säkerhet", "Cybersäkerhet", "Företagssäkerhet", "Säkerhetsstrategi, risk och styrning"],
            id: "1"
        },
        {
            title: "Energi och industri",
        
            alt: "bild på it",
            id: "2"
        },
        {
            title: "Elnät",
            body: "I en önsketänkande designprocess kommer UX-design före UI-design. Detta så att UX-designen kan lägga fokuset på hur produkten eller systemet ska uppfylla ett resultat, värde, upplevelse alternativt lösa ett problem. UX-designer ska därefter, i syfte att förstå hur och varför användaren ska använda produkten eller systemet - genomföra ett researcharbete alternativt förstudie som kan innefatta allt ifrån intervjuer, tester med användare och analys av data.UI bryts ner till beståndsdelar i ett användargränssnitt som gör det möjligt för användare att interagera med en produkt eller system tack vare en serie av visuella element. Ett UI element kan vara ett ljud, en knapp, en skärm eller en sida – allt som innebär ett slags informationsutbyte mellan användaren och produkten, där en känsla skapas i syfte att vägleda. UX är den interna upplevelsen hos en användare när den interagerar med produkten eller de element som produkten gestaltar och representerar. UX-design fokuserar på hur användaren uppfattar svårighetsgraden eller enkelheten i att lösa slutföra en uppgift eller en aktivitet. Detta kopplar starkt till de behov användaren har samt vilka förväntningar den personen har på produkten eller systemet, beroende på tidigare erfarenheter från liknande produkter och system",
   
            body2: "Användarcentrerad design har användaren som utgångspunkt och främsta fokus i alla steg av design- och utvecklingsprocessen. Detta sker genom analys och undersökning men även genom valideringstest i riktiga miljöer och situationer där användaren involveras. Shaya Solutions hjälper företag med användarcentrerad design för att skapa verklig användarnytta, affärsnytta och samhällsnytta. Detta genom att ha verkliga/befintliga användare som utgångspunkt och främsta fokus i alla steg av design- och utvecklingsprocessen, men där höjd även tas för framtida och nya användare som tidigare inte använt tjänsten/produkten. Tveka inte att fråga oss.",
            alt: "bild på it",
            fokus: ["Nya digitala tjänster utvecklats, omformats och vidareutvecklats som resulterat i såväl kund- som affärsnytta", " Förbättrat en digital tjänst ur användbarhetsperspektiv", "Utveckla nya funktioner i era befintliga miljöer", "Samverkat inom en stor och multidisciplinär organisation som levererat mångfacetterade tjänster till hundratusentals använder dagligen", "Hjälpt kunden att förstå sina målgrupper bättre för att kunna prioritera och optimera rätt givet tjänsteutvecklingen kopplat till hela organisationen"],
            id: "3"
        },
        {
            title: "Fastigheter och byggnader",
            body: "I en värld där en pandemi ritat om kartan för hur organisationer och projekt utvecklas och formas innebär det en ökad komplexitet på kravanalys. Kravanalys och kravhantering gör skillnad för våra kunder i flera led – inte enbart på projektnivå – utan direkt mot kund- och användarnivå då många produkter jobba med flera releaser parallellt i syfte att vara agila och snabba med att möta ett kundbehov. Shaya Solutions har bidragit till flertalet kunder där våra konsulter varit med i kravställningen av ny produkter och system inom samhällsbyggnad samt transportsektorn. Vår kundbas innefattar flera sektorer och branscher. Vi hanterar även kravställning och analys för webshoppar exempelvis. Det vanligaste våra konsulter inom kravhantering bemöter i en verksamhet är att behov och önskemål komma från användare av produkten eller tjänsten men också från avsändaren – ser olika ut i förhållande till kundorganisationens olika interna intressenter. Framgångsrika konsulter inom kravanalys blir därför avgörande för framgången för ett system- eller mjukvaruprojekt om de har en ytterst god kommunikations- och intressenthanteringsförmåga där kunder/användare sätts i centrum – i syfte att nå kärnverksamhetens huvudändamål och resultat. Våra konsulter har arbetat med kravhantering inom allt från webbprojekt och UX-förbättringar till integrationsprojekt och stora systembyten. Tveka inte att höra av dig så berättar vi mer.",
   
            alt: "bild på it",
            id: "4"
        },
        {
            title: "Infrastruktur",
            body: "För majoriteten av alla de kunder vi hittills mött är webben navet i deras digitala plattform. Tekniska plattformar som är till för att attrahera, engagera och konvertera kunderna för att vara en representation av en idé, tanke, dröm, lösning och inte minst en affär. Den blir den självklara motorn även för andra sociala kanaler och spindeln i nätet för all digital marknadsföring. Våra konsulter täcker med sin kompetens hela spektrat av de kompetenser som krävs för att leverera helhetslösningar med stöd och spetskompetens från strategi till fungerande lösning, vilket inkluderar förvaltningen av den färdiga lösningen. De kunder vi möter har behov av produktinformation, sortimentstyrning, varuförsörjning likväl som kanalstyrning mot andra återförsäljningskanaler där det blir allt vanligare med olika hubbar för olika produkter. Strategin varierar för olika kunder – där målet kan variera att allt ifrån att sälja enbart via egna kanaler, såväl som via egen kanal tillsammans med andra kanaler, och inte minst att enbart sälja via andra kanaler än de egna. De spetskompetenser som erbjuds inom Shaya Solutions spänner sig över olika tekniklösningar där e-handelsplattformar ingår, lojalitetsprogram, webb, användarupplevelse samt PIM-lösningar (Product Information Management).",

            alt: "bild på it",
            id: "5"
        },
        {
            title: "Telekom",
            body: "DevOps för Shaya Solutions konsulter innebär framför allt handlar om att få kundens organisation att arbeta tillsammans mot gemensamma mål genom att ha utgångspunkten i en gemensam grund kring hur produkter och system ska leva och utvecklas. Utmaningen är när olika delar av organisationen rycker i utvecklarteamet från olika håll – vilket leder till att repeterbara och tillförlitliga processer blir svåra att uppnå med sämre kvalité som resultat. Slutprodukten blir en lidande organisation där ”allt är IT-teamets fel”. Våra DevOps-experter är experter på just detta – komplexa organisationer i ständig utveckling och förändring.",

            alt: "bild på it",
            id: "6"
        },
    ] */
  return (
    <div className="cases-container">
     
      <div className="cases-div-wwd">
        <div className="one-case-wwd-it">
          <div className="case-flex">
            <p>Programmering</p>
          </div>
          <div className="hash">
            <span>Erfarenhet av programmeringsspråk som ofta används inom automation, såsom Python, C/C++, Java
eller specifika språk som PLC-programmering (Ladder Logic, Structured Text m.fl.).</span>
            {/* <span>#Informationssäkerhet</span>
            <span>#SecOps</span>
            <span>#DevOps</span>
            <span>#Nätverk</span>
            <span>#Databas</span>
            <span>#Service Management</span>
            <span>#Support</span> */}
          </div>
          <Link to={"/ett-omrade"}></Link>
        </div>

        <div className="one-case-wwd-it">
          <div className="case-flex">
            <p>PLC</p>
          </div>
          <div className="hash">
            <span>Erfarenhet av att designa, programmera och felsöka PLC-baserade system.</span>
            {/* <span>#AI & Machine Learning </span>
            <span>#Big Data</span>
            <span>#Datavisualisering</span> */}
          </div>
          <Link to={"/ett-omrade"}></Link>
        </div>

        <div className="one-case-wwd-it">
          <div className="case-flex">
            <p>SCADA/HMI</p>
          </div>
          <div className="hash">
            <span>Design och implementation av gränssnitt för att övervaka och styra automatiserade processer.</span>
            {/* <span>#Testledning</span>
            <span>#Teknisk testning </span>
            <span>#Testkoordinering</span> */}
          </div>
          <Link to={"/ett-omrade"}></Link>
        </div>

        <div className="one-case-wwd-it">
          <div className="case-flex">
            <p>Instrumentering</p>
          </div>
          <div className="hash">
            <span>Kunskap om olika sensorer, aktuatorer och mätinstrument som används för att samla in data och
utföra handlingar inom ett automatiserat system.</span>
            {/* <span>#SCADA</span>
            <span>#HMI</span>
            <span>#Nätverk</span>
            <span>#Kommunikation</span>
            <span>#Säkerhet</span>
            <span>#Siemens</span>
            <span>#PCS7</span>
            <span>#ABB</span>
            <span>#800xA</span> */}
          </div>
          <Link to={"/ett-omrade"}></Link>
        </div>

        <div className="one-case-wwd-it">
          <div className="case-flex">
            <p>Kommunikationsteknologi</p>
          </div>
          <div className="hash">
            <span>Förståelse för nätverk och kommunikationsteknologier som används inom industriell automation,
såsom Ethernet/IP, Modbus, PROFINET och andra industriella protokoll.</span>
            {/* <span>#Oracle</span>
            <span>#Salesforce</span>
            <span>#Microsoft</span>
            <span>#Calypso</span> */}
            
          </div>
          <Link to={"/ett-omrade"}></Link>
        </div>

        <div className="one-case-wwd-it">
          <div className="case-flex">
            <p>Styr- och reglerteknik</p>
          </div>
          <div className="hash">
            <span>Kunskap om hur man designar och implementerar styr- och reglersystem för att uppnå önskade
systembeteenden.</span>
            {/* <span>#Infrastruktur</span>
            <span>#Data</span>
            <span>#Moln</span>
            <span>#Applikation</span> */}
          </div>
          <Link to={"/ett-omrade"}></Link>
        </div>

        <div className="one-case-wwd-it">
          <div className="case-flex">
            <p>Drivsystem</p>
            <div className="hash">
              <span>Kunskap om olika typer av motorer, servosystem och deras styrmetoder.</span>
              {/* <span>#Backend</span>
              <span>#Frontend</span>
              <span>#Tech lead</span>
              <span>#Produktägarskap</span>
              <span>#E-handel</span> */}
            </div>
            <Link to={"/ett-omrade"}></Link>
          </div>

        </div>
        <div className="one-case-wwd-it">
          <div className="case-flex">
            <p>Robotik</p>
            <div className="hash">
              <span>Erfarenhet av att designa, programmera och underhålla industrirobotar.</span>
              {/* <span>#SAFe</span>
              <span>#ITIL</span>
              <span>#PM3</span>
              <span>#Agile Coaching</span>
              <span>#Teknisk Projektledning</span>
              <span>#Kravhantering</span>
              <span>#Beställarstöd</span> */}
            </div>
            <Link to={"/ett-omrade"}></Link>
          </div>
          
        </div>
        <div className="one-case-wwd-it">
          <div className="case-flex">
            <p>Säkerhet</p>
            <div className="hash">
              <span>Förståelse för de specifika säkerhetskraven och -standarderna inom automationsindustrin, såsom
maskinsäkerhet och funktionell säkerhet.</span>
              {/* <span>#Management konsulting</span>
              <span>#Omvärldsanalys</span>
              <span>#Förändringsledning</span>
              <span>#PMO Utveckling </span> */}
            </div>
            <Link to={"/ett-omrade"}></Link>
          </div>

        </div>
        <div className="one-case-wwd-it">
          <div className="case-flex">
            <p>Systemintegration</p>
            <div className="hash">
              <span>Förmåga att integrera olika systemkomponenter och teknologier till ett fungerande, effektivt och
säkert helhetssystem.</span>
              {/* <span>#Management konsulting</span>
              <span>#Omvärldsanalys</span>
              <span>#Förändringsledning</span>
              <span>#PMO Utveckling </span> */}
            </div>
            <Link to={"/ett-omrade"}></Link>
          </div>

        </div>
        <div className="one-case-wwd-it">
          <div className="case-flex">
            <p>Processförståelse</p>
            <div className="hash">
              <span>Viktigt med en djupare förståelse för de specifika processerna som ska automatiseras, vare sig det
handlar om kraftproduktion, tillverkning, förpackning, logistik eller något annat.</span>
              {/* <span>#Management konsulting</span>
              <span>#Omvärldsanalys</span>
              <span>#Förändringsledning</span>
              <span>#PMO Utveckling </span> */}
            </div>
            <Link to={"/ett-omrade"}></Link>
          </div>

        </div>
      </div>
      <Link to={"/kontakt"} className="btn-blue margin-btn">Kontakta oss</Link>      
    </div>
  );
};

export default WhatWeDoItComponent;
