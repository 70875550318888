import "./Handbok.css"

import handbokImg from "../../img/hanbok/manual.png"

import { Link } from "react-router-dom";
const Handbok = () => {
    return (
        <main>
            <section className="handbok-hero">
                <div className="icon-wrapper">
                    <img className="handbok-icon" src={handbokImg} alt="icon på en handbok" />
                </div>
            </section>
            <section className="handbok-menu">
                <div className="handbook-wrapper">
                <h1>Välkommnen till Shaya Solutions</h1>
                <p>Please select in which language you would like to read our Handbook:</p>
                <article className="handbok-lang">
                    <Link to={"/handbok-svenska"}>🇸🇪 Handboken på svenska</Link>
                </article>
                <article className="handbok-lang">
                    <Link to={"/handbok-svenska"}>🇬🇧 Handbook in english</Link>
                </article>
                </div>
            </section>
        </main>
    );
}

export default Handbok;