import shayadi from "../../img/shaya_di.png"
import vlogga from "../../img/vlogga.png"
import konsult_sommar from "../../img/konsult_sommar.jpeg"
import di_white from "../../img/di_white.png"

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer";
import { useEffect } from "react";
import "./NewNews1.css";
const NewNews2 = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });

  }, []);
  return (
    <><Header />
      <div className="container-newNews">
        <div className="newNews-wrapper">
          <span>#Nyhet</span>
          <h1>Shaya Solutions blir VAERNA (OBS! Organisationsnummer oförändrat)</h1>
          <img src={vlogga} alt="" />
          <p>Shaya Solutions AB, ett framgångsrikt IT- och ingenjörskonsultföretag med fokus på Management, Teknik och IT, tillkännager sin plan om ett namnbyte till <b>VAERNA OT IT AB</b> från Shaya Solutions AB.
            <br /><br />
            Namnbytet markerar en betydande milstolpe i bolagets utveckling och speglar dess strategiska inriktning mot att bli en ledande leverantör av specialiserade OT/IT-lösningar med fokus på automation och styrsystem för den moderna industriella miljön.
Vi är stolta över att presentera vår nya identitet som <b>VAERNA OT IT AB</b>, säger avgående VD för Shaya Solutions och lämnar över med varm hand till interna teamet bl.a. till Anders Åsberg och nya framgena kollegor som ansluter efter sommaren.
<br /><br />
Detta namnbyte representerar en naturlig utveckling för bolaget, då bolaget fokuserar på att erbjuda spjutspetskompetens inom automation och styrsystem med fokus på OT/IT-säkerhet och industriell digitalisering. VAERNA symboliserar vår styrka och stabilitet, medan OT IT belyser vår expertis inom detta komplexa och framväxande område.
Bolaget har under de senaste åren etablerat sig som en pålitlig partner för företag inom en rad olika branscher, inklusive tillverkning, energi, industri, kraftproduktion och elnät. Företagets team av erfarna ingenjörer och konsulter har en djupgående förståelse för de unika utmaningar som industriföretag står inför när det gäller att skydda sina OT-system och optimera sin digitala verksamhet.
Genom namnbytet och den fortsatta specialiseringen mot OT-säkerhet och industriell digitalisering befäster VAERNA OT IT AB sin position som en ledande aktör inom den svenska IT-branschen. Företaget är fast beslutna att hjälpa sina kunder att navigera i den allt mer komplexa digitala världen och säkerställa en säker, effektiv och tillförlitlig drift. Detta är också ett naturligt led då bolagets styrelse förändras.
<br /><br />
Hemsidedomän och emaildomäner kommer att övergå successivt där namn och dylikt redan säkrats hos PRV där övergången sker successivt i form av namnbyte.
<br /><br />
<b>Om VAERNA OT IT AB</b>
<br /><br />
VAERNA OT IT AB är ett svenskt, nischat konsult- och produktbolag med fokus på automation och styrsystem med stark orientering till OT/IT-säkerhet. Industriell digitalisering där automation och styrsystem står i fokus. VAERNA OT IT AB har ett team av erfarna ingenjörer och konsulter med gedigen expertis inom OT/IT-miljöer samt automation och styrsystem. Detta kombineras med både anställda konsulter och samarbetspartners. Företaget samarbetar med kunder i en rad olika branscher, inklusive kraftproduktion, energi och elnät.
<br /><br />Kontakt: 
 <b> Anders Åsberg VAERNA OT IT AB, support@shayasolutions.se.</b>
<br />
<br /> Datum: Våren 2024.
</p>

        </div>
      </div>
      <Footer />
    </>
  );
}

export default NewNews2;