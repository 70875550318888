import "../Handbok.css"
import Footer from "../../../components/Footer";
import Header from "../../../components/Header/Header";
import { Link } from "react-router-dom";

import { useEffect } from "react";

const HandbokVarderingar = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 });
    }, []);
    return (
        <>
                <Header/>
                <div className="handbook-con-wrapper">
            <main>
                <section className="handbok-menu">
                    <div className="handbook-wrapper">
                        <h1 className="h1-separat">🌵 Föräldrarledighet</h1>
                        <article className="handbok-article">
                            <p>👋 Föräldraledighet på Shaya Solutions omfattar olika typer av långvarig föräldraledighet, inklusive mammaledighet,
                                 pappaledighet och adoptionsledighet. Vi erbjuder förmånliga villkor för föräldraledighet från och med ditt anställningsdatum
                                  på Shaya Solutions. Vi värdesätter och stödjer en balans mellan arbete och familjeliv, och föräldraledighet är
                                 en viktig del av detta stöd. Vi uppmuntrar alla föräldrar att dra nytta av denna möjlighet för att ta hand om sina barn och
                                  familjer på bästa sätt. 🙂</p>
                        </article>
                    </div>
                </section>
            </main>
            </div>
            <Footer />
        </>
    );
}

export default HandbokVarderingar;