 import Header from "../../components/Header/Header";
import Footer from "../../components/Footer";
import "./News.css"
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ordFranVd from "../../img/joseph.jpg"
import { Link } from "react-router-dom";

const EachNews = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });

  }, []);

  const Location = useLocation();
  let oneNews = Location.state;

  return (
    <>
      <Header />
      <div className="container-each-news">
        <main className="Each-news-main">
          <section className="one-news-section">
            {

              <article className="one-news-article">
                { oneNews.img && <img src={oneNews.img} alt={oneNews.alt} />}
                { oneNews.video && <video src={oneNews.video} controls="controls" />}
                <h2>{oneNews.title}</h2>
                {
                  oneNews.id == 37 &&
                  <div>
                    <p>Vi är ett fantastiskt team på Shaya Solutoins som utvecklats de senaste åren. Både med våra kunder och inom bolaget. Tveka inte att höra av dig om du är nyfiken vill samverka med oss, oavsett avtalsform. <br /><br /> Shaya Solutions vd och grundare Joseph Öberg Shaya delar med sig av sina reflektioner gällande vårt erbjudande gentemot marknaden;

                    ”Innovativa och tekniska (digitala) lösningar har som oftast en accelererande effekt på en förbättring/effektivisering, de är själva inte ändamålet. Det är kundnyttan som är det kritiska. Men det här är inga nyheter. Det svåra är att omsätta och realisera detta i verkligheten för komma framåt med marknaden och berörda parter.

                    Tack till det fantastiska teamet på SHAYA SOLUTIONS AB som får bolaget att år efter år blomstra och utvecklas, tillsammans med våra kunder, anställda och alla berörda partners. Vi är långt ifrån perfekta och långt ifrån att ha kommit upp i den nivån vi vill vara. Men vi är på god väg.”.</p>
                  </div>
                }
                {
                  oneNews.id == 1 &&
                  <div>
                    <p>{oneNews.body}</p>
                    <a href={oneNews.link} target="_blank" rel="noopener noreferrer">Läs Shaya Solutions presentation av årsredovisningen för 2021.</a>
                  </div>
                }
                      {
                  oneNews.id == 38 &&
                  <div>
                    <p>I ett led att ytterligare stärka och främja medarbetarnas och konsulternas psykiska hälsa, har Shaya Solutions valt att skriva avtal med Mindler med start 1:a februari 2023. Mindler som är den största digitala psykologmottagningen i Sverige. Mindler erbjuder behandling som består av videosamtal med psykolog och övningar i deras app. Samarbetet innebär att Shaya Solutions kommer att erbjuda sina medarbetare och partners möjligheten att få tillgång till psykologsamtal genom Mindler. Detta initiativ är en del av Shaya Solutions helhetstänk för att främja medarbetarnas och konsulternas såväl som underkonsulternas välbefinnande. Teamet inom Shaya Solutions anser att psykisk hälsa är lika viktig som fysisk hälsa, särskilt  i en tid där många konsulter jobbar på distans alternativt i kundernas organisationer på heltid. <br /> <br /> ”Shaya Solutions har genom samarbetet med Mindler tagit ett viktigt steg för att hjälpa och stötta sina medarbetare och partners att förebygga, hantera stress alternativt psykisk ohälsa. Samarbetet ger medarbetarna möjligheten att få tillgång till kvalificerad psykologhjälp, coacher och samtalsterapeuter från Mindler, vilket är en av de mest välrenommerade digitala psykologmottagningarna i Sverige. Det gör mig stolt att vi tagit detta steg”, säger Joseph Öberg Shaya, VD, grundare och ägare av Shaya Solutions. Ovan är endast en del av många insatser och faktorer som arbetsgivare måste beakta i syfte att främja god arbetsmiljö för medarbetare och partners.</p>
                  </div>
                }
   {
                  oneNews.id == 100 &&
                  <div>
                    <p>{oneNews.titel2}</p>
                    <p>{oneNews.body}</p>
                    <h3>{oneNews.body2}</h3>
                  </div>
                }
                {
                  oneNews.id == 2 &&
                  <div>
                    <p>{oneNews.body}</p>
                  </div>
                }

                {
                  oneNews.id == 3 &&
                  <div>
                    <p>{oneNews.body}</p>
                  </div>
                }

                {
                  oneNews.id == 4 &&
                  <div>
                    <p>{oneNews.body}</p>
                  </div>
                }

                {
                  oneNews.id == 5 &&
                  <div>
                    <p>{oneNews.body}</p>
                    <ul>
                      {
                        oneNews.punkter.map((e, i) => {
                          return (
                            <li key={i}>{e}</li>
                          )

                        })
                      }
                    </ul>
                    <p>{oneNews.body2}</p>
                  </div>
                }
                {
                  oneNews.id == 6 &&
                  <div>
                    <p>{oneNews.body}</p>
                  </div>
                }
                {
                  oneNews.id == 7 &&
                  <div>
                    <p>{oneNews.body}</p>
                  </div>
                }
                {
                  oneNews.id == 8 &&
                  <div>
                    <p>{oneNews.body}</p>
                  </div>
                }

                {
                  oneNews.id == 11 &&
                  <div>
                    <p>{oneNews.body}</p>
                  </div>
                }

                {
                  oneNews.id == 13 &&
                  <div>
                    <p>{oneNews.body}</p>
                  </div>
                }
                {
                  oneNews.id == 14 &&
                  <div>
                    <p>{oneNews.body}</p>
                  </div>
                }
                {
                  oneNews.id == 15 &&
                  <div>
                    <p>{oneNews.body}</p>
                    <a href={oneNews.link} target="_blank" rel="noopener noreferrer">Läs Shaya Solutions presentation av årsredovisningen för 2020.</a>
                  </div>
                }
                {
                  oneNews.id == 16 &&
                  <div>
                    <p>{oneNews.body}</p>
                    <a href="https://open.spotify.com/episode/5tcBG7selBvwY6XmXmd40j?si=9RtyAKoHS5iVTYH-9LxUqg&dl_branch=1&nd=1" target="_blank" rel="noopener noreferrer">Länk till podden finns här och där poddar finns.</a>
                    <p>Trevlig sommar om inte annat!</p>
                  </div>
                }
                {
                  oneNews.id == 17 &&
                  <div>
                    <p>🧘‍♂️🤩🌟 Favorit i repris! Nu bjuder vi in till vårt andra online event onsdag den 12:e
                      maj 🔔17.45 med fantastiska Swiftr.se och Powerful Grace Club
                      <a href="https://www.powerfulgraceclub.com/" target="_blank" rel="noopener noreferrer"> powerfulgraceclub.com</a> där ett "Yoga for stress relief"
                      pass kommer att köras. Detta då eventet sist var väldigt uppskattat
                      av medverkande och något vi gärna vill erbjuda vårt nätverk igen.
                      För att delta kostnadsfritt behöver du skicka din intresseanmälan
                      via denna annons. Du kommer då att få en bekräftelse på din anmälan.
                      Länken till passet skickas innan eventets start och deltagandet är
                      gratis. Detta i ett led då hälsa och välmående aldrig har varit
                      viktigare som i dessa tider. Därför har SHAYA SOLUTIONS AB
                      inlett en satsning på återkommande events med fokus på online
                      träning tillsammans med fantastiska Swiftr <a href="http://swiftr.se/" target="_blank" rel="noopener noreferrer">swiftr.se</a> genom
                      ett ömsesidigt partnerskap. <a href="https://lnkd.in/etiuxiw" target="_blank" rel="noopener noreferrer">Se hela nyheten här</a> </p>
                    <h3>{oneNews.titel2}</h3>
                    <ul>
                      {
                        oneNews.punkter && oneNews.punkter.map((e, i) => {
                          return (
                            <li key={i}>{e}</li>

                          )
                        })
                      }
                    </ul>
                    <p>{oneNews.body2}</p>
                    <a href={oneNews.link} target="_blank" rel="noopener noreferrer" >Länk till anmälan</a>

                  </div>
                }
                {
                  oneNews.id == 18 &&
                  <div>
                    <p>{oneNews.body}</p>
                  </div>
                }
                {
                  oneNews.id == 19 &&
                  <div>
                    <p>🧘‍♂️🤩🌟 Favorit i repris! Nu bjuder vi in till vårt andra online event onsdag den 17:e mars
                      🔔17.30 med fantastiska Swiftr.se och <a href="https://www.powerfulgraceclub.com/" target="_blank" rel="noopener noreferrer">Powerful Grace Club </a>
                      där ett "Yoga for stress relief" pass kommer att köras. För att delta kostnadsfritt behöver du
                      skicka din intresseanmälan via denna annons. Du kommer då att få en bekräftelse på din anmälan.
                      Länken till passet skickas innan eventets start och deltagandet är gratis.
                      Detta i ett led då hälsa och välmående aldrig har varit viktigare som i dessa tider.
                      Därför har SHAYA SOLUTIONS AB inlett en satsning på återkommande events med fokus på
                      online träning tillsammans med fantastiska <a href="http://swiftr.se/" target="_blank" rel="noopener noreferrer">Swiftr</a> vgenom ett ömsesidigt
                      partnerskap. <a href="https://lnkd.in/etiuxiw" target="_blank" rel="noopener noreferrer">Se hela nyheten här.</a></p>
                    <h3>{oneNews.titel2}</h3>

                    <ul>
                      {
                        oneNews.punkter && oneNews.punkter.map((e, i) => {
                          return (
                            <li key={i}>{e}</li>
                          )
                        })
                      }
                    </ul>
                    <p>{oneNews.body2}</p>
                  </div>
                }

                {
                  oneNews.id == 20 &&
                  <div>
                    <p>{oneNews.body}</p>
                    <b>{oneNews.titel2}</b>
                    <p>{oneNews.body2}</p>
                  </div>
                }
                {
                  oneNews.id == 21 &&
                  <div>
                    <p>🧘‍♂️🤩🌟 Äntligen går första #online #eventet av stapeln #tisdag 9:e #februari
                      🔔17.30 med fantastiska Swiftr och Powerful Grace Club där ett så kallat
                      "Total Body HIIT"-pass kommer att köras. För att delta kostnadsfritt behöver
                      du anmäla dig på följande
                      <a href="https://career.shayasolutions.com/jobs/1063762-gratis-online-traning-med-swiftr-och-powerful-grace-9-2-kl-17-30-for-shaya-solutions-natverk" target="_blank" rel="noopener noreferrer"> länk</a>.
                      Du kommer då att få en bekräftelse om att du fått plats samt att du innan eventets start får
                      en länk. Eventet är gratis för de som finns inom SHAYA SOLUTIONS AB nätverket. Detta i ett led
                      då #hälsa och #välmående aldrig har varit viktigare som i dessa tider. Därför inleder SHAYA SOLUTIONS AB
                      en satsning på återkommande #events med fokus på #ONLINE #TRÄNING tillsammans med #fantastiska Swiftr (swiftr.se)
                      genom ett ömsesidigt partnerskap. Se hela nyheten <a href="https://www.shayasolutions.com/News/news-shaya-solutions-swiftr.html" target="_blank" rel="noopener noreferrer">Se hela nyheten här.</a></p>
                  </div>
                }
                {
                  oneNews.id == 22 &&
                  <div>
                    <p>{oneNews.body}</p>
                  </div>
                }
                {
                  oneNews.id == 23 &&
                  <div>
                    <p>{oneNews.body}</p>
                    <p>{oneNews.body2}</p>
                  </div>
                }

                {
                  oneNews.id == 27 &&
                  <div>
                    <p>Vi är ödmjuka, glada och stolta inför den 4e platsen som SHAYA SOLUTIONS AB tilldelats i
                      "Konsultrapporten", framtagen av Brainville - the leading marketplace for consulting assignments.
                      "Detta är ytterligare ett kvitto på att teamet gör ett fantastiskt jobb där vi har tydliga och enkla
                      processer som gör att vi kan samverka, inom bolaget såväl som utanför bolaget, på ett framgångsrikt
                      sätt. En eloge till hela teamet",
                      säger Joseph Öberg Shaya VD, grundare och ägare av SHAYA SOLUTIONS AB.
                      Du kan ladda ner rapporten på <a href="https://lnkd.in/eD6krGX" target="_blank" rel="noopener noreferrer">Brainvilles hemsida</a></p>
                  </div>
                }
                {
                  oneNews.id == 28 &&
                  <div>
                    <p>Maria är en passionerad och engagerad ingenjör som brinner för innovation med användaren i fokus och med teknik som hjälpmedel. Hon är grundaren av Teknikkvinnor, Sveriges största professionella nätverk för kvinnor och icke-binärer inom teknik (+29 000 medlemmar). Hon kliver in som extern rådgivare för Shaya Solutions där samarbetet på förhand är bestämt till 6 månader.</p>
                    <p>Grundaren och VD:n för Shaya Solutions, Joseph Öberg Shaya, säger; "Teamet på Shaya Solutions är imponerade av Marias driv och engagemang i flertalet frågor som ligger bolaget väldigt nära hjärtat. Att ta in Maria i rollen som extern rådgivare är i syfte att stärka vårt erbjudande på marknaden än mer för att ta nästa kliv i vår utveckling känns därför naturlig och självklar".</p>
                    <p>Maria kommenterar om varför hon valde att samarbeta med just Shaya Solutions; ”Jag har lärt känna Joseph som en intelligent, genuin och varm person med hjärta för både inkludering och entreprenörskap – ämnen jag själv brinner för och vill arbeta mer med. Därför känns det väldigt roligt att få lära känna gänget bakom Shaya Solutions, och bidra med mina erfarenheter i syfte att stärka företagets position ytterligare”.</p>
                  </div>
                }
                {
                  oneNews.id == 29 &&
                  <div>
                    <p>Lotta är i grunden civilingenjör som de senaste 25 åren arbetat som chef inom näringslivet. Hon har haft ledande positioner i tillväxtbolag framförallt inom IT- och telekombranschen. Med sitt ledarskap har hon skapat lönsamma och välmående organisationer i stark tillväxt. Hon kliver in som extern rådgivare för Shaya Solutions där samarbetet på förhand är bestämt till 6 månader.</p>
                    <p>Joseph Öberg Shaya säger; "Teamet på Shaya Solutions är väldigt glada och stolta att vi kan knyta till oss Lotta i rollen som extern rådgivare i syfte att stärka vår organisation än mer strategiskt på marknaden där hon kommer att vara ett bollplank och mentor."</p>
                    <p>Lotta om varför hon valde att samarbeta med Shaya Solutions; "Shaya Solutions är en frisk uppstickare i branschen som med sin oräddhet och kreativitet kommer att nå långt. Jag ser fram emot att bidra till deras spännande utvecklingsresa".</p>
                  </div>
                }

                {
                  oneNews.id == 30 &&
                  <div>
                    <p>{oneNews.body}</p>
                    <h3>{oneNews.titel2}</h3>
                    <div>
                      <b>Vi hjälper och har hjälpt våra kunder med konsultuppdrag såsom:</b>
                      <ul>
                        <li>IT-säkerhet</li>
                        <li>Programledning för multidisciplinära industri- och energiprojekt</li>
                        <li>Testledning inom energisektorn</li>
                        <li>Arkitektur inom Bigdata och AI-segementet</li>
                        <li>Projektledare för program inom elkraft och automation</li>
                        <li>Huvuduppdragsledning inom Samhällsbyggnad i ett multidisciplinärt kabelprojekt samt ett</li>
                        <li>Interim chefsuppdrag inom kärnkraftssektorn.</li>
                      </ul>
                    </div>
                    <div>
                      <b>Vi genomförde 11 seniora rekryteringsuppdrag bl.a. inom</b>
                      <ul>
                        <li>Backend till fordonsindustrin</li>
                        <li>Riskmanagement (Samhällsbyggnad)</li>
                        <li>Projektledning (Samhällsbyggnad) samt Site manager (vindkraftssektorn).</li>
                      </ul>
                    </div>
                    <div>
                      <b>Vi tackar ödmjukt alla ni som hejjat fram oss, delat, lajkat, tipsat och stöttat oss! Vårt motto är och kommer att förbli:</b>
                      <ul>
                        <li>Ödmjukhet</li>
                        <li>Härdighet</li>
                        <li>Flexibilitet</li>
                        <h4>Vi kommer att fortsätta vara outtröttliga under 2020 med fokus på att leverera de allra bästa och roligaste konsultuppdragen.</h4>
                      </ul>
                    </div>
                  </div>
                }


                {
                  oneNews.id == 31 &&
                  <div>
                    <p>{oneNews.body}</p>
                    <h3>{oneNews.titel2}</h3>
                    <p>{oneNews.body2}</p>
                    <h3>{oneNews.titel3}</h3>
                    <p>{oneNews.body3}</p>
                    <p>Stort lycka till Joseph! Spännande!</p>
                    <b>Tack och bock!</b>
                  </div>
                }
                {
                  oneNews.id == 32 &&
                  <div>
                    <p>{oneNews.body}</p>
                  </div>
                }
                {
                  oneNews.id == 33 &&
                  <div>
                    <p>{oneNews.body}</p>
                    <b><a href="https://teknat.uu.se/student/stod-och-service/studie-karriarvagledning/studie--och-karriarpodden/" target="_blank" rel="noopener noreferrer">Du hittar podden här</a></b>
                  </div>
                }

                {
                  oneNews.id == 34 &&
                  <div>
                    <p>Källa foto: https://lnkd.in/d8hFsJ9</p>
                    <b>Almedalen 2019 är igång! Nedan följer ett axplock av några av alla seminarier som finns att ta del av och som vi rekommenderar. Vad skulle du rekommendera? Kommentera gärna och tipsa!</b>
                    <div>
                      <p>Uniper Sverige / Johan Svenningsson: Ett miljardprogram för elen – minst 100 TWh ny produktion till 2045</p>
                      <a href="https://lnkd.in/dixwGDB" target="_blank" rel="noopener noreferrer">LÄNK</a>
                    </div>
                    <div>
                      <p>SEB: Från guldklockor till #gig-ekonomi – hur viktig är soloprenören för framtidens näringsliv?</p>
                      <a href="https://lnkd.in/dAzSbce" target="_blank" rel="noopener noreferrer">LÄNK</a>
                    </div>
                    <div>
                      <p>Dagens industri / Danske Bank: Från fast anställd till gigster – hur säkerställer vi trygghet på den nya arbetsmarknaden?</p>
                      <a href="https://lnkd.in/dvHbhZX" target="_blank" rel="noopener noreferrer">LÄNK</a>
                    </div>
                    <div>
                      <p>Accenture / Energimarknaden: Vilka är energibranschens viktigaste frågor?</p>
                      <a href="https://lnkd.in/dnypD55" target="_blank" rel="noopener noreferrer">LÄNK</a>
                    </div>
                    <div>
                      <p>Vattenfall: Klimatet kräver handling – är företagen redo att accelerera?</p>
                      <a href="https://lnkd.in/dPZEP65" target="_blank" rel="noopener noreferrer">LÄNK</a>
                    </div>
                    <div>
                      <p>Rejlers / Dagens industri: Hur tacklas effekt- och kapacitetsbristutmaningen när allt fler funktioner kräver el 24/7?</p>
                      <a href="https://lnkd.in/dd3TY6s" target="_blank" rel="noopener noreferrer">LÄNK</a>
                    </div>
                  </div>
                }
                {
                  oneNews.id == 35 &&
                  <div>
                    <p>Du hittar poddavsnittet där poddar finns alternativt <a href="https://www.youtube.com/watch?v=9gPfR-oMvSc" target="_blank" rel="noopener noreferrer">här</a></p>
                  </div>
                }
                {
                  oneNews.id == 36 &&
                  <div>
                    <p>Avsnittet finns att lyssna på: <a href="https://podcasts.apple.com/se/podcast/mitt-liv-som-ingenj%C3%B6r/id1371564764?l=en&mt=2" target="_blank" rel="noopener noreferrer">iTunes/Podcaster</a> eller <a href="https://open.spotify.com/episode/2F4TSxhdNnqFy3oW90MnQI?si=dGvDkEN7RMWB0roY6b6VQw" target="_blank" rel="noopener noreferrer">Spotify</a>.</p>
                  </div>
                }
                   {
                  oneNews.id == 40 &&
                  <div>
                    <p>{oneNews.body}</p>
                    <img src={oneNews.img2} alt="" />
                    <p>{oneNews.body2}</p>
                    <img src={oneNews.img3} alt="" />
                    <p>{oneNews.body3}</p>
                    <img src={oneNews.img4} className="di-width-100" alt="" />
                  </div>
                },
                  {
                  oneNews.id == 41 &&
                  <div>
                    <p>{oneNews.body}</p>
                    <img src={oneNews.img2} alt="" />
                    <p>{oneNews.body2}</p>
                    <img src={oneNews.img3} alt="" />
                    <p>{oneNews.body3}</p>
                    <img src={oneNews.img4} className="di-width-100" alt="" />
                  </div>
                }
              </article>
            }
          </section>
        </main>
      </div>
      <Footer />
    </>
  );
}

export default EachNews;