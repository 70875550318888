import it from "../../img/software-application.png";
import management from "../../img/management.png";
import tech from "../../img/project-management.png"
import "./WhatWeDo.css"

import { useEffect } from "react"
import { Link } from "react-router-dom"
const WhatWeDo = () => {

    return ( 
        <div className="what-we-do">
            <div className="wwd-wrap">
        <div className="tech">
            {/* <img src={it} alt="tech-logo" /> */}
            <h3>AUTOMATION</h3>
            <p>PLC, SCADA/HMI, Instrumentering, Kommunikationsteknologi, Drivsystem osv..
</p>
            <Link className="btn-blue-wwd" to={"/AUTOMATION"}>
                läs mer
            </Link>
        </div>
        <div className="it">
            {/* <img src={management} alt="it-logo" /> */}
            <h3>NÄTVERKSTEKNIK</h3>
            <p>Protokollförståelse, Nätverksutrustning, Kabelinfrastruktur, IPv6, Katastrofåterställning osv..
</p>
            <Link className="btn-blue-wwd" to={"/NATVERKSTEKNIK"}>
                läs mer
            </Link>
        </div>
        </div>
        <div className="wwd-wrap">
        <div className="management">
            {/* <img src={tech} alt="management-logo" /> */}
            <h3>OT ARKITEKTUR OCH INTEGRATION</h3>
            <p>Industriella Kontrollsystem (ICS), Nätverksarkitektur, Säkerhet, Hårdvara osv...
</p>
            <Link className="btn-blue-wwd" to={"/ot"}>
                läs mer
            </Link>
        </div>

        <div className="management">
            {/* <img src={tech} alt="management-logo" /> */}
            <h3>IT ARKITEKTUR OCH INTEGRATION</h3>
            <p>Systemintegration, API-design och -hantering, Meddelandeprotokoll, Middleware- verktyg osv..</p>
            <Link className="btn-blue-wwd" to={"/it"}>
                läs mer
            </Link>
        </div>
        </div>
    </div>
     );
}
 
export default WhatWeDo;