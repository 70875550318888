import "../Handbok.css"
import Footer from "../../../components/Footer";
import Header from "../../../components/Header/Header";
import { Link } from "react-router-dom";

import { useEffect } from "react";

const HandbokVarderingar = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 });
      }, []);
    return ( 
        <>
                <Header/>
                <div className="handbook-con-wrapper">
        <main>
        <section className="handbok-menu">
        <div className="handbook-wrapper">
            <h1 className="h1-separat">🌵 Insiderpolicy</h1>
            <article className="handbok-article">
                    <p>👋 Välkommen till Shaya Solutions publika handbok! Det
                        här avsnittet i vår handbok är publikt tillgängligt för
                        att hjälpa alla våra anställda och kandidater som undrar
                        hur det är att arbeta på Shaya Solutions. Vi lägger kontinuerligt
                        till nytt innehåll, men om det är något du inte kan hitta
                        här, tveka inte att kontakta joseph@shayasolutions.se för att se om
                        vi kan lägga till ytterligare information. Hoppas du gillar
                        att läsa
                        detta lika mycket som vi gillar att vara transparenta om det! 🙂</p>
                </article>
                <article className="handbok-article">
                    <p>🌐 Shaya Solutions är ett globalt företag. Våra konsulter har
                        friheten att själva bestämma över sin tid
                        och arbetsplats. Vi är ett modernt konsultbolag med
                        ett konsulter-först-förhållningssätt. Allt vi gjort,
                        har vi gjort för att göra det mer schysst för dig som konsult.
                        Lär dig mer genom att läsa på alla de olika avsnitten i denna
                        offentliga handbok.</p>
                </article>
            </div>
        </section>
    </main>
    </div>
   <Footer/>
    </>
     );
}
 
export default HandbokVarderingar;