import { Link } from "react-router-dom";
import { useState, useEffect, useCallback } from "react"; 
import React from "react";
import log from "../../img/vae-svart.png"
import di from "../../img/di_white.png"
import "./Header.css"

const Header = () => {

    const [navBar, setNavBar] = useState(false);
    const [hamburgerOpen, setHamburgerOpen] = useState(false)
    const handleClick = () => {
        setHamburgerOpen(!hamburgerOpen)
    }
    const hamburgerNav = document.querySelector(".hamburgerNav");

  
        const scroll = () =>{
            const body = document.querySelector("body");
            hamburgerOpen ? body.style.overflow = "hidden" : body.style.overflow = "auto";
        }
scroll()
    const changeNav = () => {
      
        if (window.scrollY >= 140) {
            setNavBar(true)
        } else {
            setNavBar(false)
        }
    }
    // scroll direction hook
    function useScrollDirection() {
        const [scrollDirection, setScrollDirection] = useState(null);

        useEffect(() => {
            let lastScrollY = window.pageYOffset;

            const updateScrollDirection = () => {
                const scrollY = window.pageYOffset;
                const direction = scrollY > lastScrollY ? "down" : "up";
                if (direction !== scrollDirection && (scrollY - lastScrollY > 5 || scrollY - lastScrollY < -5)) {
                    setScrollDirection(direction);
                }
                lastScrollY = scrollY > 0 ? scrollY : 0;
            };
            window.addEventListener("scroll", updateScrollDirection); // add event listener
            return () => {
                window.removeEventListener("scroll", updateScrollDirection); // clean up
            }
        }, [scrollDirection]);

        return scrollDirection;
    };
    const scrollDirection = useScrollDirection();

    // const changeNav = () => {
    //     if (window.scrollY >= 400) {
    //         setNavBar(true)
    //     } else {
    //         setNavBar(false)
    //     }
    // }


    // useEffect(() => {
    //     window.addEventListener("scroll", changeNav)
    //     return () => {
    //         window.removeEventListener("scroll", changeNav)
    //     }
    // }, [])

    return (
        <header className={scrollDirection === "down" ? "header" : "headerFixed"}>
            <div className={hamburgerOpen ? "hamburgerNavActive" : "hamburgerNav"}>
                <div className="hamburgerNavWrapper">

                    {/*HEADER FOR HAMBURGER  */}
                    {/* <div className="nav-top">
                    <Link to={'/om-oss'} className="link-header">Lediga uppdrag</Link>  
                    <Link to={'/om-oss'} className="link-header">Anlita oss</Link>

                    </div> */}
                    <div className="nav-section-1">
                        <h2>VAERNA</h2>
                        <Link to={'/om-oss'} className="link-header">Om oss</Link>
                        {/* <Link to={"/guidelines"} className="link-header">Guidelines</Link> */}
                        <Link to={"/nyheter"} className="link-header">Nyheter</Link>
                        {/* <Link to={'/konsulter-och-kompetenser'} className="link-header">Konsulter och kompetenser</Link> */}
                         {/* <Link to={'/nadia-initiativet'} className="link-header">Nadia initiativet</Link> */}
                        {/*<Link to={'/faq'} className="link-header">FAQ</Link>*/}
                    </div>
                    <div className="nav-section-3">
                        <h2>VÅR VERKSAMHET</h2>
                        <Link to={'/konsult'} className="link-header">Anställd konsult</Link>
                        <Link to={'/kund'} className="link-header">Kund</Link>
                        {/*<Link to={'/partner'} className="link-header">Partner</Link>*/}
                    </div>
                    <div className="nav-section-2">
                        <h2>REFERENSUPPDRAG</h2>
                        <Link to={'/it-cases'}className="link-header">IT</Link>
                        <Link to={'/management-cases'} className="link-header">Management</Link>
                        <Link to={'/teknik-cases'} className="link-header">Ingenjörstjänster</Link>
                    </div>
                </div>
            </div>
            <div className="di-wrapper">
            <Link to={"/"}>
                <img className={hamburgerOpen ? "logoDisplayNone" : "logo"} src={log} alt="shaya-logo" />
            </Link>
            <img className="di_header" src={di} alt="" />
            </div>
            {/* MAIN HEADER */}
            <nav className={hamburgerOpen ? "navDisplayNone" : "nav"}>

                <ul>
                    <li> <Link to={'/kund'} className="mainNavLink">Anlita oss</Link>
                    </li>
                </ul>
                <ul>
                    <li>  <a className="mainNavLink" href="https://career.shayasolutions.com/#section-jobs" target="_blank" rel="noopener noreferrer">Lediga jobb</a>
                    </li>
                </ul>
                
                <ul>
                    <li> <Link to={'/nyheter'} className="mainNavLink">Nyheter</Link>
                    </li>
                </ul>
                {/* <ul>
                    <li>  <a href="https://vemasy.com/Account/Login" className="mainNavLink">VMS login</a>
                    </li>
                </ul> */}
            </nav>

            <div className="hamburgerDiv"
                onClick={handleClick}>
                <div className={hamburgerOpen ? "hamburgerOpen" : "hamburger"}>
                    <div className={hamburgerOpen ? "burgerWhite" : "burger"}></div>
                    <div className={hamburgerOpen ? "burgerWhite" : "burger"}></div>
                    <div className={hamburgerOpen ? "burgerWhite" : "burger"}></div>
                </div>
            </div>
        </header>
    );
}

export default Header;