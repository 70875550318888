import "./Management-Cases.css"
import Header from "../../components/Header/Header"
import Footer from "../../components/Footer"
import ManagementCasesComponent from "../../components/ManagementCasesComponent/ManagementCasesComponent"
import FadeInSection from "../../components/FadeInSection";
import { useEffect } from "react";
import WhatWeDo from "../../components/WhatWeDOo/WhatWeDo"
import { Link } from "react-router-dom";
const ManagementCases = () => {
    useEffect(() => {
        window.scrollTo({top: 0, left: 0});
       
      }, []);

    return (
        
        <>
        <Header />
        <div className="container-ManagementCases">
            
            
            <div className="ManagementCases-wrapper">
            
                <div className="ITCases-hero">
                <FadeInSection> 
                    <h2>Management referensuppdrag</h2>

                </FadeInSection>
                </div>
                
            </div>
            

         {   <ManagementCasesComponent />}
           

        </div>
        <Footer />
        </>
        
    );
}

export default ManagementCases;