import "./Slider.css"
import { motion } from "framer-motion"
import { Link } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import arrow from "../../img/arrow.png"
const MySliderManagement = () => {



    const managementCase = [
        {
          title: "VAERNA stöttar bolag inom transportsektorn med förändringsledare",
          body: `Kunden behövde en förändringsledare för att genomföra deras uppgradering av IFS. Under större delen av 2019 pågick ett analys- och planeringsarbete hos kunden. Projektet har fått godkänt att påbörja genomförandefasen vilket kommer att pågå fram till våren 2021 och kommer att vara uppdelat på två releaser. Release 1 handlar till stora delar om uppgradering och release och release 2 innebär införande av ny funktionalitet. Kunden har sedan 2014 använt IFS v8 för i huvudsak ekonomi och underhåll och projektet kommer att uppgradera till IFS v10. I samband med uppgraderingen avvecklas ett stort antal anpassningar och användningen av IFS breddas till att bli ett koncerngemensamt affärssystem med stöd till ett flertal verksamhetsområden. Detta innebär också att kunden kommer att kunna sy ihop brutna processer vilket ska resultera i ett effektivare arbete ute i verksamheten. Förändringsledning kommer att bli mycket centralt för att uppnå de förväntade effekterna av detta projekt. `,
          start: "Uppdraget startades Q1 2020.",
          alt: "bild på it",
          id: "1",
          arbetsuppgifter: [
            "Planera, förbereda och genomföra de aktiviteter som erfordras för att säkerställa ett lyckat införande av förändrat arbetssätt, IT-stöd inom projektets ramar för verksamheten.",
            "Stötta kundens arbetsgrupper i arbetet med att utforma och dokumentera nya arbetsrutiner",
            "Planera, förbereda och genomföra utbildning i förändrat arbetssätt och IT-stödet för användargrupper i verksamheten.",
            "Ta fram kommunikationsplan",
            "Löpande kommunikation med projektets intressenter."],
          type: "management"
        },
        {
          title: "VAERNA stöttar region gällande överlämning av IT system",
          body: `Kunden behövde stärka sitt projekt med en konsult som har erfarenhet av överlämning och förvaltning inom transportsektorn. Kundens initiala projektet ska leverera nya fordon. Därefter kommer fordonen successivt att sättas i trafik kommande år. En viktig del av fordonsleveransen består av ett modernt och ändamålsenligt IT-stöd både ombord och iland för dessa fordon. Delprojektet inom IT skall integrera kundens källsystem via deras integrationsplattform mot fordonsleverantörens landbaserade system. Kundens källsystem består av system för kameraövervakning, låssystem, BI system samt ett nytt infotainmentsystem. Överlämningen syftar att säkerställa att mottagande förvaltningsobjekt kan förvalta IT lösningen samt att säkerställa/ta fram en supportprocess med drift och servicedeskrutiner för att kunna följa upp incidenter och problem. Konsulten förväntas agera överlämningsansvarig och självständigt driva överlämningsarbetet internt fram till godkänd överlämning.`,
          start: "Uppdraget startades Q2 2021.",
          alt: "bild på teknik",
          id: "2",
          type: "management"
        },
        {
          title: "VAERNA stöttar energibolag med en Projektledare",
          body: `Kunden behövde stärka sitt projekt inom Elkraft-Automation med en Projektledare på kundens siter för PMO samt för nyuppförande av anläggningar.`,
          start: "Uppdraget startades Q3 2019.",
          alt: "bild på teknik",
          id: "3",
          arbetsuppgifter: [
            "Leda, driva och leverera Projekt/delprojekt inom ramen för Elkraft/Automation till uppsatta mål, avseende tid, scope, kvalitet och budget enligt investeringsprocessen",
            "Leda och styra delprojektmedlemmar och leverantörer/ entreprenörer inom ramen för Elkraft/Automation.",
            "Ansvara för att utreda och ta fram tekniska lösningar inom delprojektet utifrån behov, idéer och krav från kunder enligt direktiv från Projektledare.",
            "Säkerställa att den tekniska lösningen följer kundens ATB och övriga krav",
            "Säkerställa/ta fram tekniska specifikationer och förfrågningsunderlag samt ansvara för utvärdering och upphandling i samråd med Projektledaren och Inköp.",
            " ta fram budget, tids- och resursplan",
            "leda och driva sitt delprojekt enligt projektplan och hantera den dagliga styrningen",
            "identifiera samt hantera projekt- och EHS-risker",
            "rapportera till projektledaren",
            "säkra samordning och kommunikation internt och externt hantera safety och EHS-frågor."
          ],
          type: "management"
        },
        {
          title: "VAERNA stöttar fastighetsbolag med upphandlingskonsult",
          body: `Kunden behövde stärka sin verksamhet med en upphandlingskonsult för planerade entreprenadupphandlingar under de kommande åren. Kravet på Shaya Solutions och konsulten är att självständigt utföra entreprenadupphandlingar enligt LOU. Upphandlingskonsulten leder entreprenadupphandlingar i samråd med verksamheten, vilket innebär bl.a. att ha ansvar för marknadsanalysen, fastställande av upphandlingsunderlag till genomförande av upphandling såsom utvärdering och avtalsskrivning. Konsulten hanterar upphandlingar av ramavtal och projektupphandlingar av byggentreprenader på ett LOU-mässigt korrekt sätt till färdigt kontrakt/ramavtal.`,
          start: "Uppdraget startades Q2 2021.",
          alt: "bild på teknik",
          id: "4",
          type: "management"
        },
        {
          title: "VAERNA stöttar myndighet med en Verksamhetsarkitekt",
          body: "Kunden behövde stärka sitt team som arbetar med deras föreläggandeprocess med en Verksamhetsarkitekt.Konsulten har arbetat i utvecklingsinitiativ samt förberedande arkitekturarbete. Särskilt fokus har legat på processkartläggning, processförbättring och automation. Kunden arbetar också med ett införande av Sparx Enterprise Architect där konsulten förväntats ha mycket god kompetens.",
          start: "Uppdraget startades Q2 2020.",
          arbetsuppgifter: ["Leda och dokumentera workshops avseende verksamhetsmodellering.",
            "Ta fram och utveckla verksamhetsarkitekturmodeller, exempelvis förmåge-, process-, intressent-, begrepps- och informationsmodeller samt beskriva samband mellan olika skikt, exempelvis verksamhets- och IT-arkitektur.",
            " Beskriva samband mellan verksamhets- och IT-arkitektur i Archimate.",
            "Analysera och dra slutsatser om förbättringar kopplade till behov som uttrycks i verksamhetsplaner och –strategier samt automatiseringspotential.",
            "Utveckla arbetssätt, teknologier och metoder för effektiv användning av Sparx Enterprise Architect.",
          ],
          alt: "bild på teknik",
          id: "5",
          type: "management"
        },
      ]


  const [width, setWidth] = useState(0);
  const sliderWrapper = useRef();

  useEffect(() => {
    setWidth(sliderWrapper.current.scrollWidth - sliderWrapper.current.offsetWidth);
  }, [])
  return (
    <>
    <div className="slider-container">
      <motion.div className="slider-wrapper" ref={sliderWrapper} whileTap={{ cursor: "grabbing" }}>
        <motion.div className="inner-crousel1"
          drag="x"
          dragConstraints={{ right: 0, left: -width }}
        >
          {
            managementCase && managementCase.map((c, i) => {
              return (

                <div className="case-text-wrapper case case-slider">
                    <h3 className="scrolling-h3">Management</h3> 
                  <p>{c.title}</p>
                  <Link className="slider-a"  key={i} to={"/ettcase"} state={c} onClick={() => { window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }); }}>
                  <img className="arrow-ld" src={arrow} alt="pil-höger" />
                  </Link>
                </div>
              )
            })
          }


        </motion.div>
      </motion.div>

      </div >

     </>
  );
}

export default MySliderManagement;


