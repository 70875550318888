import "../Handbok.css"
import Footer from "../../../components/Footer";
import Header from "../../../components/Header/Header";
import { Link } from "react-router-dom";

import { useEffect } from "react";

const HandbokVarderingar = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 });
    }, []);
    return (
        <>
                <Header/>
                <div className="handbook-con-wrapper">
            <main>
                <section className="handbok-menu">
                    <div className="handbook-wrapper">
                        <h1 className="h1-separat">🌵 Arbetstid och lönehantering</h1>
                        <article className="handbok-article">
                            <p>👋 Lönen på Shaya Solutions utbetalas den 27:e varje månad, i efterhand. Det innebär att om du blir anställd den 1:a oktober får du din första lön den 27:e november
                                 (detta gäller i de flesta fallen, i ett fåtal fall i ett tidigare skede skrevs avtal med förskottsbetalning. Se ditt anställningsavtal för exakt info).
                                <br />
                                <br />
                                Vi använder ett internt tidrapporteringsystem via PE Accounting där du rapporterar din tid veckovis. 
                                <a href="https://my.accounting.pe/web/">Du kan logga in på sidan här</a>
                                <br />
                                <br />
                                Detta tidrapporteringsystem utgör grunden för löneutbetalningen, så det är viktigt att du blir
                                 bekant med systemet och rapporterar dina timmar regelbundet.
                                 <br />
                                 <br />
                                 Utöver att rapportera och godkänna dina timmar i PE Accounting, ber vi dig även meddela om du har varit sjuk, haft semester eller varit vårdledig.
                                 <br />
                                 <br />
                                 Vi vill gärna ha din tidrapportering senast den sista varje månad för den befintliga månaden.
                                 <br />
                                 <br />
                                 Du har möjlighet att själv godkänna lönen innan den 15:e varje månad. Fram till denna dag kan du skicka e-post till
                                  ekonomi@shayasolutions.se om du har några frågor eller anmärkningar.
                                  <br />
                                  <br />
                                  Vänligen notera att många konsultuppdrag kräver att konsulter rapporterar redan direkt till sin uppdragsgivare,
                                   och för dessa fortsätter det som vanligt + att de rapporterar sin tid till oss internt via PE Accounting.
                            
                                <br />
                                <br />
                                <a href="https://pe.infocaption.com/_3db881afaf416d36.guide?pageNumber=1">Här är en guide om hur du tidrapporterar i systemet </a>
                                <br />
                                <br />
                                När du loggar in i systemet första gången ombeds du klicka här: Login och välja "glömt lösenord".
                                 En länk skickas sedan till din e-postadress för att
                                 logga in och välja ett lösenord. Som ny konsult får du tillgång till PE Accounting på din första officiella anställningsdag.
                                
                                 <br />
                                <br />
                                 PE Accounting finns också som applikation för iPhone och Android för att göra det ännu smidigare! Appen kan laddas ner från din telefonens appbutik.
                            </p>
                        </article>
                    </div>
                </section>
            </main>
            </div>
            <Footer />
        </>
    );
}

export default HandbokVarderingar;