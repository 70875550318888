

import {Link} from "react-router-dom"
import { useEffect } from "react"
import "../WhatWeDoItComponent/wwd.css"


const WhatWeDoManagementComponent = () => {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }, []);

    const cases = [
        {
            title: "Informations- och IT-säkerhet",
            body: "Våra informationssäkerhetstjänster möjliggör våra kunders digitala affärsmöjligheter genom att tillhandahålla optimerade ledande säkerhetstjänster som skyddar dina informationstillgångar, produkter och tjänster. Genom att förstå dina cyber- och informationssäkerhetsrisker möjliggör och optimerar vi din organisations motståndskraft mot hot. Detta hjälper till att säkerställa att digital transformation kan fortsätta obehindrat och kan till och med hjälpa till att påskynda digitala projekt.",
            body2: "Vi samverkar nära den anlitande organisationen där vi fokuserar på kärnverksamheten där vi med stor lyhördhet tillhandahåller innovativa, praktiska och lämpliga säkerhetstjänster och lösningar. Utifrån er verksamhet och era behov. Vi har erfarna konsulter med en klar förståelse av affärsvärde och risk. Våra tjänster är inte sektor- eller branschberoende.",
            alt: "bild på it",
            fokus: ["Digital säkerhet", "Cybersäkerhet", "Företagssäkerhet", "Säkerhetsstrategi, risk och styrning"],
            id: "1"
        },
        {
            title: "Arkitektur",
            body: "Verksamheten investerar i it-lösningar för att kärnverksamhetensskull, för att kunna skapa ännu större värde och resultat. Kompetens och förståelse för arkitektur är en del i att förstå att investeringar görs rätt ur verksamhetensperspektiv – och inte för att ha ”de mest moderna it-lösningarna”, detta enligt Shaya Solutions värderingar och perspektiv. Vi hjälper flertalet kunder med just ovan – att tillsammans med deras organisationer ta fram rätt arkitektur som behövs och krävs – för den specifika verksamheten i syfte att optimera kärnverksamhetens resultat.",
            alt: "bild på it",
            id: "2"
        },
        {
            title: "User Experience (UX) / User Interface (UI) design",
            body: "I en önsketänkande designprocess kommer UX-design före UI-design. Detta så att UX-designen kan lägga fokuset på hur produkten eller systemet ska uppfylla ett resultat, värde, upplevelse alternativt lösa ett problem. UX-designer ska därefter, i syfte att förstå hur och varför användaren ska använda produkten eller systemet - genomföra ett researcharbete alternativt förstudie som kan innefatta allt ifrån intervjuer, tester med användare och analys av data.UI bryts ner till beståndsdelar i ett användargränssnitt som gör det möjligt för användare att interagera med en produkt eller system tack vare en serie av visuella element. Ett UI element kan vara ett ljud, en knapp, en skärm eller en sida – allt som innebär ett slags informationsutbyte mellan användaren och produkten, där en känsla skapas i syfte att vägleda. UX är den interna upplevelsen hos en användare när den interagerar med produkten eller de element som produkten gestaltar och representerar. UX-design fokuserar på hur användaren uppfattar svårighetsgraden eller enkelheten i att lösa slutföra en uppgift eller en aktivitet. Detta kopplar starkt till de behov användaren har samt vilka förväntningar den personen har på produkten eller systemet, beroende på tidigare erfarenheter från liknande produkter och system",
    
            body2: "Användarcentrerad design har användaren som utgångspunkt och främsta fokus i alla steg av design- och utvecklingsprocessen. Detta sker genom analys och undersökning men även genom valideringstest i riktiga miljöer och situationer där användaren involveras. Shaya Solutions hjälper företag med användarcentrerad design för att skapa verklig användarnytta, affärsnytta och samhällsnytta. Detta genom att ha verkliga/befintliga användare som utgångspunkt och främsta fokus i alla steg av design- och utvecklingsprocessen, men där höjd även tas för framtida och nya användare som tidigare inte använt tjänsten/produkten. Tveka inte att fråga oss.",
            alt: "bild på it",
            fokus: ["Nya digitala tjänster utvecklats, omformats och vidareutvecklats som resulterat i såväl kund- som affärsnytta", " Förbättrat en digital tjänst ur användbarhetsperspektiv", "Utveckla nya funktioner i era befintliga miljöer", "Samverkat inom en stor och multidisciplinär organisation som levererat mångfacetterade tjänster till hundratusentals använder dagligen", "Hjälpt kunden att förstå sina målgrupper bättre för att kunna prioritera och optimera rätt givet tjänsteutvecklingen kopplat till hela organisationen"],
            id: "3"
        },
        {
            title: "Kravhantering",
            body: "I en värld där en pandemi ritat om kartan för hur organisationer och projekt utvecklas och formas innebär det en ökad komplexitet på kravanalys. Kravanalys och kravhantering gör skillnad för våra kunder i flera led – inte enbart på projektnivå – utan direkt mot kund- och användarnivå då många produkter jobba med flera releaser parallellt i syfte att vara agila och snabba med att möta ett kundbehov. Shaya Solutions har bidragit till flertalet kunder där våra konsulter varit med i kravställningen av ny produkter och system inom samhällsbyggnad samt transportsektorn. Vår kundbas innefattar flera sektorer och branscher. Vi hanterar även kravställning och analys för webshoppar exempelvis. Det vanligaste våra konsulter inom kravhantering bemöter i en verksamhet är att behov och önskemål komma från användare av produkten eller tjänsten men också från avsändaren – ser olika ut i förhållande till kundorganisationens olika interna intressenter. Framgångsrika konsulter inom kravanalys blir därför avgörande för framgången för ett system- eller mjukvaruprojekt om de har en ytterst god kommunikations- och intressenthanteringsförmåga där kunder/användare sätts i centrum – i syfte att nå kärnverksamhetens huvudändamål och resultat. Våra konsulter har arbetat med kravhantering inom allt från webbprojekt och UX-förbättringar till integrationsprojekt och stora systembyten. Tveka inte att höra av dig så berättar vi mer.",

            alt: "bild på it",
            id: "4"
        },
        {
            title: "E-handel och Webbutveckling",
            body: "För majoriteten av alla de kunder vi hittills mött är webben navet i deras digitala plattform. Tekniska plattformar som är till för att attrahera, engagera och konvertera kunderna för att vara en representation av en idé, tanke, dröm, lösning och inte minst en affär. Den blir den självklara motorn även för andra sociala kanaler och spindeln i nätet för all digital marknadsföring. Våra konsulter täcker med sin kompetens hela spektrat av de kompetenser som krävs för att leverera helhetslösningar med stöd och spetskompetens från strategi till fungerande lösning, vilket inkluderar förvaltningen av den färdiga lösningen. De kunder vi möter har behov av produktinformation, sortimentstyrning, varuförsörjning likväl som kanalstyrning mot andra återförsäljningskanaler där det blir allt vanligare med olika hubbar för olika produkter. Strategin varierar för olika kunder – där målet kan variera att allt ifrån att sälja enbart via egna kanaler, såväl som via egen kanal tillsammans med andra kanaler, och inte minst att enbart sälja via andra kanaler än de egna. De spetskompetenser som erbjuds inom Shaya Solutions spänner sig över olika tekniklösningar där e-handelsplattformar ingår, lojalitetsprogram, webb, användarupplevelse samt PIM-lösningar (Product Information Management).",

            alt: "bild på it",
            id: "5"
        },
        {
            title: "DevOps",
            body: "DevOps för Shaya Solutions konsulter innebär framför allt handlar om att få kundens organisation att arbeta tillsammans mot gemensamma mål genom att ha utgångspunkten i en gemensam grund kring hur produkter och system ska leva och utvecklas. Utmaningen är när olika delar av organisationen rycker i utvecklarteamet från olika håll – vilket leder till att repeterbara och tillförlitliga processer blir svåra att uppnå med sämre kvalité som resultat. Slutprodukten blir en lidande organisation där ”allt är IT-teamets fel”. Våra DevOps-experter är experter på just detta – komplexa organisationer i ständig utveckling och förändring.",

            alt: "bild på it",
            id: "6"
        },
    ]
    return ( 
        <div className="cases-container">
        <div className="cases-div-wwd">
          <div className="one-case-wwd-it">
            <div className="case-flex">
              <p>Nätverkskunskap</p>
            </div>
            <div className="hash">
              <span>Förståelse för grundläggande nätverkskoncept som LAN, WAN, VLAN, subnätting, routing och
switching.</span>
              {/* <span>#Omfattningshantering</span>
              <span>#PMP</span>
              <span>#PMI</span>
              <span>#IPMA</span>
              <span>#Prince2</span>
              <span>#Riskhantering</span>
              <span>#Tidsplanering</span>
              <span>#Kvalitetsstyrning</span>
              <span>#Ekonomistyrning</span> */}
            </div>
            <Link to={"/ett-omrade"}></Link>
          </div>
          <div className="one-case-wwd-it">
            <div className="case-flex">
              <p>Protokollförståelse</p>
            </div>
            <div className="hash">
              <span>Djupgående kunskap om vanliga nätverksprotokoll som IP, TCP/UDP, ICMP, OSPF, BGP, STP och
andra.</span>
              {/* <span>#Omvärldsanalys</span>
              <span>#Beställarstöd</span>
              <span>#Kravhantering</span>
              <span>#Upphandling</span>
              <span>#Samverkansledning</span>
              <span>#Utbildning</span> */}
            </div>
            <Link to={"/ett-omrade"}></Link>
          </div>

          <div className="one-case-wwd-it">
            <div className="case-flex">
              <p>Nätverksutrustning</p>
            </div>
            <div className="hash">
              <span>Kunskap om och erfarenhet av att arbeta med nätverksutrustning som routrar, switchar, brandväggar
och accesspunkter från olika tillverkare (t.ex. Cisco, Juniper, Aruba).</span>
              {/* <span>#CIO</span>
              <span>#CFO</span>
              <span>#Förändringsledning</span> */}
            </div>
            <Link to={"/ett-omrade"}></Link>
          </div>

          <div className="one-case-wwd-it">
            <div className="case-flex">
              <p>Kabelinfrastruktur</p>
            </div>
            <div className="hash">
              <span>Kunskap om kabeltyper (t.ex. CAT5e, CAT6, fiber) och kablingsstandarder.</span>
              {/* <span>#Business controlling</span>
              <span>#Projektredovisning</span>
              <span>#Interim CFO</span>
              <span>#Investeringsprojekt</span>
              <span>#Projektutveckling</span> */}
            </div>
            <Link to={"/ett-omrade"}></Link>
          </div>

          <div className="one-case-wwd-it">
            <div className="case-flex">
              <p>Trådlösa nätverk</p>
            </div>
            <div className="hash">
              <span>Förståelse för WLAN-design, implementering, säkerhet och felsökning.</span>
            </div>
            <Link to={"/ett-omrade"}></Link>
          </div>


          
          <div className="one-case-wwd-it">
            <div className="case-flex">
              <p>Säkerhet</p>
            </div>
            <div className="hash">
              <span>Kunskap om nätverkssäkerhetskoncept som VPN, brandväggar, IDS/IPS och säkerhetspolicyer.</span>
            </div>
            <Link to={"/ett-omrade"}></Link>
          </div>

          <div className="one-case-wwd-it">
            <div className="case-flex">
              <p>Nätverksövervakning och diagnostik</p>
            </div>
            <div className="hash">
              <span>Erfarenhet av att använda nätverksövervakningsverktyg och diagnostikverktyg som Wireshark,
SolarWinds, PRTG m.fl.</span>
            </div>
            <Link to={"/ett-omrade"}></Link>
          </div>

          <div className="one-case-wwd-it">
            <div className="case-flex">
              <p>Nätverkstopologi</p>
            </div>
            <div className="hash">
              <span>Förmåga att designa och förstå olika nätverksarkitekturer och topologier.</span>
            </div>
            <Link to={"/ett-omrade"}></Link>
          </div>


          <div className="one-case-wwd-it">
            <div className="case-flex">
              <p>IPv6</p>
            </div>
            <div className="hash">
              <span>Kunskap om IPv6-protokollet, dess implementering och integration med IPv4.</span>
            </div>
            <Link to={"/ett-omrade"}></Link>
          </div>


          <div className="one-case-wwd-it">
            <div className="case-flex">
              <p>Automatisering</p>
            </div>
            <div className="hash">
              <span>Kunskap om nätverksautomatisering, scriptning och verktyg som Ansible eller Python för
nätverksadministration.</span>
            </div>
            <Link to={"/ett-omrade"}></Link>
          </div>


          <div className="one-case-wwd-it">
            <div className="case-flex">
              <p>VoIP och QoS</p>
            </div>
            <div className="hash">
              <span>Förståelse för Voice over IP och Quality of Service-tekniker för att prioritera trafik.</span>
            </div>
            <Link to={"/ett-omrade"}></Link>
          </div>

          <div className="one-case-wwd-it">
            <div className="case-flex">
              <p>Cloud-nätverk</p>
            </div>
            <div className="hash">
              <span>Kunskap om nätverksarkitekturer i molnmiljöer som AWS, Azure eller Google Cloud.</span>
            </div>
            <Link to={"/ett-omrade"}></Link>
          </div>

          <div className="one-case-wwd-it">
            <div className="case-flex">
              <p>Kapacitetsplanering</p>
            </div>
            <div className="hash">
              <span>Förmåga att förutsäga och hantera nätverkskapacitet baserat på användningsmönster och
affärsbehov.</span>
            </div>
            <Link to={"/ett-omrade"}></Link>
          </div>


          <div className="one-case-wwd-it">
            <div className="case-flex">
              <p>Katastrofåterställning</p>
            </div>
            <div className="hash">
              <span>Planering och implementering av backup- och katastrofåterställningsstrategier för nätverket.</span>
            </div>
            <Link to={"/ett-omrade"}></Link>
          </div>
          
        </div>
        <Link to={"/kontakt"} className="btn-blue margin-btn">Kontakta oss</Link>      
      </div>
     );
}
 
export default WhatWeDoManagementComponent;