import "./Handbok.css"
import { useEffect } from "react";
import handbokImg from "../../img/hanbok/manual.png"
import Header from "../../components/Header/Header";

import Footer from "../../components/Footer";

import { Link } from "react-router-dom";
const Handbok = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 });
    }, []);
    return (
        <>
        <Header/>
            <main>
                <section className="handbok-hero-lang">
                    <div className="icon-wrapper">
                        <img className="handbok-icon" src={handbokImg} alt="icon på en handbok" />
                    </div>
                </section>
                <section className="handbok-menu">
                    <div className="handbook-wrapper">
                        <h1>Shaya Solutions Guidelines</h1>
                        <article className="handbok-article">
                            <p>👋 Välkommen till Shaya Solutions offentliga guide. Vi delar detta avsnitt i vår guide för att ge en översikt till våra anställda, konsulter, underkonsulter, partners och kandidater om hur det är att vara en del av Shaya Solutions. Vi fortsätter att uppdatera med nytt material och om du inte hittar vad du söker, tveka inte att kontakta oss på support@shayasolutions.se för att få mer information. Delar av nedan Guidelines aktiveras i samband med publiceringen av detta, dvs., från och med 2023-08-01.
                                <br />
                                <br />
                                Shaya Solutions är inte nästa generations företag – utan nutidens och för alltid samtidens företag – inom IT, Management- och Ingenjörstjänster.
                                <br />
                                <br />
                                Hybrid - vi kombinerar fördelarna i det traditionella konsultbolaget med fördelarna i den traditionella konsultmäklaren.

                            </p>
                        </article>
                        <section className="files-wrapper">
                            <section className="article-wrapper">
                                <article>
                                    <h2>Konsulter</h2>
                                    <h3>Anställningsförmåner</h3>
                                    <p>Det finns sällan något bolag som kan erbjuda guld och gröna skogar. Mycket handlar om matchning mellan dina behov – samt val av rätt bolag. Detta är viktigt att påpeka. Utvärdera olika aktörer på marknaden och förstå vad som passar dig.
                                        <br />
                                        <br />
                                     <b className="b-m-ik">Internt anställda och anställda konsulter.</b>
                                    </p>
                                    <Link to={"/flexibel-arbetstid"}>💎 Flexibel arbetstid</Link>
                                    <Link to={"/foraldrar-ledighet"}>🌵 Föräldrarledighet</Link>
                                    <Link to={"/ledighet-graviditet"}>👋 Ledighet i samband med graviditet och förlossning</Link>
                                    <Link to={"/ledighet-foraldrar"}>💎 Ledighet för föräldrar</Link>
                                    <Link to={"/friskvardbidrag"}>🌵 Friskvårdsbidrag</Link>
                                    <Link to={"/elektronik"}>👋 Elektronik</Link>
                                    <Link to={"/litteratur"}>💎 Litteratur</Link> 
                                    <Link to={"/ledighet-utveckling"}>🌵 Ledighet för personlig utveckling / viloperiod</Link>
                                    <Link to={"/kompetens-utveckling"}>👋 Kompetensutveckling</Link>
                                    <Link to={"/pension"}>💎 Pension</Link> 


                                    <h3>Arbetstid och lönehantering</h3> 
                                    <Link to={"/arbetstid-lon"}>🌵 Arbetstid och lönehantering</Link>

                                    
                                    <h3>FAQ</h3> 
                                    <Link to={"/faq"}>👋 FAQ</Link>
                                </article>
                                <article>
                                    <h2>Kvalitetssystem</h2>
                                    <Link to={"/miljo-hallbarhet"}>🌵 Miljö och hållbarhet</Link>
                                </article>
                                <article>
                                    <h2>Övrigt</h2>
                                    <Link to={"/shaya-huvudkontor"}>💎 Shaya Solutions huvudkontor</Link>
                                    <Link to={"/shaya-fragor"}>👋 Kontakt vid frågor eller funderingar</Link> 
                                </article>
                    
                            </section>
                        </section>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
}

export default Handbok;