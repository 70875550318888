
import Header from "../../components/Header/Header"
import Footer from "../../components/Footer"
import WhatWeDoITNEW from "../../components/WhatWeDoItNEW/WhatWeDoITNEWComponent"
import { useEffect } from "react"
import WhatWeDo from "../../components/WhatWeDOo/WhatWeDo"
const WhatWeDoTeknik = () => {
    useEffect(() => {
        window.scrollTo({top: 0, left: 0});
       
      }, []);


    return (
        <>
        <Header />
        <div className="container-ITCases">
            
            <div className="ITCases-wrapper">
                {/* <h1>Teknik</h1> */}
                <div className="ITCases-hero">
                    <h2>Expertområde IT ARKITEKTUR OCH INTEGRATION</h2>
                    <p className="slow-fade">En integrationsarkitekt inom IT ansvarar för att designa och implementera
lösningar som möjliggör integration mellan olika system och applikationer. De
säkerställer att system kan kommunicera och samverka på ett smidigt, säkert
och effektivt sätt. Här är några kompetensområden som är relevanta för våra
integrationsarkitekter i uppdrag och projekt.</p>
                </div>
            </div>

         {   <WhatWeDoITNEW />}
         {<WhatWeDo/>}
        </div>
        <Footer />
        </>
    );
}

export default WhatWeDoTeknik;