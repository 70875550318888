
import arsredovisning from "../../img/Bild2.png"

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer";
import { useEffect } from "react";
import "./NewNews1.css";
const NewNews1 = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 });
    
      }, []);
    return ( 
        <><Header/>
        <div className="container-newNews">
            <div className="newNews-wrapper">
            <span>#Nyhet</span>
            <h1>Sammanfattning av årsredovisning 2021</h1>
            <img src={arsredovisning} alt="" />
            <p>Teamet på Shaya Solutions gjorde ett fantastiskt jobb
                 under 2021. Sammanfattning av årsredovisning 2021
                  beskriver bakgrunden till hur vi tog oss an 2021
                   och hur vi ser på 2022. Vi ser fram emot ett fortsatt
                    utvecklande 2022 samt ett stundande 2023.</p>
                    <a target="_blank" rel="noopener noreferrer" href="https://onedrive.live.com/?authkey=%21AIPsp%2Di7wmWD5Ns&cid=8DE706180B3577D6&id=8DE706180B3577D6%218042&parId=8DE706180B3577D6%216122&o=OneUp" className="newNews-b">Läs Shaya Solutions presentation av årsredovisningen för 2021.</a>
            </div>
        </div>
        <Footer/>
        </>
     );
}
 
export default NewNews1;