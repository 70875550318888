import "./Partner.css"
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer";
import WhatWeDo from "../../components/WhatWeDOo/WhatWeDo"
import FadeInSection from "../../components/FadeInSection";
import { Link } from "react-router-dom";
import { useEffect } from "react";

const Partner = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 });

    }, []);

    <FadeInSection />
    return (
        <>
            <Header />
            <div className="container-partner">
                <div className="partner-content-container">
                    <h1 className="fade">Partner inom Shaya Solutions</h1>
                    <p className="hero-text fade">Shaya Solutions är en ledande leverantör av 
                    tjänster och kompetens i Sverige. Med en passion för teknik och 
                    problemlösning, förstår vi både kunders och konsulters behov.
                     Som konsult eller egenföretagare kan du få gratis tillgång till 
                     konsultuppdrag genom <br /> <a target="_blank" rel="noopener noreferrer" href="https://career.shayasolutions.com/connect"> Connecta.</a></p>
                    <div className="process-partner-wrapper fade">
                    <h2 className="fade">Process vid sökning av ett konsultuppdrag</h2>
                        <FadeInSection>
                            <div className="process-partner-wrapper">
                            <div className="process-text-and-number-partner">
                            <span>I</span>
                                <p className="fade">Du söker lediga konsultuppdrag via <a href="https://career.shayasolutions.com/#section-jobs">Lediga uppdrag</a>.</p>
                            </div>
                                <div className="underline-large-white"></div>
                                <div className="process-text-and-number-partner">
                                <span>II</span>
                                <p className="fade">Du får återkoppling gällande ansökan oavsett vid positvt eller negativt utfall gällande matchning.</p>
                                </div>
                                <div className="underline-large-white"></div>
                                <div className="process-text-and-number-partner">
                                <span>III</span>
                                <p className="fade">Vid positv matchning görs en digital- alternativt fysisk avstämning inför intervju med beställare. </p>
                                </div>
                                <div className="underline-large-white"></div>
                                <div className="process-text-and-number-partner">
                                <span>IV</span>
                                <p className="fade">Vid ömsesidigt intresse och överenskommelse tecknas ett avtal som föregås av referenstagning.</p>
                                </div>
                                <div className="underline-large-white"></div>
                                <div className="process-text-and-number-partner">
                                <span>V</span>
                                <p className="fade">Efter start av uppdrag följer Shaya Solutions upp uppdraget gentemot beställare och agerar ombud.</p>
                                </div>
                            </div>
                        </FadeInSection>
                    </div>
                    <a className="btn-blue" href="https://career.shayasolutions.com/connect">Connecta här</a>
                    {/* WHAT WE DO COMPONENT */}
                    <FadeInSection>
                        <WhatWeDo />
                    </FadeInSection>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Partner;