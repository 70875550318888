

import {Link} from "react-router-dom"
import { useEffect } from "react"
import "../WhatWeDoItComponent/wwd.css"


const WhatWeDoTeknikComponent = () => {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }, []);

    const cases = [
        {
            title: "Informations- och IT-säkerhet",
            body: "Våra informationssäkerhetstjänster möjliggör våra kunders digitala affärsmöjligheter genom att tillhandahålla optimerade ledande säkerhetstjänster som skyddar dina informationstillgångar, produkter och tjänster. Genom att förstå dina cyber- och informationssäkerhetsrisker möjliggör och optimerar vi din organisations motståndskraft mot hot. Detta hjälper till att säkerställa att digital transformation kan fortsätta obehindrat och kan till och med hjälpa till att påskynda digitala projekt.",
        
            body2: "Vi samverkar nära den anlitande organisationen där vi fokuserar på kärnverksamheten där vi med stor lyhördhet tillhandahåller innovativa, praktiska och lämpliga säkerhetstjänster och lösningar. Utifrån er verksamhet och era behov. Vi har erfarna konsulter med en klar förståelse av affärsvärde och risk. Våra tjänster är inte sektor- eller branschberoende.",
            alt: "bild på it",
            fokus: ["Digital säkerhet", "Cybersäkerhet", "Företagssäkerhet", "Säkerhetsstrategi, risk och styrning"],
            id: "1"
        },
        {
            title: "Arkitektur",
            body: "Verksamheten investerar i it-lösningar för att kärnverksamhetensskull, för att kunna skapa ännu större värde och resultat. Kompetens och förståelse för arkitektur är en del i att förstå att investeringar görs rätt ur verksamhetensperspektiv – och inte för att ha ”de mest moderna it-lösningarna”, detta enligt Shaya Solutions värderingar och perspektiv. Vi hjälper flertalet kunder med just ovan – att tillsammans med deras organisationer ta fram rätt arkitektur som behövs och krävs – för den specifika verksamheten i syfte att optimera kärnverksamhetens resultat.",

            alt: "bild på it",
            id: "2"
        },
        {
            title: "User Experience (UX) / User Interface (UI) design",
            body: "I en önsketänkande designprocess kommer UX-design före UI-design. Detta så att UX-designen kan lägga fokuset på hur produkten eller systemet ska uppfylla ett resultat, värde, upplevelse alternativt lösa ett problem. UX-designer ska därefter, i syfte att förstå hur och varför användaren ska använda produkten eller systemet - genomföra ett researcharbete alternativt förstudie som kan innefatta allt ifrån intervjuer, tester med användare och analys av data.UI bryts ner till beståndsdelar i ett användargränssnitt som gör det möjligt för användare att interagera med en produkt eller system tack vare en serie av visuella element. Ett UI element kan vara ett ljud, en knapp, en skärm eller en sida – allt som innebär ett slags informationsutbyte mellan användaren och produkten, där en känsla skapas i syfte att vägleda. UX är den interna upplevelsen hos en användare när den interagerar med produkten eller de element som produkten gestaltar och representerar. UX-design fokuserar på hur användaren uppfattar svårighetsgraden eller enkelheten i att lösa slutföra en uppgift eller en aktivitet. Detta kopplar starkt till de behov användaren har samt vilka förväntningar den personen har på produkten eller systemet, beroende på tidigare erfarenheter från liknande produkter och system",
         
            body2: "Användarcentrerad design har användaren som utgångspunkt och främsta fokus i alla steg av design- och utvecklingsprocessen. Detta sker genom analys och undersökning men även genom valideringstest i riktiga miljöer och situationer där användaren involveras. Shaya Solutions hjälper företag med användarcentrerad design för att skapa verklig användarnytta, affärsnytta och samhällsnytta. Detta genom att ha verkliga/befintliga användare som utgångspunkt och främsta fokus i alla steg av design- och utvecklingsprocessen, men där höjd även tas för framtida och nya användare som tidigare inte använt tjänsten/produkten. Tveka inte att fråga oss.",
            alt: "bild på it",
            fokus: ["Nya digitala tjänster utvecklats, omformats och vidareutvecklats som resulterat i såväl kund- som affärsnytta", " Förbättrat en digital tjänst ur användbarhetsperspektiv", "Utveckla nya funktioner i era befintliga miljöer", "Samverkat inom en stor och multidisciplinär organisation som levererat mångfacetterade tjänster till hundratusentals använder dagligen", "Hjälpt kunden att förstå sina målgrupper bättre för att kunna prioritera och optimera rätt givet tjänsteutvecklingen kopplat till hela organisationen"],
            id: "3"
        },
        {
            title: "Kravhantering",
            body: "I en värld där en pandemi ritat om kartan för hur organisationer och projekt utvecklas och formas innebär det en ökad komplexitet på kravanalys. Kravanalys och kravhantering gör skillnad för våra kunder i flera led – inte enbart på projektnivå – utan direkt mot kund- och användarnivå då många produkter jobba med flera releaser parallellt i syfte att vara agila och snabba med att möta ett kundbehov. Shaya Solutions har bidragit till flertalet kunder där våra konsulter varit med i kravställningen av ny produkter och system inom samhällsbyggnad samt transportsektorn. Vår kundbas innefattar flera sektorer och branscher. Vi hanterar även kravställning och analys för webshoppar exempelvis. Det vanligaste våra konsulter inom kravhantering bemöter i en verksamhet är att behov och önskemål komma från användare av produkten eller tjänsten men också från avsändaren – ser olika ut i förhållande till kundorganisationens olika interna intressenter. Framgångsrika konsulter inom kravanalys blir därför avgörande för framgången för ett system- eller mjukvaruprojekt om de har en ytterst god kommunikations- och intressenthanteringsförmåga där kunder/användare sätts i centrum – i syfte att nå kärnverksamhetens huvudändamål och resultat. Våra konsulter har arbetat med kravhantering inom allt från webbprojekt och UX-förbättringar till integrationsprojekt och stora systembyten. Tveka inte att höra av dig så berättar vi mer.",
           
            alt: "bild på it",
            id: "4"
        },
        {
            title: "E-handel och Webbutveckling",
            body: "För majoriteten av alla de kunder vi hittills mött är webben navet i deras digitala plattform. Tekniska plattformar som är till för att attrahera, engagera och konvertera kunderna för att vara en representation av en idé, tanke, dröm, lösning och inte minst en affär. Den blir den självklara motorn även för andra sociala kanaler och spindeln i nätet för all digital marknadsföring. Våra konsulter täcker med sin kompetens hela spektrat av de kompetenser som krävs för att leverera helhetslösningar med stöd och spetskompetens från strategi till fungerande lösning, vilket inkluderar förvaltningen av den färdiga lösningen. De kunder vi möter har behov av produktinformation, sortimentstyrning, varuförsörjning likväl som kanalstyrning mot andra återförsäljningskanaler där det blir allt vanligare med olika hubbar för olika produkter. Strategin varierar för olika kunder – där målet kan variera att allt ifrån att sälja enbart via egna kanaler, såväl som via egen kanal tillsammans med andra kanaler, och inte minst att enbart sälja via andra kanaler än de egna. De spetskompetenser som erbjuds inom Shaya Solutions spänner sig över olika tekniklösningar där e-handelsplattformar ingår, lojalitetsprogram, webb, användarupplevelse samt PIM-lösningar (Product Information Management).",
          
            alt: "bild på it",
            id: "5"
        },
        {
            title: "DevOps",
            body: "DevOps för Shaya Solutions konsulter innebär framför allt handlar om att få kundens organisation att arbeta tillsammans mot gemensamma mål genom att ha utgångspunkten i en gemensam grund kring hur produkter och system ska leva och utvecklas. Utmaningen är när olika delar av organisationen rycker i utvecklarteamet från olika håll – vilket leder till att repeterbara och tillförlitliga processer blir svåra att uppnå med sämre kvalité som resultat. Slutprodukten blir en lidande organisation där ”allt är IT-teamets fel”. Våra DevOps-experter är experter på just detta – komplexa organisationer i ständig utveckling och förändring.",
            
            alt: "bild på it",
            id: "6"
        },
    ]
    return ( 
        <div className="cases-container">
        <div className="cases-div-wwd">
          <div className="one-case-wwd-it">
            <div className="case-flex">
              <p>Systemintegration</p>
            </div>
            <div className="hash">
              <span>Djupgående förståelse för metoder och tekniker för att integrera olika IT-system, både internt och
externt.</span>
              {/* <span>#PLC</span>
              <span>#SCADA</span>
              <span>#HMI</span>
              <span>#Styr och regler</span>
              <span>#Elkraft</span>
              <span>#I&C</span>
              <span>#Detaljkonstruktion</span>
              <span>#Dokumentation</span>
              <span>#Projektering</span>
              <span>#Siemens</span>
              <span>#PCS7</span>
              <span>#ABB</span>
              <span>#800xA</span>
              <span>#Industriell IT</span>
              <span>#UPS</span> */}
            </div>
            <Link to={"/ett-omrade"}></Link>
          </div>
          <div className="one-case-wwd-it">
            <div className="case-flex">
              <p>API-design och -hantering</p>
            </div>
            <div className="hash">
              <span>Kunskap om att skapa, implementera, hantera och säkra API:er (Application Programming
Interfaces).</span>
              {/* <span>#Anläggningsdynamik</span>
              <span>#Process- och anläggningskonstruktion</span>
              <span>#Beräkning</span> */}
            </div>
            <Link to={"/ett-omrade"}></Link>
          </div>
          <div className="one-case-wwd-it">
            <div className="case-flex">
              <p>Meddelandeprotokoll</p>
            </div>
            <div className="hash">
              <span>Erfarenhet av olika meddelandeprotokoll som SOAP, REST, MQTT, AMQP m.fl.</span>
              {/* <span>#Dokumentation</span>
              <span>#CAD </span>
              <span>#CATIA v5</span>
              <span>#Konstruktionssamordning</span>
              <span>#Projektering</span>
              <span>#QA</span>
              <span>#QC</span>
              <span>#Objektledning</span> */}
            </div>
            <Link to={"/ett-omrade"}></Link>
          </div>
          <div className="one-case-wwd-it">
            <div className="case-flex">
              <p>Middleware-verktyg</p>
            </div>
            <div className="hash">
              <span>Kunskap om populära integrationsplattformar och middleware-verktyg som MuleSoft, Apache Kafka,
RabbitMQ, Microsoft BizTalk m.fl.</span>
              {/* <span>#Produktionsledning</span>
              <span>#Konstruktion</span>
              <span>#Dokumentation</span>
              <span>#Projektering</span> */}
            </div>
            <Link to={"/ett-omrade"}></Link>
          </div>
          <div className="one-case-wwd-it">
            <div className="case-flex">
              <p>Dataformat</p>
            </div>
            <div className="hash">
              <span>Förståelse för olika dataformat som XML, JSON, Avro m.fl.</span>
              {/* <span>#Installationsledning</span>
              <span>#Projektering</span>
              <span>#VA</span>
              <span>#Fastighetsautomation</span> */}
            </div>
            <Link to={"/ett-omrade"}></Link>
          </div>
          <div className="one-case-wwd-it">
            <div className="case-flex">
              <p>Transformering av data</p>
              <div className="hash">
                <span>Erfarenhet av verktyg och tekniker för att transformera data mellan olika format och strukturer, till
exempel XSLT.</span>
                {/* <span>#Fysiskt skydd</span> */}
              </div>
              <Link to={"/ett-omrade"}></Link>
            </div>
          </div>

          <div className="one-case-wwd-it">
            <div className="case-flex">
              <p>Säkerhet</p>
              <div className="hash">
                <span>Kunskap om att säkra integrationslösningar genom autentisering, auktorisering, kryptering och andra
säkerhetsmekanismer.</span>
              </div>
              <Link to={"/ett-omrade"}></Link>
            </div>
          </div>

          <div className="one-case-wwd-it">
            <div className="case-flex">
              <p>Hög tillgänglighet och skalbarhet</p>
              <div className="hash">
                <span>Designa integrationslösningar som är robusta, skalbara och kan hantera stora datamängder i realtid.</span>
              </div>
              <Link to={"/ett-omrade"}></Link>
            </div>
          </div>

          <div className="one-case-wwd-it">
            <div className="case-flex">
              <p>Affärssystem</p>
              <div className="hash">
                <span>Erfarenhet av integration med vanliga affärssystem som ERP, CRM, e-handelssystem m.fl.</span>
              </div>
              <Link to={"/ett-omrade"}></Link>
            </div>
          </div>

          <div className="one-case-wwd-it">
            <div className="case-flex">
              <p>Databaser</p>
              <div className="hash">
                <span>Kunskap om relationella och NoSQL-databaser och hur man integrerar dem med andra system.</span>
              </div>
              <Link to={"/ett-omrade"}></Link>
            </div>
          </div>

          <div className="one-case-wwd-it">
            <div className="case-flex">
              <p>Mönster för systemintegration</p>
              <div className="hash">
                <span>Förståelse för etablerade integrationsmönster som Publish/Subscribe, Request/Reply, Event-driven
Architecture m.fl.</span>
              </div>
              <Link to={"/ett-omrade"}></Link>
            </div>
          </div>

          <div className="one-case-wwd-it">
            <div className="case-flex">
              <p>Cloud-tjänster</p>
              <div className="hash">
                <span>Kunskap om molnplattformar som AWS, Azure, Google Cloud och hur man integrerar med
molnbaserade tjänster</span>
              </div>
              <Link to={"/ett-omrade"}></Link>
            </div>
          </div>

          <div className="one-case-wwd-it">
            <div className="case-flex">
              <p>Hybridmiljöer</p>
              <div className="hash">
                <span>Förmåga att designa integrationslösningar som sträcker sig över både on-premises och molnbaserade
miljöer.</span>
              </div>
              <Link to={"/ett-omrade"}></Link>
            </div>
          </div>

          <div className="one-case-wwd-it">
            <div className="case-flex">
              <p>Dokumentation och specifikationer</p>
              <div className="hash">
                <span>Förmåga att skapa klara och tydliga dokument och specifikationer för integrationsprojekt.</span>
              </div>
              <Link to={"/ett-omrade"}></Link>
            </div>
          </div>

          <div className="one-case-wwd-it">
            <div className="case-flex">
              <p>API-hantering</p>
              <div className="hash">
                <span>Erfarenhet av att arbeta med och utveckla API:er specifikt för OT-integrationer.</span>
              </div>
              <Link to={"/ett-omrade"}></Link>
            </div>
          </div>

          <div className="one-case-wwd-it">
            <div className="case-flex">
              <p>Protokollkunskap</p>
              <div className="hash">
                <span>Förståelse för både IT- och OT-specifika protokoll för att säkerställa en problemfri kommunikation
mellan olika system.</span>
              </div>
              <Link to={"/ett-omrade"}></Link>
            </div>
          </div>

          <div className="one-case-wwd-it">
            <div className="case-flex">
              <p>Säkerhetsstandarder för integration</p>
              <div className="hash">
                <span>Kunskap om säkerhetspraxis specifikt för integrationspunkter, som säkra dataöverföringsmetoder.</span>
              </div>
              <Link to={"/ett-omrade"}></Link>
            </div>
          </div>

          <div className="one-case-wwd-it">
            <div className="case-flex">
              <p>Testning</p>
              <div className="hash">
                <span>Erfarenhet av att testa integrationslösningar för att säkerställa att de fungerar som förväntat och är
säkra.</span>
              </div>
              <Link to={"/ett-omrade"}></Link>
            </div>
          </div>

          <div className="one-case-wwd-it">
            <div className="case-flex">
              <p>Dokumentation</p>
              <div className="hash">
                <span>Förmåga att skapa tydliga och noggranna tekniska specifikationer, arkitekturdiagram och
användardokumentation.</span>
              </div>
              <Link to={"/ett-omrade"}></Link>
            </div>
          </div>
        </div>
        <Link to={"/kontakt"} className="btn-blue margin-btn">Kontakta oss</Link>      
      </div>
          
     );
}
 
export default WhatWeDoTeknikComponent;