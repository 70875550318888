import "./Slider.css"
import { motion } from "framer-motion"
import { Link } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import arrow from "../../img/arrow.png"
const MySliderTeknik = () => {

    const teknikCase = [
        {
          title: "VAERNA stöttar energibolag med förstärkning av en montageledare",
          body: `Kunden behövde stärka sitt team för deras löpande investeringsprojekt med en montageledare på deras anläggningar. Arbetsuppgifterna för montageledaren består av att samordna samtliga aktiviteter och entreprenörer på anläggningsplatsen med övriga aktiviteter. Vidare omfattas uppdraget av att verka för att montagearbetet och driftsättning följer kundens regelverk och administrativa rutiner.
          Som montageledare har hen följt upp projektets alla delmoment så som tex. arbetsmiljöplan och ansvarar för att projektet följer kundens regelverk för arbetsmiljö. Montageledaren agerar länk mellan Investeringsprojekt och platsorganisationen samt den övergripande revisionssamordningen och samtliga entreprenörer. Montageledaren följer upp att montagearbetena fortlöper enligt plan, från start till avslut. Montageledaren svarar för kontinuerlig uppföljning och återrapportering till Uppdragsgivare/Projektledaren och den övergripande revisionssamordningen.`,
          start: "Uppdraget startades Q1 2020.",
          alt: "bild på it",
          id: "1",
          type: "teknik"
      },
      {
          title: "VAERNA stöttar energibolag med en Safety Officer för att stärka arbetsmiljön",
          body: `Kunden behövde stärka sin verksamhet med en konsult för kontroll av arbetsmiljön på deras arbetsplats. Kunden vill säkerställa efterlevnad av arbetsmiljölagen samt att utbilda personalen på plats för att stärka arbetsmiljökulturen och för att öka förståelsen för varför det är viktigt att säkerheten alltid ska komma först. Safety Officers uppdrag innebär främst kontroll av arbetsmiljö (AFS 1999:3), efterlevnad av arbetsmiljölagen samt dialog med personal på plats för att stärka arbetsmiljökulturen och öka förståelsen för varför det är viktigt att alltid sätta säkerheten först. Kontroll sker av arbetsmiljö och miljö genom platsbesök. Dessa genomförs dels oannonserat, dels tillsammans med arbetsplatsens BAS-U. Platsbesök planeras också in när inkoppling sker av ledningar för att kontroll och samtal ska kunna föras med driftorganisationen. Frågeställningar och kontroller varierar beroende av vilka som är på plats, och i vilket skede arbetet befinner sig i. Arbetsplatsbesök sker ibland med representant från näts ledningsgrupp. Inbjudan till, och beslut om var kontroller ska göras, sker genom samråd med driftledare och/eller från entreprenadansvariga och byggledare.`,
          start: "Uppdraget startades Q1 2020.",
          alt: "bild på teknik",
          id: "2",
          type: "teknik"
      },
      {
          title: "VAERNA stöttar elbolag med Teknik-specialist för reservlarmsändare",
          body: "Kunden behövde stärka sitt team som arbetar med ett av deras driftövervaknings- och styrsystem som är ett av de viktigaste systemen med en Teknik-specialist. Konsultens uppdrag är att bli en del av den grupp som förvaltar och vidareutvecklar systemet. Kunden arbetar intensivt med att utveckla och förbättra funktionalitet samt stabilitet i systemet. Uppdraget kräver erfarenheter både från systemutveckling samt drift/förvaltning. Det finns dock flertal andra system runt omkring driftövervakningssystemet som är nödvändiga för att detta ska fungera på ett optimalt sätt. Ett av dessa system är ett larmsystem som består av hårdvara och en central applikation som tar emot larm från denna hårdvara. Att förstå larmhanteringen i mottagarapplikationen på en övergripande nivå och kunna administrera systemet i Linux och vara behjälplig vid tester och felsökning är några av arbetsmomenten. Shaya Solutions är stolta över att kunna bidra med denna kompetens.",
          start: "Uppdraget startades Q3 2020.",
          alt: "bild på teknik",
          id: "3",
          type: "teknik"
      },
     
      ]
    


  const [width, setWidth] = useState(0);
  const sliderWrapper = useRef();

  useEffect(() => {
    setWidth(sliderWrapper.current.scrollWidth - sliderWrapper.current.offsetWidth);
  }, [])
  return (
    <>
    <div className="slider-container">
      <motion.div className="slider-wrapper" ref={sliderWrapper} whileTap={{ cursor: "grabbing" }}>
        <motion.div className="inner-crousel1"
          drag="x"
          dragConstraints={{ right: 0, left: -width }}
        >
          {
            teknikCase && teknikCase.map((c, i) => {
              return (
                <div className="case-text-wrapper case case-slider">
                <h3 className="scrolling-h3">Ingenjörstjänster</h3> 
              <p>{c.title}</p>
              <Link className="slider-a" key={i} to={"/ettcase"} state={c} onClick={() => { window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }); }}>
              <img className="arrow-ld" src={arrow} alt="pil-höger" />
              </Link>
            </div>
              )
            })
          }


        </motion.div>
      </motion.div>

      </div >

     </>
  );
}

export default MySliderTeknik;


