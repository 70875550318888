import shayadi from "../../img/shaya_di.png"
import image1 from "../../img/1.jpeg"
import image2 from "../../img/2.jpeg"
import image3 from "../../img/3.jpeg"
import konsult_sommar from "../../img/konsult_sommar.jpeg"
import di_white from "../../img/di_white.png"

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer";
import { useEffect } from "react";
import "./NewNews1.css";
const NewNews2 = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });

  }, []);
  return (
    <><Header />
      <div className="container-newNews">
        <div className="newNews-wrapper">
          <span>#Nyhet</span>
          <h1>Det har varit en ära. Hatten av och tack för mig. -Joseph Öberg Shaya</h1>
          <br /><br />
          <p>200+ människor - som Shaya Solutions (som snart byter namn till VAERNA) anställt och/eller hjälpt senaste 6 åren. Människor inom IT/Managment/Teknik till nytt arbete, konsultuppdrag och nya arbetsgivare/uppdragsgivare.
            <br /><br />
            40+ kunder - som ovan 200+ människor har hjälpt med att lösa komplexa projekt och skapa värde i - ja, utan att överdriva - i världsklass.
Stolt över att ha varit accelerator och katalysator i dessa människors liv på ett eller annat sätt.
<br /> <img src={image2} alt="" />
<br /><br />
Fingerspetzengefühl. Är det något jag utvecklat under åren som bolagsbyggare är självkännedom. Och här tar det stopp för mig.
<br /><br />
Jag har inte tiden att fortsätta ge det som bolagen kräver av mig.
Nöjd med de resultat och uppsatta mål som uppnåtts med bolagen.
<br /><br />
<b>Inte mist - en stundande utmaning som jag uppfattar som en möjlighet jag inte vill eller kan säga nej till.</b>
<br /><br />
Bolaget och jag är skuldfria med en stark kassa som grund. Jag lämnar härmed över stafettpinnen till teamet internt och kliver av styrelserna för respektive bolag när som helst i dagarna.
 
Tack för förra veckans avtackning, sommarmiddag och summering av verksamhetshalvåret strategiskt.

Jag kommer att meddela på linkedin när det är dags vad som händer härnäst. Allt gott till nuvarande teamet och lycka till.

<b>Hatten av. Tack för mig.</b>

<img src={image1} alt="" />

<br /><br />
PS.
<br />1. Bolaget fortsätter med sin fortsatta resa mot att nischa sig mot OT/IT.
<br />2. Bolaget kommer att byta namn framgent och inte bära mitt efternamn.
<br />3. Jag kliver av bolagens styrelser i närtid och kommer inte längre att vara verksam operativt.
<br /> DS.
<br />
<br /> Datum: Våren 2024.
</p>        </div>
      </div>
      <Footer />
    </>
  );
}

export default NewNews2;