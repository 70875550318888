import Header from "../../components/Header/Header";
import Footer from "../../components/Footer";
import "./Nadia.css";
import NadiaImg from "../../img/nadia.jpg";
import FadeInSection from "../../components/FadeInSection";
import { useEffect } from "react";

const Nadia = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);
  <FadeInSection />;
  return (
    <>
      <Header />
      <div className="container-nadia">
        <div className="nadia-container">
          <h1>Shaya Solutions startar Nadia initiativet</h1>
          <p>
          
             Initiativet stöder unga vuxna med "frånvarande föräldrar" och ensamstående
              mödrar i deras entreprenörsresor genom att erbjuda mentorskap
               och kompetens. Stadgarna för Nadia-initiativet kommer att
                publiceras på hemsidan snarast, vilket också kommer
                 att definiera kraven för ansökningsprocessen. Stipendiaterna
                  kommer att förväntas
             att i framtiden stödja personer i liknande situationer som deras egna.
             <br />
             <br />
             Shaya Solutions AB planerar att skänka 2-4% av sin vinst 
             till Nadia-initiativet från 2023 baserat på vinsterna från 2022.
             <br />
             Nadia är Joseph Öberg Shaya's mor och hans största förebild. 
          </p>
          <div className="nadia-main-content">
            <FadeInSection>
              <h2 className="h2-center">
                Syfte och målbild med Nadia initiativet
              </h2>
              <div className="box-wrapper">
                <div className="main-content-box">
                  <h3>|</h3>
                  <p>
                  Syftet är att stödja unga vuxna med "frånvarande föräldrar" och ensamstående mödrar i deras tidiga entreprenörsresor.
                  </p>
                </div>
                <div className="main-content-box">
                  <h3>||</h3>
                  <p>
                  Shaya Solutions AB kommer att skänka 2-4% av sin vinst till Nadia-initiativet varje år som bolaget går med vinst från 2023, baserat på vinsterna från 2022
                  </p>
                </div>
                <div className="main-content-box">
                  <h3>|||</h3>
                  <p>
                  Pengarna som skänks till Nadia-initiativet fördelas baserat på stadgarna som publiceras senast 2022-12-31 och kontot förvaltas av Shaya Solutions AB.
                  </p>
                </div>
              </div>
              
            </FadeInSection>
          </div>
          <div className="nadia-main-content">
            <FadeInSection>
              <h2 className="h2-center">
              Ansökningsprocessen och motkrav gentemot stipendiaterna
              </h2>
              <div className="box-wrapper">
                <div className="main-content-box">
                  <h3>|</h3>
                  <p>
                  Nadia-initiativet ger stöd till sökande genom att skänka kunskap, mentorskap och kompetens för att uppnå "proof of concept" applikationer / digitala lösningar, samt söka teknisk rådgivning.
                  </p>
                </div>
                <div className="main-content-box">
                  <h3>||</h3>
                  <p>
                  Stadgarna i Nadia initiativet kommer att definiera
                   kraven i ansökningsprocessen. Dessa kommer att
                    publiceras på hemsidan senast 2022-12-31.
                  </p>
                </div>
                <div className="main-content-box">
                  <h3>|||</h3>
                  <p>
                  Stipendiaterna förväntas att lova att i framtiden stödja personer i liknande situationer som de själva har varit i, i motsvarande omfattning som de själva har fått stöd.
                  </p>
                </div>
              </div>
              
            </FadeInSection>
          </div>

          <div className="why-and-goal-wrapper">
        
            <FadeInSection>
            <h2 className="h2-center">
            Ansök redan idag
              </h2>
            <div className="last-section-nadia">
              <div className="last-section-box">
              <span>|</span>
              <p>Skicka in din ansökan till nadiainitiativet@shayasolutions.se och berätta om vad du behöver hjälp med samt vad du skulle vilja åstadkomma om du fick den önskade hjälpen?</p>
              </div>

              <div className="last-section-box">
              <span>||</span>
              <p>Teamet inom Shaya Solutions återkopplar huruvida du omfattas av villkoren för Nadia initiativet eller ej.</p>
              </div>

              <div className="last-section-box">
              <span>|||</span>
              <p>Du får besked och återkoppling oavsett om det är positivt eller negativt.</p>
              </div>
              </div>
              </FadeInSection>
          </div>
        </div>
        
      </div>
      
      <Footer />
      
    </>
  );
};

export default Nadia;
