import React, { useState, useEffect, useRef, useMemo } from 'react';
import axios from 'axios';
import loading from "./loading.gif"
import "./JobPosting.css"

const API_KEY = 'DIo4Teyke2i9Sp7MFrCVPL1YQG7IV6ZwPP0C_I9R';
const PAGE_SIZE = 9;

function JobPostings() {
  const [jobPostings, setJobPostings] = useState([]);
  const [locationFilter] = useState('');
  const [positionFilter, setPositionFilter] = useState('');
  const [numJobsToShow, setNumJobsToShow] = useState(PAGE_SIZE);
  const [isLoading, setIsLoading] = useState(false);
  const prevNumJobsToShowRef = useRef(numJobsToShow);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`https://api.teamtailor.com/v1/jobs?page[size]=${numJobsToShow}`, {
        headers: {
          Accept: "application/vnd.api+json",
          Authorization: `Bearer ${API_KEY}`,
          'X-Api-Version': '20210218'
        },
      })
      .then((response) => {
        setJobPostings(response.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  }, [numJobsToShow]);

  useEffect(() => {
    prevNumJobsToShowRef.current = numJobsToShow;
  }, [numJobsToShow]);

  const handlePositionFilterChange = (event) => {
    setPositionFilter(event.target.value);
  };

  const handleMoreJobsClick = () => {
    setNumJobsToShow(prevNumJobsToShow => prevNumJobsToShow + PAGE_SIZE);
  };

  const filteredJobPostings = jobPostings.filter((jobPosting) => {
    const isLocationMatch = jobPosting.attributes.location?.toLowerCase().includes(locationFilter.toLowerCase()) || locationFilter === '';
    const isPositionMatch = jobPosting.attributes.title?.toLowerCase().includes(positionFilter.toLowerCase()) || positionFilter === '';
    return isLocationMatch && isPositionMatch;
  });

  const renderJobPosting = (jobPosting) => (
    <div className="job" key={jobPosting.id}>
      <h2>{jobPosting.attributes.title}</h2>
      <p>{jobPosting.attributes.description}</p>
      <a
        className="job-link"
        href={`https://career.shayasolutions.com/jobs/${jobPosting.id}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        Läs mer
      </a>
    </div>
  );

  return (
    <>
    <div className="job-container">
      <h1>Aktuella jobb </h1>
      <div className='input-wrapper'>
        <input type="text" placeholder="Sök" value={positionFilter} onChange={handlePositionFilterChange} />
      </div>
      <div className="job-list">
        {filteredJobPostings.slice(0, numJobsToShow).map(renderJobPosting)}
      </div>
      {isLoading ? (
      <img className='loading-gif' src={loading} alt="" />
      ) : (
        filteredJobPostings.length >= numJobsToShow && (
          <button className='moreJobsBtn' onClick={handleMoreJobsClick}>Fler jobb</button>
        )
      )}
      </div>
    </>
  );
}

export default JobPostings;