import Header from "../components/Header/Header";
import Footer from "../components/Footer";
import "./WordsFromCeo.css"
import FadeInSection from "./../components/FadeInSection";
import joseph from "../img/joseph.jpg";

const WordsFromCeo = () => {

    return (
        <>
        <Header />
            <div className="container">
            
            <main>
                <h1 className="ceo-header">“Vi sitter i skuggan av träd <br></br>som andra planterat”</h1>
                <section className="container-section-ceo">
                    <div className="words-from-ceo-article">
                        <img src={joseph} alt="bild på Joseph" className="joseph-img"/>
                        <h2 className="ceo-h2">VD, grundare och ägaren har ordet</h2>
                    </div>

                    <article className="first-text-area-white">
                        <p>Ovan är ett citat som jag hört och läst av en generation entreprenörer
                             samt investerare som ofta pratat om så kallade ”fina bolag”. 
                             Begreppet refererar till bolag som inkrementellt och över lång tid,
                              löser problem på en marknad. De drivs långsiktigt, såväl ur ett
                               investeringsperspektiv som ur ett ägarperspektiv. Inte sällan med
                                starka finansiella resultat och välmående personal samt nöjda kunder. 
                                <br></br>
                                <br></br>
                                Jag flydde som barn till Sverige från krig, tillsammans med familjen,
                                 för snart 30 år sedan. Med åldern kommer även reflektionerna, 
                                 över hur viktigt det är att tänka på framtida generationer. 
                                 Jag är otroligt stolt över ha kommit till Sverige som 10 åring, 
                                 lyckats ta ut en Master of Science examen inom Energisystem vid
                                  Uppsala Universitet, slitit extremt hårt och skapat värden på 
                                  tre globala bolag under mina år som anställd. För att idag äga
                                   samt driva två bolag som jag tillika grundat. Två bolag
                                    där jag tagit all ekonomisk risk under anmärkningsvärda tider,
                                     och som idag sysselsätter ca. fyrtio personer samt sysselsatt
                                      ytterligare tjugo under resans gång. Under pandemin gjordes
                                       inte en enda permittering.</p>
                    </article>
                    
                    <article className="black-text-box">
                    <FadeInSection> 
                        <div>
                            <h3 className="ceo-h3">Påminnelse.</h3>
                            <h4 className="ceo-h4">“Vi sitter i skuggan av träd som <br></br> andra planterat”</h4>
                            <p>
                            Med ovan ’påminnelse’ vill jag betona att resan görs tack vare ett fungerande
                            samhälle och strukturer. Såväl lärare, myndigheter och samhällsfunktioner,
                            som andra företagare, näringsliv och mentorer.
                            <br></br>
                            <br></br>
                            Detta tar tid. Lång tid.
                            Därför får vi inte glömma förbättringsområdena på vägen, detta så att min
                                resa ska kunna vara möjlig för fler att göra, oavsett bakgrund eller
                                geografisk lokalisering.
                        </p>
                        </div>
                        </FadeInSection> 

                    </article>
               
               
                        
          
                </section>
            </main>
            <Footer />
            </div>
            
       
        
        </>
    );
}

export default WordsFromCeo;