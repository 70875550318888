import middagMedTeamet from "../../img/Bild1.png"

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer";
import { useEffect } from "react";
import "./NewNews1.css";
const NewNews2 = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 });
    
      }, []);
    return ( 
        <><Header/>
        <div className="container-newNews">              
        <div className="newNews-wrapper">
        <span>#Nyhet</span>
            <h1>Shaya x Mindler</h1>
            <img src={middagMedTeamet} alt="" />
            <h2>Shaya Solutions har skrivit avtal med Mindler</h2>
            <p>I ett led att ytterligare stärka och främja medarbetarnas och konsulternas psykiska hälsa,
                 har Shaya Solutions valt att skriva avtal med Mindler med start 1:a februari 2023. Mindler 
                 som är den största digitala psykologmottagningen i Sverige. Mindler erbjuder behandling
                  som består av videosamtal med psykolog och övningar i deras app.
Samarbetet innebär att Shaya Solutions kommer att erbjuda sina medarbetare och partners möjligheten att få
 tillgång till psykologsamtal genom Mindler. Detta initiativ är en del av Shaya Solutions helhetstänk för
  att främja medarbetarnas och konsulternas såväl som underkonsulternas välbefinnande. Teamet inom Shaya 
  Solutions anser att psykisk hälsa är lika viktig som fysisk hälsa, särskilt i en tid där många konsulter
   jobbar på distans alternativt i kundernas organisationer på heltid. <br /> <br />
”Shaya Solutions har genom samarbetet med Mindler tagit ett viktigt steg för att hjälpa och stötta sina medarbetare
 och partners att förebygga, hantera stress alternativt psykisk ohälsa. Samarbetet ger medarbetarna möjligheten att
  få tillgång till kvalificerad psykologhjälp, coacher och samtalsterapeuter från Mindler, vilket är en av de mest
   välrenommerade digitala psykologmottagningarna i Sverige. Det gör mig stolt att vi tagit detta steg”, <b className="newNews-b">säger Joseph
    Öberg Shaya, VD, grundare och ägare av Shaya Solutions. </b>
Ovan är endast en del av många insatser och faktorer som arbetsgivare måste beakta i syfte att främja god arbetsmiljö
 för medarbetare och partners.
         </p>
            </div>      
        </div>
        <Footer/>
        </>
     );
}
 
export default NewNews2;