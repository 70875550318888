import Header from "../../components/Header/Header";
import FooterFaq from "../../components/FooterFaq";

import { useState, useEffect, useRef } from 'react'

import "./AboutUs.css"
import Team from "../../img/about-us-img/team.png"
import Location from "../../img/about-us-img/location.png"
import Arrow from "../../img/arrow.png"
//TEAM IMG
import Ahmad from "../../img/about-us-img/Ahmad.jpg"
import Anders from "../../img/about-us-img/Anders.jpg"
import dale from "../../img/about-us-img/dale.webp"

//import Carl from "../../img/about-us-img/Carl.jpg"
//import Cecilia from "../../img/about-us-img/Cecilia.png"
//import David from "../../img/about-us-img/David.png"
//import Jessica from "../../img/about-us-img/Jessica.png"
//import Joseph from "../../img/about-us-img/Joseph.jpg"
import Lotta from "../../img/about-us-img/Lotta.png"
//import Nicklas from "../../img/about-us-img/Nicklas.jpg"
import peter from "../../img/about-us-img/peter.png"
//import AliceNa from "../../img/about-us-img/Alice_namn.png"
import Nova from "../../img/about-us-img/Nova.jpg"
//import Ulrika from "../../img/about-us-img/Ulrika.jpg"
import FadeInSection from "../../components/FadeInSection";

import { Link } from "react-router-dom";
const AboutUs = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 });

    }, []);
    const [words, setWords] = useState(["uppdrag.", "kunder.", "komplexitet.", "karriärsriktning.", "omfattning av uppdrag."])
    const wrapperRef = useRef()

    const handleAnim = () => {

        setTimeout(() => {
            const copyWords = [...words];
            const firstElem = copyWords.splice(1)

            wrapperRef.current.style.transition = "0.4s";
            wrapperRef.current.style.top = "-3px";
            wrapperRef.current.style.animation = "ease-in";
            wrapperRef.current.style.opacity = "1";


            setWords([...firstElem.concat(copyWords)])
        }, 300);


        wrapperRef.current.style.opacity = "0.8"
        wrapperRef.current.style.top = "0px";
    }

    useEffect(() => {
        setTimeout(() => {
            handleAnim()
        }, 1700);
    })
    return (
        <>
            <Header />
            <div className="container-aboutus">
                <div className="about-us-content-container">
                    <div className="hero-text-about-us">
                        <div className="hero-text-h3">
                            {/* <h3 className="regular-text">Vi hedrar</h3> */}
                            <h2>Vi lägger fokus på</h2>
                            <div ref={wrapperRef} className='text-container-word-wrapper'>
                                <h2 className='text-word'>{words[0]}</h2>
                            </div>
                            {/* <h2>framgångsrika</h2> */}
                        </div>
                    </div>
                    <div className="about-us-wwd-text">
                        <div className="about-us-each-box">
                            <h2>Vad är VAERNA?</h2>
                            <p>VAERNA är ett nischat bolag inom automation och styrsystem med stark koppling till OT/IT säkerhet.
                                <br />
                                <br />
                                Fokus är utmaningarna i våra kunders kritiska verksamheter.
                                Det leder till att rätt konsulter kommer att söka sig per automatik till de utmaningar som erbjuds då konsulterna vi fokuserar på drivs av kompetensutmaningar. Det gör deras leveranser extraordinära.
                                <br />
                                <br />
                                En inspirerande arbetsmiljö för professionell tillväxt och personlig utveckling blir då en naturlig del. Med vår breda expertis inom olika områden och nära samarbete med kunder erbjuder vi skräddarsydda lösningar.
                            </p>
                        </div>

                        <div className="about-us-each-box">
                            <h2>Vad erbjuder VAERNA?</h2>
                            <p>Allt handlar om matchning mellan dig som människa och bolagets värderingar som utgörs av oss inom bolaget. Hör av dig för en kopp kaffe, så tar vi det därifrån.
                                <br />
                                <br />
                                Ett som är säkert är att vi har mycket arbetslivskvalité.
                                Arbetslivskvalité för oss betyder att välja hur mycket individen vill arbeta, vilka kunder, sektorer och uppdrag vi åtar oss, bra lön/ersättning, komplexitet i uppdrag, kompetensutmaningar.

                            </p>
                        </div>

                        <div className="about-us-each-box">
                            <h2>Vision och målbild</h2>
                            <p>Att vara ödmjuk kan av vissa människor tolkas som svaghet.
                                För att vara ödmjuk krävs ett stort mod.
                                För att ha ett stort mod krävs hög integritet.
                                <br />
                                <br />
                                Vår vision är att integritet i att aktivt välja vad vi vill jobba med ur ett miljö- och hållbarhetsperspektiv, jämlikhets- och jämställdhetsperspektiv och inte minst ur ett arbetsmiljöperspektiv.
                                <br />
                                <br />
                            </p>
                        </div>
                    </div>
                    <div className="our-team-container">
                        <h2>Hör av dig vid frågor</h2>
                        <div className="our-team-wrapper">
                           
                            <div className="each-person">
                                <img className="person-img" src={Ahmad} alt="Ahmad-img" />
                                <h3 className="h3-about-us--alice">Henry Cantwell</h3>
                                <p>Marketing and Software Developer</p>
                            </div>
                            <div className="each-person">
                                 <img className="person-img" src={dale} alt="Ahmad-img" /> 
                                <h3 className="h3-about-us--alice">Mikael Leijon</h3>
                                <p>Automation och styrsystem</p>
                            </div>
                            <div className="each-person">
                                <img className="person-img" src={peter} alt="Ahmad-img" />
                                <h3 className="h3-about-us--alice">Peter Szendröi</h3>
                                <p>OT Säkerhet</p>
                            </div>
                            <div className="each-person">
                                <img className="person-img" src={Anders} alt="Anders-img" />
                                <h3 className="h3-about-us--alice">Anders Åsberg</h3>
                                <p>Senior Projektcontroller</p>
                            </div>
                                                    
                            <div className="each-person">
                                <img className="person-img" src={Nova} alt="Nova-img" />
                                <h3 className="h3-about-us--alice">Nova (tryffelhund)</h3>
                                <p>Global Executive Vice President of Välkomstkommittén

                                </p>
                            </div>
                        </div>
                    </div>
                    <FadeInSection>
                        <div className="offices-container">
                            <h2 className="header-large">Kontor</h2>
                            <div className="underline-small"></div>

                            <div className="office-list-wrapper">
                                <div className="main-office">
                                    <h2 className="main-office-heading">Huvudkontor</h2>
                                    <p>Olof Palmes gata 11,<br />
                                        111 37 Stockholm
                                        <br/>c/o iOffice</p>
                                    <Link to={'/kontakt'}>
                                        <div className="lets-have-contact">
                                            <h2>Låt oss ha kontakt!</h2>
                                            <h2 className="h2-low-opacity">Vi svarar gärna på dina frågor.</h2>
                                            <img className="arrow" src={Arrow} alt="arrow-right" />
                                        </div>
                                    </Link>
                                </div>
                                <div className="other-offices">
                                    <h2>Andra kontor</h2>
                                    <p>Med möjlighet till konsultplatser <br /> vid tillsättning vid uppdrag.</p>
                                    <div className="other-offices-list">
                                        <div className="offices-list-section-1">
                                            <p>Östra Hamngatan 17, <br /> 411 10 Göteborg</p>
                                            <p>Östra Storgatan 9, <br /> 553 20 Jönköping</p>
                                            <p>Storgatan 51, <br /> 903 26 Umeå</p>
                                            <p>Drottninggatan 18, <br /> 803 20 Gävle</p>
                                            <p>Fiskaregatan 4c, <br /> 871 33 Härnösand</p>
                                            <p>Leuchtenfabrik Berlin, <br /> 12459 Berlin</p>

                                        </div>
                                        <div className="offices-list-section-2">
                                            <p>Universitetsallén 32, <br /> 852 34 Sundsvall</p>
                                            <p>Centralesplanaden 20C, <br /> 891 32 Örnsköldsvik</p>
                                            <p>Kurortsvägen 20b, <br /> 837 52 Åre</p>
                                            <p>Adelsgatan 21, <br /> 211 22 Malmö</p>
                                            <p>Stora Torget 3, <br /> 582 19 Linköping</p>
                                            <p>Västgötegatan 7, <br /> 602 21 Norrköping</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </FadeInSection>
                </div>

            </div>
            <FooterFaq />
        </>
    );
}

export default AboutUs;