import "../Handbok.css"
import Footer from "../../../components/Footer";
import Header from "../../../components/Header/Header";
import { Link } from "react-router-dom";

import { useEffect } from "react";

const HandbokVarderingar = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 });
    }, []);
    return (
        <>
            <Header />
            <div className="handbook-con-wrapper">
            <main>
                <section className="handbok-menu">
                    <div className="handbook-wrapper">
                        <h1 className="h1-separat">🌵 Miljö och hållbarhet</h1>
                        <article className="handbok-article">
                            <p>👋 Shaya Solutions grundades 2016 med fokus att hjälpa personer, som trots djup utbildning och akademisk teknisk kompetens ej fick ett arbete eller konsultuppdrag.
                                <br />
                                <br />
                                Detta härleddes ofta till okunskap och bristande kännedom om hur man bemöter, förvaltar och utvecklar kompetens, kunskap och talanger. Verksamheten har sedan oktober 2018 breddats till att omfatta hela arbetsmarknaden.
                                    <br />
                                    <br />
                                Miljö och arbete går hand i hand
                                <br />
                                <br />
                                Vid FN:s toppmöte den 25 september 2015 antog världens stats-och regeringschefer 17 
                                globala mål och Agenda 2030 för hållbar utveckling. Världens länder har åtagit sig att från och med den 1 januari 2016 fram till år 2030 leda världen mot en hållbar och rättvis framtid. 
                                Vi har valt att konstruera vårt miljö-och hållbarhetsarbete utifrån Shaya Solutions verksamhet där vi har möjlighet att påverka.
                                </p>
                                <a href="https://onedrive.live.com/?authkey=%21AE927R1sMdojc%2Ds&cid=8DE706180B3577D6&id=8DE706180B3577D6%217669&parId=8DE706180B3577D6%216122&o=OneUp">Vill du veta mer om hur vi jobbar med miljö och hållbarhet? Läs texten i sin helhet.</a>
                        </article>
                    </div>
                </section>
            </main>
            </div>
            <Footer />
        </>
    );
}

export default HandbokVarderingar;