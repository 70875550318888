import midsommar from "../../img/midsommar.jpg"

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer";
import { useEffect } from "react";

import "./NewNews1.css";
const NewNews2 = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 });

    }, []);
    return (
        <><Header />
            <div className="container-newNews">
                <div className="newNews-wrapper">
                    <span>#Nyhet</span>
                    <h1>Glad midsommar önskar teamet på Shaya Solutions☀️</h1>
                    <img src={midsommar} alt="" />
                </div>
            </div>
            <Footer />
        </>
    );
}

export default NewNews2;